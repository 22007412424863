import { Button, Typography } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetContactsCount,
  useGetProject,
} from '@frontend/sorghum/data-access';
import { ProjectStatus } from '@frontend/sorghum/interface';
import { THREADS_PRICING_PAGE, usePath } from '@frontend/sorghum/utils';
import { Box, styled } from '@mui/material';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const ContainerStyled = styled(Box)<{ $status: boolean }>(
  ({ theme, $status }) => ({
    padding: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: $status
      ? theme.palette['background']['primary']['5']
      : theme.palette['background']['error']['5'],
  }),
);

const LeftWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const ThreadsCustomerCountBlock: FC = () => {
  const [t] = useTranslation('threads');
  const { navigateToUpgrade } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID as string);
  const { data: contact } = useGetContactsCount();
  const { isViewer } = useProjectPermission();

  const userCount = contact?.count ?? 0;
  const trialLimit = project?.trialLimit ?? 0;

  const handleLeanMore = useCallback(() => {
    window.open(THREADS_PRICING_PAGE, '_blank');
  }, []);

  const handleUpgrade = useCallback(() => {
    navigateToUpgrade(project?.socialType, projectID);
  }, [navigateToUpgrade, project?.socialType, projectID]);

  const Description = () =>
    !isViewer ? (
      <Typography variant="body2" color="grey.900">
        {t('component.customerCountBlock.normal.description', {
          count: trialLimit,
        })}
      </Typography>
    ) : (
      <Typography variant="body2" color="grey.900">
        {t('component.customerCountBlock.reached.description')}
      </Typography>
    );

  return (
    <ContainerStyled $status={!isViewer}>
      <LeftWrapperStyled>
        {!isViewer ? (
          <Typography component="span" variant="h6" color="grey.900">
            <Trans
              t={t}
              i18nKey="component.customerCountBlock.normal.title"
              values={{
                count: userCount,
              }}
              components={[
                <Typography
                  sx={{ fontWeight: '700 !important' }}
                  component="span"
                  variant="h5"
                  color="primary"
                />,
              ]}
            />
          </Typography>
        ) : (
          <Typography variant="h6" color="grey.900">
            <Trans
              t={t}
              i18nKey="component.customerCountBlock.reached.title"
              values={{
                count: trialLimit,
              }}
              components={[
                <Typography component="span" variant="h5" color="error" />,
              ]}
            />
          </Typography>
        )}

        {project?.status !== ProjectStatus.ADVANCE && <Description />}
      </LeftWrapperStyled>
      {!isViewer ? (
        <Button onClick={handleLeanMore} variant="outlined" color="primary">
          {t('common.learnMore')}
        </Button>
      ) : (
        <Button onClick={handleUpgrade} variant="contained" color="error">
          {t('component.upgrade')}
        </Button>
      )}
    </ContainerStyled>
  );
};

export default ThreadsCustomerCountBlock;

import { Dialog } from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useProjectLinked } from '@frontend/sorghum/data-access';
import { ThreadsCtx, UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { PAGE_COMMENT_AUTO_REPLY, usePath } from '@frontend/sorghum/utils';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const ConnectThreadsModal: FC = () => {
  const { linkThreads } = useContext(ThreadsCtx);
  const [t] = useTranslation('threads');
  const { data, refetch } = useProjectLinked();
  const { navigateToThreads } = usePath();
  const { modalType, closeModal } = useContext(UICtx);

  useEffect(() => {
    const bc = new BroadcastChannel('threads_connect');
    bc.onmessage = (event) => {
      if (event.data.isSuccess) {
        Alert.success(t('setting.connectSuccess'));
        refetch();
        closeModal();
      } else {
        Alert.error(t('setting.connectFailed'));
      }
    };

    return () => {
      bc.close();
    };
  }, [closeModal, refetch, t]);

  const handleCancel = useCallback(() => {
    navigateToThreads(PAGE_COMMENT_AUTO_REPLY);
    closeModal();
  }, [closeModal, navigateToThreads]);

  if (data?.threads?.id) return null;

  return (
    <Dialog
      size="xs"
      closeBtn={false}
      title={t('modal.connect.title')}
      open={modalType === ModalTypesEnum.CONNECT_THREADS_MODAL}
      content={t('modal.connect.description')}
      cancelBtnText={t('modal.connect.cancel')}
      confirmBtnText={t('modal.connect.confirm')}
      handleClose={handleCancel}
      handleConfirm={linkThreads}
    />
  );
};

export default ConnectThreadsModal;

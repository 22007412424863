import { globalTheme, theme } from '@frontend/components/external-providers';
import {
  EllipsisMenu,
  MenuItem,
  NestedMenu,
  Tooltip,
  TourMask,
  Typography,
} from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useDeleteAccountLogout,
  useGetAccountInfo,
  useGetProject,
  usePatchAccountInfo,
  useProjectList,
} from '@frontend/sorghum/data-access';
import {
  NotificationCtx,
  UICtx,
  WebSocketProvider,
  queryClient,
} from '@frontend/sorghum/external-providers';
import {
  AccountPermission,
  ModalTypesEnum,
  SocialTypeEnum,
  UseAuthReturn,
  UsePageDashboardReturn,
} from '@frontend/sorghum/interface';
import {
  Avatar,
  CreateProjectModal,
  DashboardNoticeBar,
  DemoModeTopBar,
  EditTapPayModal,
  FanPageImage,
  FullScreenLoading,
  LoadingModal,
  RenameProjectModal,
  SelectChannelModal,
  TapPayLoadingModal,
  TutorialModal,
  UpgradeProjectModal,
} from '@frontend/sorghum/ui';
import {
  FLOW_ENTRY,
  PAGE_MAIN,
  PAGE_PERMISSION_DENIED,
  THREADS,
  dayjs,
  useFacebook,
  usePath,
} from '@frontend/sorghum/utils';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LanguageIcon from '@mui/icons-material/Language';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { get, isNull } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as LogoutIcon } from './images/icon-logout.svg';
import LogoOpen from './images/logo-open.svg';
import Logo from './images/logo.svg';
import { useMobile } from './usePages/useMobile';

const tapPayAppKey = process.env['NX_TAP_PAY_APP_KEY'] || '';

export interface SorghumFeaturesDashboardProps {
  facebookAppID: string;
  mode: string;
  googleStorageEndpoint: string;
  tapPayAppID: number;
  useAuth: () => UseAuthReturn;
  usePage: (googleStorageEndpoint: string) => UsePageDashboardReturn;
}

interface DrawerProps {
  $isProjectDrawer?: boolean;
  $isHover?: boolean;
}

const StyledSorghumFeaturesDashboard = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100vw',
}));

const ListStyled = styled(List)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: theme.palette['bluegrey']['800'],
  color: theme.palette['bluegrey']['400'],
  '.MuiListItemIcon-root': {
    color: theme.palette['grey']['white'],
  },
  padding: '0',
}));

const ProjectListStyled = styled(List)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  overflow: 'auto',
  flexDirection: 'column',
  justifyContent: 'space-between',
  background: theme.palette['bluegrey']['750'],
  color: theme.palette['bluegrey']['100'],
  '.MuiListItemIcon-root': {
    color: theme.palette['grey']['white'],
  },
}));

const OutletWrapperStyled = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',

  '.main-container': {
    minWidth: '1024px',
    width: '100%',
    height: '100%',
  },

  '.with-notice-bar': {
    paddingTop: '38px',
  },
}));

const ListItemButtonBorderStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '4px',
  left: 0,
  top: 0,
  backgroundColor: theme.palette['bluegrey'][600],
  borderRadius: '0 4px 4px 0',
}));

const ListItemButtonStyled = styled(ListItemButton)<{ $selected: boolean }>(
  ({ theme, $selected }) => ({
    '.MuiListItemIcon-root': {
      color: theme.palette['bluegrey'][400],
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      color: theme.palette['bluegrey'][300],
      '.MuiListItemIcon-root': {
        color: theme.palette['bluegrey'][300],
      },
      '& > svg path': {
        fill: theme.palette['bluegrey'][300],
      },
    },
    ...($selected && {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      '.MuiListItemIcon-root': {
        color: theme.palette['bluegrey'][100],
      },
      '& svg path': {
        fill: theme.palette['bluegrey'][100],
      },
    }),
  }),
);

const ProjectListItemButtonStyled = styled(ListItemButton)<{
  $selected: boolean;
}>(({ theme, $selected }) => ({
  position: 'relative',
  ...($selected && {
    backgroundColor: theme.palette['bluegrey'][700],
  }),
  padding: '12px 20px',
  '.MuiListItemIcon-root': {
    minWidth: '28px',
    marginRight: '12px',
  },
}));

const LogoListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '14px',
  margin: '12px 0',
}));

const AddProjectButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '28px',
  height: '28px',
  borderRadius: '100px',
  backgroundColor: `${theme.palette['bluegrey'][100]}`,
  transition: '0.5s',
  cursor: 'pointer',
  marginRight: '12px',
}));

const drawerWidth = 280;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerProps>(({ theme, open, $isProjectDrawer, $isHover }) => ({
  '& .MuiDrawer-paper': {
    flexGrow: 1,
    overflow: $isHover ? 'visible' : 'hidden',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: $isProjectDrawer ? 0 : theme.spacing(8),
    }),
    border: 0,
  },
}));

//TODO: 待更名，要和目前真正的 dashboard 頁做區隔
export function SorghumFeaturesDashboard({
  facebookAppID,
  mode,
  googleStorageEndpoint,
  tapPayAppID,
  useAuth,
  usePage,
}: SorghumFeaturesDashboardProps) {
  const [t] = useTranslation();
  const {
    outletRef,
    isTouring,
    tour,
    closeTour,
    openModal,
    closeModal,
    modalType,
    isOpenLeftSidebar,
    resetLoadingStatus,
    setIsOpenProjectList,
    isOpenProjectList,
    setIsOpenLeftSidebar,
    closeDemoMode,
    openCreateProjectModal,
    openSelectChannelModal,
    openRenameProjectModal,
  } = useContext(UICtx);
  const notificationState = useContext(NotificationCtx);
  const { projectID: paramProjectID } = useParams();
  const { data: projectID } = useGetProjectID();
  const { data: projects } = useProjectList();
  const { data: project } = useGetProject(projectID);
  const { data: account } = useGetAccountInfo();
  const { navigate, navigateToOtherProject, navigateToThreadsProject } =
    usePath();
  const { mutate: updateLanguage } = usePatchAccountInfo();
  const { mutate: accountLogout } = useDeleteAccountLogout();

  const { modal, leftSideItems, redirectTo, gotoTutorial } = usePage(
    googleStorageEndpoint,
  );

  // 拿來判斷是否要跳轉到登入註冊頁
  useAuth();
  useMobile();

  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isProjectTooltipOpen, setIsProjectTooltipOpen] = useState(false);
  const [hoverProjectID, setHoverProjectID] = useState('');
  // 是否換頁
  const [isChangePage, setIsChangePage] = useState(false);

  const toggleDrawer = useCallback(() => {
    setIsOpenLeftSidebar(!isOpenLeftSidebar);
  }, [isOpenLeftSidebar, setIsOpenLeftSidebar]);

  const { logout } = useFacebook({
    appId: facebookAppID,
    mode,
  });

  const handleMouseEnterProject = useCallback((projectID: string) => {
    setIsProjectTooltipOpen(true);
    setHoverProjectID(projectID);
  }, []);

  const handleMouseLeaveProject = useCallback(() => {
    setIsProjectTooltipOpen(false);
  }, []);

  const handleProjectItemClick = useCallback(
    (projectID: string, socialType: SocialTypeEnum) => {
      if (socialType === SocialTypeEnum.FACEBOOK)
        navigateToOtherProject(projectID);
      else navigateToThreadsProject(projectID);
      setIsOpenProjectList(false);
    },
    [navigateToOtherProject, navigateToThreadsProject, setIsOpenProjectList],
  );

  const handleProjectListClickAway = useCallback(() => {
    // 點擊 panel 以外的地方關閉 panel
    setIsOpenProjectList(false);
  }, [setIsOpenProjectList]);

  const NestedMenuList = useMemo(
    () => [
      {
        label: t('dashboard.language'),
        subItemList: [
          {
            id: 'side_lang_en',
            label: t('dashboard.english'),
            value: 'en-us',
            onClick: () => updateLanguage({ language: 'en_us' }),
            isSelected: account?.language === 'en_us',
          },
          {
            id: 'side_lang_zh',
            label: t('dashboard.chinese'),
            value: 'zh-tw',
            onClick: () => updateLanguage({ language: 'zh_tw' }),
            isSelected: account?.language === 'zh_tw',
          },
        ],
      },
    ],
    [account?.language, t, updateLanguage],
  );

  // 設定 project timezone
  useEffect(() => {
    if (project?.timezone) {
      dayjs.tz.setDefault(project.timezone);
    }
  }, [project?.timezone]);

  // 網址沒有 project id 則跳轉到第一個 project
  useEffect(() => {
    if (!paramProjectID) {
      const firstShortCode = get(projects, 'data.0.shortCode', '');
      const firstSocialType = get(projects, 'data.0.socialType');
      if (firstShortCode) {
        if (firstSocialType === SocialTypeEnum.FACEBOOK)
          navigate(PAGE_MAIN + '/' + firstShortCode + '/' + FLOW_ENTRY, {
            replace: true,
          });
        else navigateToThreadsProject(firstShortCode);
      }
    }
  }, [navigate, projects, paramProjectID, navigateToThreadsProject]);

  useEffect(() => {
    // 有 ID 而且沒有權限跳轉至沒有權限的頁面
    if (paramProjectID && project?.projectId && project?.permission === false) {
      navigate(PAGE_PERMISSION_DENIED, { replace: true });
    }
  }, [navigate, project, paramProjectID]);

  // 沒有 ID 且沒有任何專案則開啟創建專案 modal
  useEffect(() => {
    if (
      !paramProjectID &&
      projects?.code === 20000 &&
      projects.data.length <= 0
    ) {
      openCreateProjectModal();
    }
  }, [projects, openCreateProjectModal, paramProjectID]);

  const projectList = get(projects, 'data', []);

  useEffect(() => {
    if (
      project?.socialType === SocialTypeEnum.THREADS &&
      !window.location.pathname.includes(THREADS)
    ) {
      navigateToThreadsProject(project.shortCode);
    } else {
      let newPathName = window.location.pathname;

      // 如果網址的 project ID 並不是 short code，則替換網址為 short code
      if (
        project &&
        paramProjectID === project.projectId &&
        paramProjectID.length > 10
      ) {
        newPathName = newPathName.replace(paramProjectID, project.shortCode);
      }

      navigate(newPathName + window.location.search, { replace: true });
    }
  }, [navigate, project, paramProjectID, pathname, navigateToThreadsProject]);

  // 切換頁面
  useEffect(() => {
    setIsChangePage(true);
  }, [pathname]);

  // 切換頁面要重置 loading
  useEffect(() => {
    if (isChangePage) {
      resetLoadingStatus();
      setIsChangePage(false);
    }
  }, [isChangePage, resetLoadingStatus]);

  // 按上(下)一頁時，關閉下列的狀態
  useEffect(() => {
    const handlePopState = () => {
      closeDemoMode();
      closeModal();
      closeTour();
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [closeDemoMode, closeModal, closeTour]);

  if (modalType === ModalTypesEnum.TUTORIAL_MODAL) {
    return <TutorialModal googleStorageEndpoint={googleStorageEndpoint} />;
  }

  if (!projectID) {
    return <FullScreenLoading />;
  }

  return (
    <StyledSorghumFeaturesDashboard>
      <WebSocketProvider projectID={projectID}>
        <Drawer anchor={'left'} variant="permanent" open={isOpenLeftSidebar}>
          <ListStyled onClick={() => setIsOpenProjectList(false)}>
            <Box>
              <LogoListItemButtonStyled>
                <ListItemIcon onClick={() => redirectTo()}>
                  <Box height="32" display="flex" justifyContent="center">
                    {isOpenLeftSidebar ? (
                      <img src={LogoOpen} alt="logo-open" height="32" />
                    ) : (
                      <Avatar
                        src={Logo}
                        sx={{
                          width: '32px',
                          height: '32px',
                        }}
                      />
                    )}
                  </Box>
                </ListItemIcon>
              </LogoListItemButtonStyled>
              {/* 目前的專案 */}
              <ListItemButton
                id="side_project"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenProjectList(!isOpenProjectList);
                }}
              >
                <ListItemIcon>
                  <FanPageImage
                    size="small"
                    picture={project?.picture}
                    socialType={project?.socialType}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    '& > span': {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    },
                  }}
                >
                  {project?.projectName}
                </ListItemText>
                <KeyboardArrowRightIcon />
              </ListItemButton>
              {leftSideItems.map(
                ({ label, value, Icon, alert, alertType, onClick, id }) => (
                  <Tooltip
                    key={`left-side-item-${value}`}
                    placement="right"
                    title={label}
                    disabled={isOpenLeftSidebar}
                  >
                    <ListItemButtonStyled
                      id={id}
                      onClick={onClick}
                      $selected={pathname.includes(value)}
                    >
                      {alert && (
                        <CircleIcon
                          sx={{
                            color: get(
                              globalTheme,
                              `palette.${alertType ? alertType : 'info'}.main`,
                              '',
                            ),
                            width: '8px',
                            height: '8px',
                            position: 'absolute',
                            top: '12px',
                            right: '12px',
                          }}
                        />
                      )}
                      {pathname.includes(value) && (
                        <ListItemButtonBorderStyled />
                      )}
                      <ListItemIcon>{<Icon />}</ListItemIcon>

                      <ListItemText primary={label} />
                    </ListItemButtonStyled>
                  </Tooltip>
                ),
              )}
            </Box>
            <Box>
              <Tooltip
                title={t('dashboard.languageTooltip')}
                placement="right"
                disabled={isOpenLeftSidebar}
              >
                <ListItemButton
                  id="side_profile"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <ListItemIcon>
                    {<LanguageIcon sx={{ color: 'bluegrey.400' }} />}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      '& > span': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      },
                    }}
                    primary={t('dashboard.language')}
                  />
                  <KeyboardArrowRightIcon />
                </ListItemButton>
              </Tooltip>

              <Tooltip
                title={t('dashboard.tutorial')}
                placement="right"
                disabled={isOpenLeftSidebar}
              >
                <ListItemButton id="side_help" onClick={gotoTutorial}>
                  <ListItemIcon>
                    {<HelpOutlineIcon sx={{ color: 'bluegrey.400' }} />}
                  </ListItemIcon>
                  <ListItemText primary={t('dashboard.tutorial')} />
                </ListItemButton>
              </Tooltip>

              <Tooltip
                title={t('dashboard.logOutTooltip')}
                placement="right"
                disabled={isOpenLeftSidebar}
              >
                <ListItemButton
                  id="side_logout"
                  onClick={() => {
                    accountLogout(undefined, {
                      onSuccess: () => {
                        localStorage.removeItem('token');
                        sessionStorage.removeItem('project-id');
                        localStorage.removeItem('project-id');
                        queryClient.clear();
                        logout();
                        window.open(window.location.origin, '_self');
                      },
                    });
                  }}
                >
                  <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
                  <ListItemText primary={t('dashboard.logOut')} />
                </ListItemButton>
              </Tooltip>

              <ListItemButton
                sx={{
                  marginTop: '12px',
                  padding: '16px',
                  borderTop: `1px solid ${get(theme, 'palette.bluegrey.400')}`,
                }}
                onClick={toggleDrawer}
              >
                <Tooltip
                  title={t('dashboard.expandMenu')}
                  placement="right"
                  disabled={isOpenLeftSidebar}
                >
                  <ListItemIcon>
                    {isOpenLeftSidebar ? (
                      <KeyboardArrowLeftIcon sx={{ color: 'bluegrey.400' }} />
                    ) : (
                      <KeyboardArrowRightIcon sx={{ color: 'bluegrey.400' }} />
                    )}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary={t('dashboard.collapseMenu')} />
              </ListItemButton>
            </Box>
          </ListStyled>
        </Drawer>
        {/* 第二層 Project list */}
        <Drawer
          anchor={'left'}
          open={isOpenProjectList}
          variant="permanent"
          $isProjectDrawer={true}
          $isHover={isProjectTooltipOpen}
        >
          <ProjectListStyled>
            <Box sx={{ paddingTop: '60px' }}>
              {projectList.map((item, index) => (
                <Box
                  key={item.projectId}
                  sx={{
                    position: 'relative',
                  }}
                >
                  {/* 只有在 hover 並且沒有被 active 的時候才顯示 */}
                  <Tooltip
                    title={
                      <Typography variant="note" color="white">
                        {t('flows.leftSidebar.switchProject', {
                          name: item.projectName,
                        })}
                      </Typography>
                    }
                    placement="right"
                    open={
                      item.projectId === hoverProjectID &&
                      isProjectTooltipOpen &&
                      item.projectId !== projectID
                    }
                    disabled={item.permission === AccountPermission.VIEWER}
                  >
                    <ProjectListItemButtonStyled
                      id={`side_project${index}`}
                      $selected={item.projectId === projectID}
                    >
                      <ListItemIcon
                        onClick={() =>
                          handleProjectItemClick(
                            item.shortCode,
                            item.socialType,
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseEnterProject(item.projectId)
                        }
                        onMouseLeave={handleMouseLeaveProject}
                        sx={{
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <FanPageImage
                          size="medium"
                          picture={item?.picture}
                          socialType={item?.socialType}
                        />
                      </ListItemIcon>
                      <ListItemText
                        onClick={() =>
                          handleProjectItemClick(
                            item.shortCode,
                            item.socialType,
                          )
                        }
                        onMouseEnter={() =>
                          handleMouseEnterProject(item.projectId)
                        }
                        onMouseLeave={handleMouseLeaveProject}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: 'bluegrey.100',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.projectName}
                        </Typography>
                        <Typography
                          variant="caption"
                          sx={{ color: 'bluegrey.400' }}
                        >
                          {item.isLinked
                            ? t('flows.leftSidebar.connected')
                            : t('flows.leftSidebar.disconnected')}
                        </Typography>
                      </ListItemText>
                      <Tooltip
                        placement="right"
                        title={t('flows.leftSidebar.noPermissionToEditProject')}
                        disabled={item.permission !== AccountPermission.VIEWER}
                      >
                        <Box>
                          <EllipsisMenu
                            color={
                              item.permission === AccountPermission.VIEWER
                                ? get(globalTheme, 'palette.bluegrey.500', '')
                                : get(globalTheme, 'palette.bluegrey.100', '')
                            }
                            disabled={
                              item.permission === AccountPermission.VIEWER
                            }
                          >
                            <MenuItem
                              onClick={() => {
                                openRenameProjectModal(item.projectId);
                              }}
                              content={t('common.rename')}
                            />
                          </EllipsisMenu>
                        </Box>
                      </Tooltip>
                    </ProjectListItemButtonStyled>
                  </Tooltip>
                </Box>
              ))}

              <ProjectListItemButtonStyled
                id="create_new_project"
                $selected={false}
                onClick={openSelectChannelModal}
              >
                <AddProjectButtonStyled>
                  <AddOutlinedIcon
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: 'bluegrey.800',
                    }}
                  />
                </AddProjectButtonStyled>
                <ListItemText>
                  {t('flows.leftSidebar.createNewProject')}
                </ListItemText>
              </ProjectListItemButtonStyled>
            </Box>
          </ProjectListStyled>
        </Drawer>
        <OutletWrapperStyled
          ref={outletRef}
          onClick={handleProjectListClickAway}
          component="main"
        >
          <DashboardNoticeBar redirectTo={redirectTo} />

          <Box
            className={`main-container ${
              isNull(notificationState.noticeBarType) ? '' : 'with-notice-bar'
            }`}
          >
            <LoadingModal />
            <Outlet />
          </Box>
        </OutletWrapperStyled>
        {/* <HelperCenterButtonContainer
          onClick={() => window.open(CONTACT_US_MESSENGER, '_blank')}
        >
          <Avatar src={MessengerIcon} />
        </HelperCenterButtonContainer> */}
        <NestedMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          itemList={NestedMenuList}
          open={!!anchorEl}
        />
        <DemoModeTopBar />
        <EditTapPayModal
          tapPayAppID={tapPayAppID}
          tapPayAppKey={tapPayAppKey}
        />
        <TapPayLoadingModal />
        <CreateProjectModal />
        <SelectChannelModal projectList={projectList} />
        <UpgradeProjectModal />
        {modalType === ModalTypesEnum.RENAME_PROJECT && <RenameProjectModal />}
        {modal}
        {isTouring && tour && <TourMask {...tour} />}
      </WebSocketProvider>
    </StyledSorghumFeaturesDashboard>
  );
}

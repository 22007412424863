import { globalTheme } from '@frontend/components/external-providers';
import { Chip, Switch, Typography } from '@frontend/components/ui';
import { Alert, internalLinkToast } from '@frontend/components/utils';
import { useProjectPermission } from '@frontend/editor/data-access';
import { UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyCondition,
  usePostCommentAutoReplyActive,
} from '@frontend/sorghum/data-access';
import { PostType } from '@frontend/sorghum/interface';
import { Box, styled } from '@mui/material';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import PostImage from '../../../images/post-image.svg';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import { HandlePoint } from '../handle-point/handle-point';
import AssistantNavigationIcon from '../icons/assistant-navigation-icon';

type ItemStatus = 'error' | 'focus' | undefined;

const BodyWrapperStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 12px 12px 12px',
  gap: '16px',
}));

const HeaderStyled = styled(Box)<{
  $color?: string;
  $background?: string;
  $readonly?: boolean;
}>(({ theme }) => ({
  borderRadius: '12px 12px 0 0',
  color: theme.palette.green[600],
  background: theme.palette.green[50],
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 12px',

  '& > div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > svg': {
      background: theme.palette.green[50],
      '& > g > path': {
        fill: theme.palette.green[600],
      },
    },
  },
}));

const PostImageWrapperStyled = styled(Box)(({ theme }) => ({
  background: 'transparent',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  '& > img': {
    width: '44px',
    height: '44px',
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '4px',
  },
}));

const PostDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 0 12px 16px',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',

  '& > div': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const ElementWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const ElementItemWrapperStyled = styled(Typography)<{
  $status?: ItemStatus;
}>(({ theme, $status }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '4px',
  border: '1px solid transparent',
  ...($status === 'error' && {
    borderColor: theme.palette['error']['main'],
  }),
  ...($status === 'focus' && {
    borderColor: theme.palette['green']['400'],
  }),
  background: theme.palette['grey']['100'],
  borderRadius: '8px',
  padding: '12px',
}));

export const NodeThreadsCommentReplyBlock = ({
  id,
  selected,
  data,
}: NodeProps) => {
  const [t] = useTranslation('threads');
  const { zoom } = useViewport();
  const { drawerType, setDrawerType } = useContext(UICtx);
  const { isViewer } = useProjectPermission();

  const { data: commentAutoReplyData } = useGetCommentReply(
    data.commentAutoReplyID,
  );

  const { data: commentAutoReplyCondition } = useGetCommentReplyCondition(
    get(commentAutoReplyData, 'replyCondition.data.0.id', ''),
  );

  const { mutate: activeComment } = usePostCommentAutoReplyActive();

  const {
    isActive,
    keywords,
    replyToText,
    replyToStatus,
    replyPeriodText,
    replyPeriodStatus,
    replyConditionText,
    replyConditionStatus,
    isShowPost,
    posts,
  } = useMemo(() => {
    let replyToStatus: ItemStatus = undefined;
    let replyPeriodStatus: ItemStatus = undefined;
    let replyConditionStatus: ItemStatus = undefined;
    let replyToText = t('commentReplyDetail.notSet');
    let replyPeriodText = t('commentReplyDetail.notSet');
    let replyConditionText = t('commentReplyDetail.notSet');
    const keywords = get(commentAutoReplyCondition, 'condition.0.keyword', []);

    switch (commentAutoReplyData?.selectPosts?.replyType) {
      case 1: {
        if (commentAutoReplyData.selectPosts.posts.length > 0)
          replyToText = t('commentReplyDetail.replyTo.specific');
        else replyToStatus = 'error';
        break;
      }
      case 2: {
        if (commentAutoReplyData.selectPosts.isComplete)
          replyToText = t('commentReplyDetail.replyTo.all');
        else replyToStatus = 'error';
        break;
      }
      default: {
        replyToStatus = 'error';
      }
    }

    switch (commentAutoReplyData?.schedulePeriod?.periodType) {
      case 1: {
        if (
          commentAutoReplyData.schedulePeriod?.startedAt &&
          commentAutoReplyData.schedulePeriod?.endedAt
        )
          replyPeriodText = `${dayjs(
            commentAutoReplyData.schedulePeriod.startedAt,
          )
            .tz()
            .format('YYYY/MM/DD HH:mm')} - ${dayjs(
            commentAutoReplyData.schedulePeriod.endedAt,
          )
            .tz()
            .format('YYYY/MM/DD HH:mm')}`;
        else replyPeriodStatus = 'error';
        break;
      }
      case 2: {
        if (commentAutoReplyData.schedulePeriod.isComplete)
          replyPeriodText = t('commentReplyDetail.replyPeriod.always');
        else replyPeriodStatus = 'error';
        break;
      }
      default: {
        replyPeriodStatus = 'error';
      }
    }

    switch (commentAutoReplyCondition?.replyTo) {
      case 1: {
        if (
          commentAutoReplyData &&
          commentAutoReplyData.replyCondition &&
          commentAutoReplyData.replyCondition.isComplete
        )
          replyConditionText = t('commentReplyDetail.allComment');
        else replyConditionStatus = 'error';
        break;
      }
      case 2: {
        if (commentAutoReplyCondition.replyTo)
          if (keywords.length > 0)
            replyConditionText = t('commentReplyDetail.keyword');
          else replyConditionStatus = 'error';
        break;
      }
      default: {
        replyConditionStatus = 'error';
      }
    }

    const posts = commentAutoReplyData?.selectPosts?.posts || [];

    switch (drawerType) {
      case DrawerTypesEnum.THREADS_COMMENT_REPLY_TO: {
        replyToStatus = 'focus';
        break;
      }
      case DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD: {
        replyPeriodStatus = 'focus';
        break;
      }
      case DrawerTypesEnum.THREADS_COMMENT_REPLY_CONDITIONS: {
        replyConditionStatus = 'focus';
        break;
      }
    }

    return {
      replyToText,
      replyToStatus,
      replyPeriodText,
      replyPeriodStatus,
      replyConditionText,
      replyConditionStatus,
      posts,
      isShowPost:
        commentAutoReplyData?.selectPosts?.replyType === 1 && posts.length > 0,
      keywords,
      isActive: !!commentAutoReplyData?.isActive,
    };
  }, [commentAutoReplyCondition, commentAutoReplyData, drawerType, t]);

  const handleSwitchClick = useCallback(() => {
    if (
      replyToStatus === 'error' ||
      replyPeriodStatus === 'error' ||
      replyConditionStatus === 'error'
    ) {
      Alert.error(t('commentReplyDetail.alert.empty'));
      return;
    } else {
      if (!isActive) {
        activeComment(
          {
            id: data.commentAutoReplyID,
            enable: true,
          },
          {
            onSuccess: (data, variables) => {
              if (data.code === 20000) {
                Alert.success(
                  t('commentReplyDetail.alert.success', {
                    name: commentAutoReplyData?.name,
                  }),
                );
              }
            },
          },
        );
      } else {
        activeComment({
          id: data.commentAutoReplyID,
          enable: false,
        });
      }
    }
  }, [
    activeComment,
    commentAutoReplyData?.name,
    data.commentAutoReplyID,
    isActive,
    replyConditionStatus,
    replyPeriodStatus,
    replyToStatus,
    t,
  ]);

  const openDrawer = useCallback(
    (type: number) => {
      if (isViewer) return;
      switch (type) {
        case 1: {
          setDrawerType(DrawerTypesEnum.THREADS_COMMENT_REPLY_TO);
          break;
        }
        case 2: {
          setDrawerType(DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD);
          break;
        }
        case 3: {
          setDrawerType(DrawerTypesEnum.THREADS_COMMENT_REPLY_CONDITIONS);
          break;
        }
      }
    },
    [isViewer, setDrawerType],
  );

  const handleBlockClick = useCallback(() => {
    if (isViewer) return;
    if (isActive) {
      internalLinkToast(
        t('commentReplyDetail.alert.info'),
        t('commentReplyDetail.alert.infoAction'),
        'info',
        () =>
          activeComment({
            id: data.commentAutoReplyID,
            enable: false,
          }),
      );
    }
  }, [activeComment, data.commentAutoReplyID, isActive, isViewer, t]);

  return (
    <EditorBlockContainer nodeID={id} onClick={handleBlockClick}>
      <HandlePoint
        id="1"
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
      />
      <HandlePoint
        id="2"
        type="source"
        position={Position.Right}
        styles={{
          top: 'calc(100% - 30px)',
        }}
        isConnected={true}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.green.600', '')}
      >
        <HeaderStyled>
          <Box>
            <AssistantNavigationIcon fontSize="small" />
            <Typography sx={{ marginLeft: '8px' }} variant="subtitle1">
              {t('commentReplyDetail.title')}
            </Typography>
          </Box>
          <Switch
            checked={isActive}
            disabled={isViewer}
            onClick={(e) => {
              e.stopPropagation();
              handleSwitchClick();
            }}
          />
        </HeaderStyled>

        <BodyWrapperStyled>
          <ElementWrapperStyled onClick={() => openDrawer(1)}>
            <Typography variant="subtitle2" color="grey.900">
              {t('commentReplyDetail.replyTo.title')}
            </Typography>
            <ElementItemWrapperStyled
              $status={replyToStatus}
              variant="body3"
              color={
                replyToText === t('commentReplyDetail.notSet')
                  ? 'grey.500'
                  : 'grey.900'
              }
            >
              {isShowPost &&
                posts.map((post) => {
                  return (
                    <PostImageWrapperStyled key={post.id}>
                      <img src={post.fullPicture || PostImage} alt="" />
                      <PostDescriptionStyled>
                        <Typography
                          style={{ cursor: 'pointer' }}
                          component="div"
                          color={
                            (post as PostType).isDeleted
                              ? 'grey.400'
                              : 'grey.900'
                          }
                          variant="body3"
                          onClick={() =>
                            window.open(post.permalinkUrl, '_blank')
                          }
                        >
                          {post.message
                            ? post.message
                            : t('commentAutoReply.modal.post.postNoText')}
                        </Typography>
                        <Typography
                          color={
                            (post as PostType).isDeleted
                              ? 'grey.400'
                              : 'grey.500'
                          }
                          variant="notoSans"
                        >
                          {(post as PostType).isDeleted
                            ? t(
                                'commentAutoReply.content.firstStep.postDeleted',
                              )
                            : dayjs(post.createdTime)
                                .tz()
                                .format('MMMM D, YYYY [at] h:mm A')}
                        </Typography>
                      </PostDescriptionStyled>
                    </PostImageWrapperStyled>
                  );
                })}
              {!isShowPost && replyToText}
            </ElementItemWrapperStyled>
          </ElementWrapperStyled>
          <ElementWrapperStyled onClick={() => openDrawer(2)}>
            <Typography variant="subtitle2" color="grey.900">
              {t('commentReplyDetail.replyPeriod.title')}
            </Typography>
            <ElementItemWrapperStyled
              $status={replyPeriodStatus}
              variant="body3"
              color={
                replyPeriodText === t('commentReplyDetail.notSet')
                  ? 'grey.500'
                  : 'grey.900'
              }
            >
              {replyPeriodText}
            </ElementItemWrapperStyled>
          </ElementWrapperStyled>
          <ElementWrapperStyled onClick={() => openDrawer(3)}>
            <Typography variant="subtitle2" color="grey.900">
              {t('commentReplyDetail.replyCondition')}
            </Typography>
            <ElementItemWrapperStyled
              component="div"
              $status={replyConditionStatus}
              variant="body3"
              color={
                replyConditionText === t('commentReplyDetail.notSet')
                  ? 'grey.500'
                  : 'grey.900'
              }
            >
              {replyConditionText}
              {commentAutoReplyCondition?.replyTo === 2 &&
                keywords.map((i: string) => <Chip key={i} label={i} />)}
            </ElementItemWrapperStyled>
          </ElementWrapperStyled>
        </BodyWrapperStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeThreadsCommentReplyBlock);

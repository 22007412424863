import { User, UserContext } from '@frontend/sorghum/interface';
import {
  BREAK_POINT,
  PAGE_EMAIL_VERIFY,
  PAGE_LOGIN,
  PAGE_MICRO,
  PAGE_PAGES,
  PAGE_PROJECT_INVITE,
  PAGE_SIGN_UP,
  PAGE_VERIFY_LIMIT,
  PAGE_VERIFY_UNAVAILABLE,
  useRwd,
} from '@frontend/sorghum/utils';
import { Box, Grid, Typography } from '@mui/material';
import SmallLogo from 'assets/images/logo.svg';
import i18next from 'i18next';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import NewLoginPicEN from '../images/login-en.png';
import NewLoginPicZH from '../images/login-zh.png';
import LoginPic from '../images/login.png';
import Logo from '../images/logo.png';
import NewLogo from '../images/new-logo.svg';
import {
  HomePageContainerStyled,
  LeftSideContainerStyled,
  LeftSideLogoContainerStyled,
  LeftSidePictureContainerStyled,
  LeftSidePictureStyled,
  SmallLogoContainerStyled,
} from './style';

export function SorghumFeaturesHome() {
  const { pathname } = useLocation();
  const [t] = useTranslation();
  const { breakPoint } = useRwd();
  const [user, setUser] = useState<User>({ email: '', status: 'PENDING' });
  const [descriptionText, setDescriptionText] = useState('');

  const isSignUpOrSignInPage = useMemo(() => {
    let isSpecificPage = false;
    if (pathname === PAGE_SIGN_UP || pathname === PAGE_LOGIN) {
      isSpecificPage = true;
    }
    return isSpecificPage;
  }, [pathname]);

  const onSetUser = useCallback(
    (newUser: User) => {
      const data = {
        id: newUser.id ? newUser.id : user.id,
        email: newUser.email ? newUser.email : user.email,
        password: newUser.password ? newUser.password : user.password,
        status: newUser.status ? newUser.status : user.status,
        error: newUser.error ? newUser.error : user.error,
      };

      setUser(data);
    },
    [user],
  );

  // 只有特定頁面需要顯示左側插圖底下的文字
  const isShowDescriptionText = useCallback((url: string) => {
    switch (url) {
      case PAGE_PROJECT_INVITE:
      case PAGE_VERIFY_UNAVAILABLE:
      case PAGE_EMAIL_VERIFY:
      case PAGE_VERIFY_LIMIT:
      case PAGE_SIGN_UP:
      case PAGE_LOGIN:
        return false;
      default:
        return true;
    }
  }, []);

  const onSetDescriptionText = useCallback(
    (url: string) => {
      switch (url) {
        case PAGE_PAGES:
          setDescriptionText(t('pages.leftDescriptionText'));
          break;
        case PAGE_MICRO:
          setDescriptionText(t('micro.leftDescriptionText'));
          break;
        default:
          setDescriptionText(t('login.leftDescriptionText'));
          break;
      }
    },
    [t],
  );

  useEffect(() => {
    if (!isEmpty(pathname)) {
      onSetDescriptionText(pathname);
    }
  }, [onSetDescriptionText, pathname]);

  return (
    <HomePageContainerStyled>
      <Grid container component="main" sx={{ height: '100%' }}>
        {breakPoint > BREAK_POINT.SM && (
          <Grid item md={4}>
            <LeftSideContainerStyled
              className={isSignUpOrSignInPage ? 'signUpSignin' : ''}
            >
              <LeftSideLogoContainerStyled
                className={isSignUpOrSignInPage ? 'signUpSignin' : ''}
              >
                {isSignUpOrSignInPage ? (
                  <img src={NewLogo} alt="newLogo" width="160" height="60" />
                ) : (
                  <img src={Logo} alt="logo" width="139" />
                )}
              </LeftSideLogoContainerStyled>

              <LeftSidePictureContainerStyled
                className={isSignUpOrSignInPage ? 'signUpSignin' : ''}
              >
                <LeftSidePictureStyled
                  className={isSignUpOrSignInPage ? 'signUpSignin' : ''}
                >
                  {isSignUpOrSignInPage && i18next.language.includes('en') ? (
                    <img
                      src={NewLoginPicEN}
                      alt="new-login-pic-en"
                      width="100%"
                    />
                  ) : isSignUpOrSignInPage &&
                    i18next.language.includes('zh') ? (
                    <img
                      src={NewLoginPicZH}
                      alt="new-login-pic-zh"
                      width="100%"
                    />
                  ) : (
                    <img src={LoginPic} alt="login-pic" width="100%" />
                  )}
                </LeftSidePictureStyled>
                {isShowDescriptionText(pathname) && (
                  <Box>
                    <Typography
                      textAlign="center"
                      variant="body2"
                      color="text.secondary"
                    >
                      {descriptionText}
                    </Typography>
                  </Box>
                )}
              </LeftSidePictureContainerStyled>
            </LeftSideContainerStyled>
          </Grid>
        )}

        <Grid
          item
          sm={12}
          xs={12}
          md={8}
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: breakPoint <= BREAK_POINT.SM ? '100%' : '60%',
              padding: breakPoint <= BREAK_POINT.SM ? '20px' : '0',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {breakPoint < BREAK_POINT.MD && (
              <SmallLogoContainerStyled
                className={isSignUpOrSignInPage ? 'signUpSignin' : ''}
              >
                {isSignUpOrSignInPage ? (
                  <img src={NewLogo} alt="newLogo" width="120" height="45" />
                ) : (
                  <img src={SmallLogo} alt="small-logo" />
                )}
              </SmallLogoContainerStyled>
            )}
            <Outlet context={{ user, setUser: onSetUser }} />
          </Box>
        </Grid>
      </Grid>
    </HomePageContainerStyled>
  );
}

export function useUser() {
  return useOutletContext<UserContext>();
}

export default SorghumFeaturesHome;

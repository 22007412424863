import { GetContactsResponseType } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS } from '../apis';

const defaultData: GetContactsResponseType = {
  id: '',
  headshot: '',
  socialId: '',
  name: '',
  notification: false,
  gender: 1,
  timezone: '',
  locale: '',
  lastActive: '',
  joined: '',
  tags: [],
  attributes: [],
  sequences: [],
};

async function getContacts(
  contactsId: string,
): Promise<GetContactsResponseType> {
  return axios
    .get(`${CONTACTS}`, { params: { contactsId } })
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetContacts(contactsId: string | undefined) {
  return useQuery<GetContactsResponseType>({
    queryKey: ['get-contacts', { contactsId }],
    queryFn: () => {
      if (contactsId) {
        return getContacts(contactsId);
      } else {
        return defaultData;
      }
    },
  });
}

import {
  PostDataOperationCommentReplyListProps,
  PostDataOperationCommentReplyListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_COMMENT_REPLY_LIST } from '../apis';

async function postDataOperationCommentReplyList(
  props: PostDataOperationCommentReplyListProps,
): Promise<PostDataOperationCommentReplyListResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_COMMENT_REPLY_LIST, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      totalCount: 0,
      limit: 0,
      offset: 0,
      commentReplies: [],
    }));
}

export function usePostDataOperationCommentReplyList(
  props: PostDataOperationCommentReplyListProps,
) {
  return useQuery({
    queryKey: ['post-data-operation-commentReply-list', props],
    queryFn: () => postDataOperationCommentReplyList(props),
  });
}

export default usePostDataOperationCommentReplyList;

import { Box, styled } from '@mui/material';

export const ChannelOptionWrapper = styled(Box)(({ theme }) => ({
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
}));

export const ChannelOptionStyled = styled(Box)<{
  $isDisabled: boolean;
  $isSelected: boolean;
}>(({ theme, $isDisabled, $isSelected }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  height: '80px',
  padding: '16px 24px',
  border: $isSelected
    ? `3px solid ${theme.palette['primary']['main']}`
    : `1px solid ${theme.palette['grey'][300]}`,
  borderRadius: '8px',
  backgroundColor: $isSelected
    ? `${theme.palette['background']['primary']['5']}`
    : $isDisabled
    ? `${theme.palette['grey']['white']}`
    : '',
  cursor: $isDisabled ? 'not-allowed' : 'pointer',
  opacity: $isDisabled ? 0.4 : 1,
}));

export const BetaChipStyled = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette['background']['primary']['5']}`,
  borderRadius: '4px',
  padding: '2px 8px',
}));

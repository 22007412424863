import { Box } from '@mui/material';
import { styled } from '@mui/system';

const PlanOptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 24px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette['grey'][300]}`,
  cursor: 'pointer',
  height: '80px',

  '&.selected': {
    border: `3px solid ${theme.palette['primary']['main']}`,
    backgroundColor: `${theme.palette['primary']['main']}0D`,
  },
}));

export const PlanOption = ({
  isSelected,
  content,
  onClick,
}: {
  isSelected: boolean;
  content: JSX.Element;
  onClick?: () => void;
}) => {
  return (
    <PlanOptionStyled
      onClick={onClick}
      className={isSelected ? 'selected' : ''}
    >
      {content}
    </PlanOptionStyled>
  );
};

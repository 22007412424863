import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CONTACTS_COUNT } from '../apis';

type GetContactsCountResponseType = {
  count: number;
};

const defaultData: GetContactsCountResponseType = {
  count: 0,
};

async function getContactsCount(): Promise<GetContactsCountResponseType> {
  return axios
    .get(CONTACTS_COUNT)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetContactsCount() {
  return useQuery<GetContactsCountResponseType>({
    queryKey: ['get-contacts-count'],
    queryFn: () => getContactsCount(),
  });
}

export default useGetContactsCount;

import { PlacementEnum, TourMaskProps } from '@frontend/components/interface';
import { Dialog } from '@frontend/components/ui';
import { usePostCommentAutoReply } from '@frontend/editor/data-access';
import { usePostAccountTutorial } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  dayjs,
  HOW_TO_USE_COMMENT_REPLY_EN,
  HOW_TO_USE_COMMENT_REPLY_ZH,
  LIST,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import { Box, Link, styled, Typography } from '@mui/material';
import i18next from 'i18next';
import { useCallback, useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Step1After from '../../images/comment-auto-reply-onboarding/step1-finish.png';
import Step1Before from '../../images/comment-auto-reply-onboarding/step1.png';
import Step2After from '../../images/comment-auto-reply-onboarding/step2-finish.png';
import Step2Before from '../../images/comment-auto-reply-onboarding/step2.png';
import Step3After from '../../images/comment-auto-reply-onboarding/step3-finish.png';
import Step3Before from '../../images/comment-auto-reply-onboarding/step3.png';

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const ImageRowWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'inline-flex',
  justifyContent: 'space-between',
  padding: '0 66px',
}));

const StepWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > img': {
    width: '200px',
    height: '200px',
    marginBottom: '16px',
  },
}));

interface CommentAutoReplyOnboardingWelcomeModalProps {
  googleStorageEndpoint: string;
}

export const CommentAutoReplyOnboardingWelcomeModal = ({
  googleStorageEndpoint,
}: CommentAutoReplyOnboardingWelcomeModalProps) => {
  const [t] = useTranslation();
  const {
    modalType,
    openRenameCommentAutoReplyModal,
    openModal,
    closeModal,
    setTour,
    setIsTouring,
    closeDemoMode,
    closeTour,
  } = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { mutate: addCommentReply } = usePostCommentAutoReply();
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();

  const mode =
    modalType === ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH
      ? 'finish'
      : 'start';

  const finishTutorial = useCallback(() => {
    closeTour();
    updateAccountTutorial({
      marketingGuru: {
        commentAutoReply: {
          completedAt: dayjs().toISOString(),
        },
      },
    });

    openModal(ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH);
  }, [updateAccountTutorial, closeTour, openModal]);

  const onboardingTour: TourMaskProps[] = useMemo(() => {
    return [
      {
        targets: ['add_comment_auto_reply'],
        highlight: 'add_comment_auto_reply',
        highlightType: 'button',
        onHighlightClick: () => {
          setIsTouring(false);
          openModal(ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_MAIN_TUTORIAL);
        },
        tooltipProps: {
          description: t('tour.commentAutoReplyOnBoarding.step1.description'),
          placement: PlacementEnum.BOTTOM_END,
          pagination: `1 ${t('common.of')} 3`,
        },
      },
      {
        targets: ['comment-auto-reply-switch-button'],
        highlight: 'comment-auto-reply-switch-button',
        highlightType: 'button',
        onHighlightClick: () => {
          setIsTouring(false);
          navigateToProjectPage(`/${COMMENT_AUTO_REPLY}/${LIST}`);
          openModal(
            ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_CONFIRM_BEHAVIOR,
          );
        },
        tooltipProps: {
          description: t('tour.commentAutoReplyOnBoarding.step3.description'),
          placement: PlacementEnum.BOTTOM_END,
          pagination: `2 ${t('common.of')} 3`,
        },
      },
      {
        targets: ['6d16e090-1f4c-4f22-9581-0a274ee03393'],
        tooltipProps: {
          title: t('tour.commentAutoReplyOnBoarding.step5.title'),
          description: t('tour.commentAutoReplyOnBoarding.step5.description'),
          placement: PlacementEnum.BOTTOM,
          pagination: `3 ${t('common.of')} 3`,
          nextButtonText: t('tour.commentAutoReplyOnBoarding.step5.next'),
          nextButtonClick: finishTutorial,
        },
        isTransparentMaskClickable: true,
      },
    ];
  }, [setIsTouring, t, openModal, navigateToProjectPage, finishTutorial]);

  // start tutorial
  const handleTourStart = useCallback(() => {
    closeModal();
    setTour(onboardingTour);

    setTimeout(() => {
      setIsTouring(true);
    }, 0);
  }, [closeModal, setTour, setIsTouring, onboardingTour]);

  const createCommentAutoReply = useCallback(() => {
    closeDemoMode();
    addCommentReply(
      {
        name: t('commentAutoReply.list.defaultReplyName'),
        conditionName: t('commentAutoReply.list.defaultConditionName'),
      },
      {
        onSuccess: (data, variables) => {
          if (data.code === 20000) {
            sendGAEvent(
              'Comment auto reply',
              'comment auto reply - created',
              'Comment auto reply - created',
              '',
            );
            navigateToProjectPage(
              `/${COMMENT_AUTO_REPLY}/${data.data.id}/${CONTENT}`,
            );
            openRenameCommentAutoReplyModal(data.data.id);
          }
        },
      },
    );
  }, [
    addCommentReply,
    closeDemoMode,
    navigateToProjectPage,
    openRenameCommentAutoReplyModal,
    t,
  ]);

  const { title, confirmBtnText, handleConfirm } = useMemo(() => {
    if (mode === 'start') {
      return {
        title: t('tour.modal.commentAutoReplyWelcome.start.title'),
        confirmBtnText: t('tour.modal.commentAutoReplyWelcome.start.button'),
        handleConfirm: handleTourStart,
      };
    }
    return {
      title: t(`tour.modal.commentAutoReplyWelcome.finish.title`),
      confirmBtnText: t(`tour.modal.commentAutoReplyWelcome.finish.button`),
      handleConfirm: createCommentAutoReply,
    };
  }, [mode, t, handleTourStart, createCommentAutoReply]);

  const handleClose = useCallback(() => {
    closeDemoMode();
    closeModal();
  }, [closeDemoMode, closeModal]);

  return (
    <Dialog
      size="md"
      title={title}
      open={
        modalType ===
          ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_START ||
        modalType ===
          ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH
      }
      {...(modalType ===
        ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH && {
        cancelBtnText: t('tour.modal.commentAutoReplyWelcome.finish.later'),
      })}
      confirmBtnText={confirmBtnText}
      closeBtn={false}
      autoClose={false}
      handleClose={handleClose}
      handleConfirm={handleConfirm}
      disableEscapeKeyDown
    >
      <ContentWrapperStyled>
        {modalType ===
          ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_START && (
          <Typography variant="body3" color="grey.500" alignSelf="end">
            {t(`tour.modal.commentAutoReplyWelcome.start.time`)}
          </Typography>
        )}
        <Typography>
          <Typography component="span" variant="body1" color="grey.800">
            {t(`tour.modal.commentAutoReplyWelcome.${mode}.description`)}
          </Typography>
          {modalType ===
            ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH && (
            <Typography
              component="span"
              variant="body1"
              color="grey.800"
              sx={{ marginTop: '25px' }}
            >
              <Trans
                i18nKey="tour.modal.commentAutoReplyWelcome.finish.memo"
                components={[
                  <Link
                    href={
                      i18next.language.includes('en')
                        ? HOW_TO_USE_COMMENT_REPLY_EN
                        : HOW_TO_USE_COMMENT_REPLY_ZH
                    }
                    underline="always"
                    target="_blank"
                    sx={{
                      color: 'alert.info.main',
                    }}
                  />,
                ]}
              />
            </Typography>
          )}
        </Typography>
        <ImageRowWrapperStyled>
          <StepWrapperStyled>
            <img
              src={mode === 'finish' ? Step1After : Step1Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.700">
              {t('tour.modal.commentAutoReplyWelcome.learnStep.step1')}
            </Typography>
          </StepWrapperStyled>
          <StepWrapperStyled>
            <img
              src={mode === 'finish' ? Step2After : Step2Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.700">
              {t('tour.modal.commentAutoReplyWelcome.learnStep.step2')}
            </Typography>
          </StepWrapperStyled>
          <StepWrapperStyled>
            <img
              src={mode === 'finish' ? Step3After : Step3Before}
              alt="step1-before"
            />
            <Typography variant="body1" color="grey.700">
              {t('tour.modal.commentAutoReplyWelcome.learnStep.step3')}
            </Typography>
          </StepWrapperStyled>
        </ImageRowWrapperStyled>
      </ContentWrapperStyled>
    </Dialog>
  );
};

export default CommentAutoReplyOnboardingWelcomeModal;

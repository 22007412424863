import { Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function CreateProjectModal() {
  const [t] = useTranslation();
  const { modalType, closeModal, openSelectChannelModal } = useContext(UICtx);

  //FIXME: social type
  const handleConfirm = useCallback(() => {
    closeModal();
    openSelectChannelModal();
  }, [closeModal, openSelectChannelModal]);

  return (
    <Dialog
      size="xs"
      title={t('flows.leftSidebar.createProjectModal.title')}
      open={modalType === ModalTypesEnum.CREATE_PROJECT}
      confirmBtnText={t('common.next')}
      closeBtn={false}
      handleClose={() => null}
      handleConfirm={handleConfirm}
    >
      <Box>
        <Typography variant="body1" color="grey.800">
          {t('flows.leftSidebar.createProjectModal.content')}
        </Typography>
      </Box>
    </Dialog>
  );
}

export default CreateProjectModal;

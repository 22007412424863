import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchProjectDefaultRequest } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT_DEFAULT } from '../apis';

async function patchProjectDefault(props: PatchProjectDefaultRequest) {
  return axios
    .patch(PROJECT_DEFAULT, props)
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchProjectDefault() {
  return useMutation({
    mutationKey: ['patch-project-default'],
    mutationFn: (props: PatchProjectDefaultRequest) =>
      patchProjectDefault(props),
    onSuccess: (res, props) => {
      queryClient.invalidateQueries(['get-account-info']);
    },
  });
}

import {
  PaymentStatus,
  Project,
  ProjectStatus,
  ProjectStep,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT } from '../apis';

const defaultValue = {
  projectId: '',
  projectName: '',
  isLinked: false,
  shortCode: '',
  timezone: 'Asia/Taipei',
  timezoneOffset: 8,
  paymentStatus: PaymentStatus.NORMAL,
  step: ProjectStep.NONE,
  status: ProjectStatus.VIEW_ONLY,
  socialType: SocialTypeEnum.FACEBOOK,
  permission: true,
};

async function getProject(id: string): Promise<Project> {
  return axios
    .get(PROJECT, {
      params: {
        ...(id.length > 10 && { projectId: id }),
        ...(id.length <= 10 && { shortCode: id }),
      },
    })
    .then((res) => {
      if (res.data.code === 20000) {
        return res.data.data;
      } else {
        return {
          ...defaultValue,
          permission: false,
        };
      }
    })
    .catch((_) => ({
      ...defaultValue,
      permission: false,
    }));
}

export function useGetProject(projectID: string | undefined) {
  return useQuery<Project>({
    queryKey: ['get-project', { projectID }],
    queryFn: () => {
      if (projectID) {
        return getProject(projectID);
      } else {
        return defaultValue;
      }
    },
  });
}

export default useGetProject;

import { globalTheme } from '@frontend/components/external-providers';
import CloseIcon from '@mui/icons-material/Close';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';

export interface ChipProps extends MuiChipProps {
  color?: 'primary' | 'secondary' | 'error';
  active?: boolean;
  radiusType?: 'tag' | 'chip';
}

const ChipStyled = styled(MuiChip)<{
  $color?: string;
  $disabled?: boolean;
  $radiusType: 'tag' | 'chip';
  $active?: boolean;
}>(({ theme, $color = 'primary', $disabled, $radiusType, $active }) => ({
  backgroundColor: $disabled
    ? theme.palette['background']['black'][10]
    : `${theme.palette[$color]['main']}0D`,
  ...($radiusType === 'tag' && { borderRadius: '4px' }),
  color: $disabled ? theme.palette['grey'][900] : theme.palette[$color]['main'],
  fontSize: '16px',
  border: `1px solid ${
    $disabled ? theme.palette['background']['black'][10] : 'transparent'
  }`,
  ...($active && {
    borderColor: theme.palette[$color]['main'],
  }),
  '&:hover': {
    border: `1px solid ${theme.palette[$color]['main']}40`,
  },
  WebkitTextFillColor: 'initial !important',
}));

export function Chip({
  color = 'primary',
  radiusType = 'tag',
  active,
  ...props
}: ChipProps) {
  return (
    <ChipStyled
      {...props}
      $color={color}
      $radiusType={radiusType}
      $disabled={props.disabled}
      $active={active}
      deleteIcon={
        <CloseIcon
          style={{
            color: props.disabled
              ? get(globalTheme, `palette.grey.600`, '')
              : color === 'error'
              ? get(globalTheme, `palette.error.main`, 'error')
              : color === 'primary'
              ? get(globalTheme, `palette.primary.main`, 'primary')
              : get(globalTheme, `palette.secondary.dark`, 'secondary'),
            fontSize: '18',
          }}
        />
      }
    />
  );
}

export default Chip;

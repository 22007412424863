import { BreadcrumbsItem } from '@frontend/components/interface';
import {
  Breadcrumbs,
  EllipsisItemProps,
  EllipsisMenu,
  MenuItem,
} from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MessengerIcon, ThreadsIcon } from '../icons';

export interface TopBarProps {
  history: BreadcrumbsItem[];
  now: BreadcrumbsItem;
  lastModified?: string;
  menuItems?: EllipsisItemProps[];
  onPublish?: () => void;
  children?: React.ReactNode;
  savingText?: React.ReactNode;
  customButton?: React.ReactNode;
  isShowMoreButton?: boolean;
  isCanvas?: boolean;
  socialType: SocialTypeEnum | undefined;
}

const TopBarWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  // 在 editor-loading-modal 之上
  zIndex: 100,
  display: 'inline-flex',
  padding: '12px 40px',
  justifyContent: 'space-between',
  background: '#E2E7EF',
  alignItems: 'center',
  position: 'relative',
  height: '60px',

  '&.is-canvas': {
    width: '100%',
    position: 'relative',
  },
}));

const ButtonGroupWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div': {
    paddingLeft: theme.spacing(1),
  },
  '& > span': {
    paddingLeft: theme.spacing(1),
  },
}));

const BreadcrumbsWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > svg': {
    marginRight: theme.spacing(1),
  },
}));

export const TopBar: FC<TopBarProps> = ({
  history,
  now,
  // 有傳 menuItems 才會出現 more button
  menuItems = [],
  children,
  savingText,
  customButton,
  isShowMoreButton = false,
  isCanvas = false,
  socialType = SocialTypeEnum.FACEBOOK,
}: TopBarProps) => {
  const [t] = useTranslation();

  const { isViewer, isPermissionViewer, isStatusViewOnly } =
    useProjectPermission();

  const breadcrumbNow = {
    ...now,
    label: `${now.label} ${
      isPermissionViewer
        ? t('common.breadcrumbs.viewerMode')
        : isStatusViewOnly
        ? t('common.breadcrumbs.viewMode')
        : ''
    }`,
  };

  return (
    <TopBarWrapper className={`${isCanvas ? 'is-canvas' : ''}`}>
      <BreadcrumbsWrapper>
        {socialType === SocialTypeEnum.FACEBOOK ? (
          <MessengerIcon />
        ) : (
          <ThreadsIcon />
        )}
        <Breadcrumbs history={history} now={breadcrumbNow} />
      </BreadcrumbsWrapper>
      <ButtonGroupWrapper>
        {children}
        {savingText && savingText}
        {customButton && customButton}
        {menuItems.length > 0 && isShowMoreButton && (
          <EllipsisMenu disabled={isViewer}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                onClick={item.onClick}
                content={item.label}
                color={item.color}
              />
            ))}
          </EllipsisMenu>
        )}
      </ButtonGroupWrapper>
    </TopBarWrapper>
  );
};

export default TopBar;

import {
  PostDataOperationBroadcastListProps,
  PostDataOperationBroadcastListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_BROADCAST_LIST } from '../apis';

async function postDataOperationBroadcastList(
  props: PostDataOperationBroadcastListProps,
): Promise<PostDataOperationBroadcastListResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_BROADCAST_LIST, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePostDataOperationBroadcastList(
  props: PostDataOperationBroadcastListProps,
) {
  return useQuery({
    queryKey: ['post-data-operation-broadcast-list', props],
    queryFn: () => postDataOperationBroadcastList(props),
  });
}

export default usePostDataOperationBroadcastList;

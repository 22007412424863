import { PlacementEnum } from '@frontend/components/interface';
import {
  Button,
  Chip,
  Divider,
  Pagination,
  ProgressBar,
  Typography,
} from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import {
  useGetAccountInfo,
  useGetAccountTutorial,
  usePostAccountTutorial,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import {
  PAGE_COMMENT_AUTO_REPLY_LIST,
  PAGE_PEOPLE,
  usePath,
} from '@frontend/sorghum/utils';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
} from '@mui/material';
import { styled } from '@mui/system';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

const MissionBoardContainerStyled = styled(Box)(({ theme }) => ({}));

const MissionButtonStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  bottom: '12px',
  left: '75px',

  zIndex: 5,
  lineHeight: '100%',
  height: '40px',
  paddingTop: '8px',
  boxSizing: 'content-box',

  '.inner-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.MuiTypography-button': {
    lineHeight: '100%',
  },

  '&.opened': {
    left: '291px',
    transition: '0.5s',
  },
}));

const MissionButtonClosedStyled = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '8px 16px',
  backgroundColor: theme.palette['bluegrey']['800'],
  color: theme.palette['grey']['white'],
  zIndex: 5,
  lineHeight: '100%',
  borderRadius: '100px',

  '.inner-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.MuiTypography-button': {
    lineHeight: '100%',
  },

  '&:hover': {
    backgroundColor: theme.palette['bluegrey']['800'],
  },
}));

const MissionButtonOpenedStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px',
  height: '40px',
  width: '40px',
  backgroundColor: theme.palette['bluegrey']['800'],
  cursor: 'pointer',
}));

const PopoverContainerStyled = styled(Box)(({ theme }) => ({
  width: '320px',
  marginBottom: '8px',
  padding: '8px 0',

  '.title': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
  },

  '.progress-bar': {
    padding: '4px 16px',
  },

  '.divider': {
    padding: '16px',
  },
}));

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: `${theme.palette['primary']['main']}0D`,
  },
}));

export function MissionBoard() {
  const [t] = useTranslation();
  const {
    setIsTouring,
    setTour,
    toggleMissionListModal,
    openModal,
    setIsOpenMissionListModal,
    isOpenMissionListModal,
    isOpenLeftSidebar,
  } = useContext(UICtx);
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: projectID } = useGetProjectID();
  const { data: account } = useGetAccountInfo();
  const { data: tutorialData } = useGetAccountTutorial();
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();
  const { isViewer } = useProjectPermission();
  const { navigateToProjectPage } = usePath();

  const anchorRef = useRef(null);
  const [currentLevel, setCurrentLevel] = useState(1);

  const handleClick = useCallback(() => {
    toggleMissionListModal();
  }, [toggleMissionListModal]);

  const finishTutorial = useCallback(() => {
    if (account?.isOnboarding) {
      updateAccountTutorial({
        isOnboarding: false,
      });
    }
  }, [account, updateAccountTutorial]);

  const level1LessonList = useMemo(
    () => [
      {
        id: 0,
        title: t('flows.missionBoard.missionList.first.lesson1'),
        icon: SchoolOutlinedIcon,
        onClick: () => {
          openModal(ModalTypesEnum.LEVEL1_LESSON1);
        },
        gaID: 'level1_mission1_show_more',
        completed:
          tutorialData?.marketingExpert?.canvasTutorial?.isComplete ?? false,
      },
      {
        id: 1,
        title: t('flows.missionBoard.missionList.first.lesson2'),
        icon: LibraryBooksOutlinedIcon,
        onClick: () => {
          openModal(ModalTypesEnum.LEVEL1_LESSON2);
        },
        gaID: 'level1_mission2_show_more',
        completed:
          tutorialData?.marketingExpert?.getMarketingTemplate?.isComplete ??
          false,
      },
      {
        id: 2,
        title: t('flows.missionBoard.missionList.first.lesson3'),
        icon: UploadOutlinedIcon,
        onClick: () => {
          openModal(ModalTypesEnum.LEVEL1_LESSON3);
        },
        gaID: 'level1_mission3_show_more',
        completed:
          tutorialData?.marketingExpert?.publishConversationFlow?.isComplete ??
          false,
      },
    ],
    [openModal, t, tutorialData],
  );

  const level2LessonList = useMemo(
    () => [
      {
        id: 0,
        title: t('flows.missionBoard.missionList.second.lesson1'),
        icon: SchoolOutlinedIcon,
        onClick: () => {
          navigateToProjectPage(`${PAGE_PEOPLE}`);
          openModal(ModalTypesEnum.LEVEL2_LESSON1);
          setIsOpenMissionListModal(false);
        },
        gaID: 'level2_mission1_show_more',
        completed: tutorialData?.marketingGuru?.customer?.isComplete ?? false,
      },
      {
        id: 1,
        title: t('flows.missionBoard.missionList.second.lesson2'),
        icon: SchoolOutlinedIcon,
        onClick: () => {
          navigateToProjectPage(`${PAGE_COMMENT_AUTO_REPLY_LIST}`);
          openModal(ModalTypesEnum.LEVEL2_LESSON2);
          setIsOpenMissionListModal(false);
        },
        gaID: 'level2_mission2_show_more',
        completed:
          tutorialData?.marketingGuru?.commentAutoReply?.isComplete ?? false,
      },
    ],
    [
      tutorialData,
      t,
      navigateToProjectPage,
      openModal,
      setIsOpenMissionListModal,
    ],
  );

  const missionList = useMemo(
    () => [
      {
        id: 0,
        level: 1,
        totalStep: tutorialData?.marketingExpert?.totalStep ?? 0,
        completedStep: tutorialData?.marketingExpert?.completedStep ?? 0,
        title: t('flows.missionBoard.missionList.first.title'),
        lessonList: level1LessonList,
      },
      {
        id: 1,
        level: 2,
        totalStep: tutorialData?.marketingGuru?.totalStep ?? 0,
        completedStep: tutorialData?.marketingGuru?.completedStep ?? 0,
        title: t('flows.missionBoard.missionList.second.title'),
        lessonList: level2LessonList,
      },
    ],
    [tutorialData, level1LessonList, level2LessonList, t],
  );

  const progressPercent = useMemo(() => {
    let progressPercentage = 0;
    if (missionList[currentLevel - 1].totalStep > 0) {
      progressPercentage =
        (missionList[currentLevel - 1].completedStep /
          missionList[currentLevel - 1].totalStep) *
        100;
    } else {
      progressPercentage = 0;
    }
    return progressPercentage;
  }, [missionList, currentLevel]);

  // 判斷 default 該顯示哪個 level
  useEffect(() => {
    if (account) {
      if (
        account.isOnboarding &&
        account.onboardingLevel === 'marketingExpert'
      ) {
        setCurrentLevel(1);
      } else if (
        account.isOnboarding &&
        account.onboardingLevel === 'marketingGuru'
      ) {
        setCurrentLevel(2);
      }
    }
  }, [account, setCurrentLevel]);

  // 判斷是否該顯示 level 的遮罩與 tooltip
  useEffect(() => {
    if (!isLoaded && anchorRef.current && account?.userName && projectID) {
      if (account.isOnboarding) {
        setIsLoaded(true);
        setIsOpenMissionListModal(true);

        if (account.onboardingLevel === 'marketingExpert') {
          setTimeout(() => {
            setTour([
              {
                targets: ['mission-board'],
                tooltipProps: {
                  description: t(
                    'flows.missionBoard.missionList.first.onboardingTooltip',
                  ),
                  placement: PlacementEnum.RIGHT,
                  onClose: () => {
                    setIsTouring(false);
                    finishTutorial();
                  },
                },
              },
            ]);

            setIsTouring(true);
          }, 1000);
        }

        if (account.onboardingLevel === 'marketingGuru') {
          setTimeout(() => {
            setTour([
              {
                targets: ['mission-board'],
                tooltipProps: {
                  description: t(
                    'flows.missionBoard.missionList.second.onboardingTooltip',
                  ),
                  placement: PlacementEnum.RIGHT,
                  onClose: () => {
                    setIsTouring(false);
                    finishTutorial();
                  },
                },
              },
            ]);

            setIsTouring(true);
          }, 1000);
        }
      }
    }
  }, [
    t,
    account,
    isLoaded,
    setIsTouring,
    setTour,
    setIsOpenMissionListModal,
    finishTutorial,
    projectID,
  ]);

  return (
    <MissionBoardContainerStyled>
      {anchorRef.current && (
        <Popover
          open={isOpenMissionListModal}
          disableEscapeKeyDown
          anchorEl={anchorRef?.current}
          className={isOpenLeftSidebar ? 'opened' : ''}
          onClose={() => setIsOpenMissionListModal(false)}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <PopoverContainerStyled id="mission-board">
            <Box className="title">
              <Typography variant="subtitle2" color="grey.700">
                {missionList[currentLevel - 1].title}
              </Typography>
              <Chip
                sx={{ height: '24px', '& > span': { padding: '0 8px' } }}
                label={`Level ${missionList[currentLevel - 1].level}`}
              />
            </Box>
            <Box className="progress-bar">
              <ProgressBar
                title={t('flows.missionBoard.progressBar', {
                  finished: missionList[currentLevel - 1].completedStep,
                  total: missionList[currentLevel - 1].totalStep,
                })}
                percent={progressPercent}
              />
            </Box>
            <Box className="divider">
              <Divider />
            </Box>
            <Box>
              {missionList[currentLevel - 1].lessonList.map((item) => (
                <MenuItemStyled
                  key={item.id}
                  id={item.gaID}
                  sx={{ padding: '10px 16px' }}
                  onClick={() => {
                    finishTutorial();
                    setIsTouring(false);
                    item.onClick();
                  }}
                >
                  <ListItemIcon>
                    {item.completed ? (
                      <CheckIcon sx={{ color: 'success.main' }} />
                    ) : (
                      <item.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText sx={{ whiteSpace: 'pre-wrap' }}>
                    {item.title}
                  </ListItemText>
                  <Typography
                    variant="body2"
                    color="grey.700"
                    sx={{ textDecoration: 'underline' }}
                  >
                    {t('flows.missionBoard.showMore')}
                  </Typography>
                </MenuItemStyled>
              ))}
            </Box>
            <Box className="divider">
              <Divider />
            </Box>
            <Box sx={{ width: '100%' }}>
              {/* TODO: 更新 pagination storybook */}
              <Pagination
                count={missionList.length}
                defaultPage={currentLevel}
                onChange={(event, page) => {
                  setCurrentLevel(page);
                }}
              />
            </Box>
          </PopoverContainerStyled>
        </Popover>
      )}
      {!isViewer && (
        <MissionButtonStyled
          ref={anchorRef}
          onClick={handleClick}
          className={isOpenLeftSidebar ? 'opened' : ''}
        >
          {isOpenMissionListModal ? (
            <MissionButtonOpenedStyled>
              <KeyboardArrowDownOutlinedIcon
                sx={{ width: '24px', height: '24px', color: 'grey.white' }}
              />
            </MissionButtonOpenedStyled>
          ) : (
            <MissionButtonClosedStyled>
              <Box className="inner-container" id="userguide_click">
                <RocketLaunchOutlinedIcon sx={{ marginRight: '8px' }} />
                {t('flows.missionBoard.toggleButton')}
              </Box>
            </MissionButtonClosedStyled>
          )}
        </MissionButtonStyled>
      )}
    </MissionBoardContainerStyled>
  );
}

export default MissionBoard;

import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum, ModalTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetCommentReplyCondition,
  usePatchCommentReplyCondition,
} from '@frontend/sorghum/data-access';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { get } from 'lodash';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import ThreadsCommentReplyKeywordModal from '../threads-comment-reply-keyword-modal/threads-comment-reply-keyword-modal';
import {
  ApplyButtonStyled,
  ContainerStyled,
  ContentWrapperStyled,
  KeywordStyled,
  ReplyContentStyled,
  ReplyKeywordsWrapper,
  ReplyTitleWrapper,
  ReplyWrapperStyled,
  TitleWrapper,
} from './style';

export const DrawerThreadsCommentReplyConditions = () => {
  const { commentAutoReplyID } = useParams();
  const [t] = useTranslation('threads');
  const { setIsDrawerUpdate } = useContext(EditorCtx);

  const { data: commentReplyDataFromAPI } = useGetCommentReply(
    commentAutoReplyID as string,
  );

  const { data: commentReplyConditionDataFromAPI } =
    useGetCommentReplyCondition(
      get(commentReplyDataFromAPI, 'replyCondition.data.0.id', ''),
    );
  const { mutate: updateCondition } = usePatchCommentReplyCondition();
  const { drawerType, openModal, setDrawerType } = useContext(UICtx);
  const [replyConditionsRadio, setReplyConditionsRadio] = useState(1);
  const [replyKeywords, setReplyKeywords] = useState<string[]>([]);

  const handleRadioChange = useCallback(
    (val: 1 | 2) => {
      setIsDrawerUpdate(true);
      setReplyConditionsRadio(val);
    },
    [setIsDrawerUpdate],
  );

  const handleKeywordTyped = useCallback(
    (newValue: string[]) => {
      setIsDrawerUpdate(true);
      setReplyKeywords([...newValue]);
    },
    [setIsDrawerUpdate],
  );

  const handleApply = useCallback(() => {
    if (commentAutoReplyID && commentReplyConditionDataFromAPI) {
      updateCondition({
        id: commentAutoReplyID,
        isAutoSave: false,
        replyCondition: [
          {
            id: commentReplyConditionDataFromAPI.id,
            name: commentReplyConditionDataFromAPI.name,
            replyTo: replyConditionsRadio,
            replyContentType: commentReplyConditionDataFromAPI.replyContentType,
            isWarning: false,
            ...(replyConditionsRadio === 2 && {
              condition: [
                {
                  keywordCompareType: 2,
                  keyword: replyKeywords,
                },
              ],
            }),
          },
        ],
      });
    }

    setIsDrawerUpdate(false);
    setDrawerType(DrawerTypesEnum.CLOSE);
  }, [
    commentAutoReplyID,
    commentReplyConditionDataFromAPI,
    replyConditionsRadio,
    replyKeywords,
    setDrawerType,
    setIsDrawerUpdate,
    updateCondition,
  ]);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;
    if (replyConditionsRadio === 2 && replyKeywords.length === 0) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [replyConditionsRadio, replyKeywords]);

  useEffect(() => {
    if (drawerType === DrawerTypesEnum.THREADS_COMMENT_REPLY_CONDITIONS) {
      const keywords = get(
        commentReplyConditionDataFromAPI,
        'condition.0.keyword',
        [],
      );
      setReplyConditionsRadio(
        commentReplyConditionDataFromAPI
          ? commentReplyConditionDataFromAPI.replyTo
          : 1,
      );
      setReplyKeywords(keywords);
      setIsDrawerUpdate(false);
    }
  }, [commentReplyConditionDataFromAPI, drawerType, setIsDrawerUpdate]);

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.THREADS_COMMENT_REPLY_CONDITIONS}>
      <ContainerStyled>
        <ContentWrapperStyled>
          <TitleWrapper>
            <Typography variant="h6" color="grey.900">
              {t('commentAutoReply.drawer.title')}
            </Typography>
          </TitleWrapper>

          <ReplyContentStyled>
            <ReplyWrapperStyled>
              <ReplyTitleWrapper>
                <Typography variant="subtitle2" color="black">
                  {t('commentAutoReply.drawer.replyConditions.title')}
                </Typography>
              </ReplyTitleWrapper>
              <RadioGroup row value={replyConditionsRadio}>
                <FormControlLabel
                  value="1"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyConditions.radio1')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(1)}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyConditions.radio2')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(2)}
                />
              </RadioGroup>
            </ReplyWrapperStyled>

            {replyConditionsRadio === 2 && (
              <ReplyWrapperStyled>
                <ReplyTitleWrapper>
                  <Typography variant="subtitle2" color="black">
                    {t('commentAutoReply.drawer.replyConditions.subtitle')}
                  </Typography>
                </ReplyTitleWrapper>

                {replyKeywords && replyKeywords.length > 0 && (
                  <ReplyKeywordsWrapper>
                    <Typography variant="body3" color="black">
                      {t('commentAutoReply.drawer.replyConditions.subContext')}
                    </Typography>
                    {replyKeywords.map((item, index) => (
                      <KeywordStyled
                        key={index}
                        variant="outlined"
                        color="primary"
                        label={item}
                      />
                    ))}
                  </ReplyKeywordsWrapper>
                )}

                <Button
                  onClick={() =>
                    openModal(ModalTypesEnum.THREADS_COMMENT_REPLY_KEYWORD)
                  }
                  size="medium"
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: globalTheme.palette?.['bluegrey']?.[500],
                    border: `1px solid ${globalTheme.palette?.['bluegrey']?.[400]}`,
                  }}
                >
                  {t('commentAutoReply.drawer.replyConditions.button')}
                </Button>
              </ReplyWrapperStyled>
            )}
          </ReplyContentStyled>

          <ApplyButtonStyled
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ height: '48px' }}
          >
            {t('commentAutoReply.drawer.applyButton')}
          </ApplyButtonStyled>
        </ContentWrapperStyled>
      </ContainerStyled>

      <ThreadsCommentReplyKeywordModal
        replyKeywords={replyKeywords}
        handleSubmit={handleKeywordTyped}
      />
    </EditorDrawer>
  );
};

export default memo(DrawerThreadsCommentReplyConditions);

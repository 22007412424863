import { Button, Chip } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '28px 24px',
}));

export const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const ReplyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ReplyTitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyKeywordsWrapper = styled(Box)(({ theme }) => ({
  maxHeight: '320px',
  overflow: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '4px',
}));

export const KeywordStyled = styled(Chip)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${theme.palette['background']['primary']['5']}`,
  backgroundColor: theme.palette['background']['primary']['5'],
  fontSize: '13px',

  '&:hover': {
    border: `1px solid ${theme.palette['background']['primary']['5']}`,
  },
}));

export const ApplyButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: '8px',
}));

import {
  PostPeopleListConditionType,
  PostPeopleListResponse,
} from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { PEOPLE_LIST } from '../apis';

const onboardingData = (
  googleStorageEndpoint: string,
): PostPeopleListResponse => {
  return {
    offset: 0,
    limit: 50,
    contacts: [
      {
        id: '08dd1890-e49f-459c-94f7-d2bd166c4532',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_1.jpeg`,
        socialId: '7359212404179947',
        name: 'Pu Chipu',
        notification: true,
        lastActive: '2024-1-06T02:24:04.283Z',
        joined: '2024-11-06T02:23:09.542Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
      {
        id: '9666185c-d01d-415e-80b0-86aebc677d1d',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_2.jpeg`,
        socialId: '27542937658688104',
        name: 'Maria Luzon',
        notification: true,
        lastActive: '2024-11-07T09:10:24.798Z',
        joined: '2024-11-06T02:22:03.185Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
      {
        id: '007af634-f94b-4e07-9cba-98283ba342b3',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_3.jpeg`,
        socialId: '8844237865603126',
        name: 'Ene Wei',
        notification: true,
        lastActive: '2024-9-02T09:25:16.649Z',
        joined: '2024-11-06T02:21:14.437Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
      {
        id: '8ab27db4-8b08-484c-8330-3ff2d1db287a',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_4.jpeg`,
        socialId: '8882329515163852',
        name: 'Lauren Shoreland',
        notification: false,
        lastActive: '2024-4-04T02:20:07.368Z',
        joined: '2024-11-06T02:20:07.368Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
      {
        id: 'd5bac7cc-f518-45f9-93ec-a2bce0a48b0d',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_5.jpeg`,
        socialId: '24741639745451542',
        name: 'Shuaib Ahmed',
        notification: true,
        lastActive: '2024-1-27T09:10:52.896Z',
        joined: '2024-11-06T02:19:17.484Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
      {
        id: '6e93c14e-162b-4cd4-b426-6393e3d2e978',
        headshot: `${googleStorageEndpoint}/static/image/onboarding_customer_6.jpeg`,
        socialId: '7176390559065120',
        name: 'Emily Ho',
        notification: true,
        lastActive: '2024-2-03T09:09:42.213Z',
        joined: '2024-11-06T02:10:45.290Z',
        subscribedSource: 'demo',
        tags: [],
        attribute: [],
        sequence: [],
      },
    ],
    totalCount: 6,
    filterCount: 6,
  };
};

export interface PostPeopleListProps {
  limit: number;
  start?: string | null;
  end?: string | null;
  sortBy?: string;
  orderBy?: string;
  offset?: number;
  condition?: PostPeopleListConditionType[];
  // 1-and/2-or
  filterCriteria?: 1 | 2;
  fake?: boolean;
  googleStorageEndpoint?: string;
}

const defaultValue = {
  totalCount: 0,
  filterCount: 0,
  contacts: [],
  limit: 0,
  offset: 0,
};

const postPeopleList = ({
  sortBy,
  orderBy,
  offset,
  limit,
  filterCriteria,
  condition,
}: PostPeopleListProps): Promise<PostPeopleListResponse> =>
  axios
    .post(PEOPLE_LIST, {
      ...(sortBy && { sortBy }),
      ...(orderBy && { orderBy }),
      ...(condition && condition.length > 0
        ? { condition, filterCriteria }
        : // 沒有條件則帶入 all
          { condition: [], filterCriteria: 1 }),
      offset,
      limit,
    })
    .then((res) => res.data.data)
    .catch((_) => defaultValue);

// postContacts
export const usePostPeopleList = ({
  sortBy,
  orderBy,
  offset,
  limit,
  filterCriteria,
  condition,
  fake,
  googleStorageEndpoint,
}: PostPeopleListProps) => {
  return useQuery<PostPeopleListResponse>({
    queryKey: [
      'post-people-list',
      {
        filterCriteria,
        sortBy,
        orderBy,
        offset,
        limit,
        condition,
        fake,
        googleStorageEndpoint,
      },
    ],
    queryFn: () => {
      if (fake && googleStorageEndpoint) {
        return onboardingData(googleStorageEndpoint);
      }
      return postPeopleList({
        sortBy,
        orderBy,
        offset,
        limit,
        filterCriteria,
        condition,
      });
    },
  });
};

export default usePostPeopleList;

import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import {
  ActionCellTypesEnum,
  BaseElement,
  CategoryValueTypesEnum,
  CouponCellTypesEnum,
  DrawerTypesEnum,
  ElementTypesEnum,
  NodeTypesEnum,
  Rules,
  TextButtonCellTypesEnum,
} from '@frontend/editor/interface';
import {
  NEW_ELEMENT_POSITION_DISTANCE,
  getEdge,
  getUniquePosition,
  uuid,
} from '@frontend/editor/utils';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Node,
  XYPosition,
  applyEdgeChanges,
  applyNodeChanges,
  useReactFlow,
  useViewport,
} from 'reactflow';
import { useCanvasTemplate } from '../use-canvas-template/use-canvas-template';
import useGetCategoryValue from '../use-get-category-value/use-get-category-value';
import useGetProjectID from '../use-get-project-id/use-get-project-id';

export function useCanvasAdd() {
  const {
    nodes,
    elements,
    blockSerialNumber,
    ref,
    setNodes,
    setEdges,
    getElementByOutputID,
    getElement,
    setBlockSerialNumber,
    addElement,
    connectElement,
    setOnFocusCellID,
  } = useContext(EditorCtx);
  const uiState = useContext(UICtx);
  const { setCenter } = useReactFlow();
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: categoryValues } = useGetCategoryValue({
    projectID: projectID as string,
    type: CategoryValueTypesEnum.RN_TOPIC,
  });
  const { zoom, x: viewportCenterX, y: viewportCenterY } = useViewport();
  const innerWidth = ref.current?.clientWidth || 0;
  const innerHeight = ref.current?.clientHeight || 0;
  const centerX = (innerWidth / 2 - viewportCenterX) / zoom;
  const centerY = (innerHeight / 2 - viewportCenterY) / zoom;
  const {
    addSendCouponTemplate,
    addShowCouponTemplate,
    addRedeemCouponTemplate,
  } = useCanvasTemplate();

  const connect = useCallback(
    (source: BaseElement, target: BaseElement) => {
      connectElement(source.outputID, target.id);
      const newEdge = getEdge(source, target);
      if (newEdge) {
        setEdges((edges) =>
          applyEdgeChanges(
            [
              {
                id: newEdge.id,
                type: 'remove',
              },
              { item: newEdge, type: 'add' },
            ],
            edges,
          ),
        );
      }
    },
    [connectElement, setEdges],
  );

  const removeCreateBlockMenu = useCallback(() => {
    setNodes((prev) =>
      prev.filter((n) => n.type !== NodeTypesEnum.CREATE_MENU_NODE),
    );
  }, [setNodes]);

  const focusToNode = useCallback(
    (newPosition: XYPosition, outputID?: string) => {
      // 要注意不要被開啟的 drawer 擋住
      setCenter(newPosition.x, newPosition.y, {
        zoom,
        duration: 1000,
      });
    },
    [setCenter, zoom],
  );

  const generateNewElement = useCallback(
    (type: ElementTypesEnum, additionalData?: any) => {
      switch (type) {
        case ElementTypesEnum.BLOCK:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.SHARE_LINK:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            shareLinkID: uuid(),
            cellID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            createDate: new Date().getTime(),
            data: {
              fileID: '',
              fileUrl: '',
              title: '',
              buttonText: '',
            },
            ...additionalData,
          };
        case ElementTypesEnum.COUPON:
          return {
            id: uuid(),
            elementID: uuid(),
            inputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            targetID: '',
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.COUPON_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            cellType: CouponCellTypesEnum.SUCCESS,
            ...additionalData,
          };
        case ElementTypesEnum.TEXT_BUTTON:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.TEXT_BUTTON_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            label: '',
            ...additionalData,
          };
        case ElementTypesEnum.RECURRING_NOTIFICATION:
          return {
            id: uuid(),
            outputID: uuid(),
            cellID: uuid(),
            elementType: type,
            elementName: t('canvas.recurringNotification.drawer.title'),
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            fileID: '',
            fileUrl: '',
            topic:
              categoryValues && categoryValues.length > 0
                ? categoryValues[0].id
                : '',
            title: '',
            sourceID: '',
            imageType: 1,
            reoptin: false,
            ...additionalData,
          };
        case ElementTypesEnum.ENTRY_POINT:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            position: {
              x: 0,
              y: 0,
            },
            targetID: '',
            children: [],
            data: {},
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.IMAGE:
          return {
            id: uuid(),
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            elementName: t('canvas.editorImage.label'),
            ...additionalData,
          };
        case ElementTypesEnum.ACTION:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            children: [],
            targetID: '',
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.ACTION_ELEMENT:
          return {
            id: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.CONDITION:
          return {
            id: uuid(),
            elementID: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            position: {
              x: 0,
              y: 0,
            },
            data: {},
            targetID: '',
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.CONDITION_CELL:
          return {
            id: uuid(),
            outputID: uuid(),
            elementType: type,
            createDate: new Date().getTime(),
            ...additionalData,
          };
        case ElementTypesEnum.COLLECT_USER_ANSWER:
          return {
            id: uuid(),
            outputID: uuid(),
            cellID: uuid(),
            elementType: type,
            elementName: t('canvas.blockMenu.collectUserAnswerButton'),
            targetID: '',
            children: [],
            data: {
              text: '',
              attributeId: '',
              periodType: 4,
              periodValue: 30,
              isAllowFreeInput: false,
              freeInputID: uuid(),
            },
            createDate: new Date().getTime(),
            title: '',
            sourceID: '',
            ...additionalData,
          };
        default:
          return {
            id: uuid(),
            inputID: uuid(),
            outputID: uuid(),
            elementType: type,
            children: [],
            createDate: new Date().getTime(),
            ...additionalData,
          };
      }
    },
    [categoryValues, t],
  );

  // 取得預設的 elements
  const getInitialElements = () => {
    // 新增 flow 一定會有 entry-point 跟一個 block
    const block = generateNewElement(ElementTypesEnum.BLOCK, {
      position: {
        x: 400,
        y: 0,
      },
      label: t('canvas.blockDefaultTitle', {
        count: 1,
      }),
    });

    const entry = generateNewElement(ElementTypesEnum.ENTRY_POINT, {
      targetID: block.id,
    });

    const newChart = [entry, block];
    return newChart;
  };

  const addTextButton = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.TEXT_BUTTON, {
        nodeID: parentID,
        parentID,
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  const addCollectUserAnswer = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(
        ElementTypesEnum.COLLECT_USER_ANSWER,
        {
          nodeID: parentID,
          parentID,
        },
      );
      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  const addRecurringNotification = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(
        ElementTypesEnum.RECURRING_NOTIFICATION,
        {
          nodeID: parentID,
          parentID,
        },
      );
      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  // 新增 image 元件
  const addImage = useCallback(
    (parentID: string, addIndex?: number) => {
      const image = generateNewElement(ElementTypesEnum.IMAGE, {
        nodeID: parentID,
        parentID,
      });
      addElement(image, parentID, addIndex);
    },
    [addElement, generateNewElement],
  );

  // 新增 Gallery 元件
  const addGallery = useCallback(
    (parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.GALLERY, {
        nodeID: parentID,
        parentID,
        ratio: 2,
      });
      addElement(newElement, parentID, addIndex);
      const newCell = generateNewElement(ElementTypesEnum.GALLERY_IMAGE, {
        nodeID: parentID,
        parentID: newElement.id,
      });
      addElement(newCell, newElement.id);

      setOnFocusCellID(newCell.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  // 新增 Gallery 元件
  const addGalleryImage = useCallback(
    (nodeID: string, parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.GALLERY_IMAGE, {
        nodeID,
        parentID,
      });
      addElement(newElement, parentID, addIndex);
    },
    [addElement, generateNewElement],
  );

  const addCellButton = useCallback(
    (parentID: string) => {
      const textButtonCell = generateNewElement(
        ElementTypesEnum.TEXT_BUTTON_CELL,
        {
          buttonType: TextButtonCellTypesEnum.BLOCK,
          buttonData: {},
          parentID,
        },
      );

      addElement(textButtonCell, parentID);
      setOnFocusCellID(textButtonCell.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  const addOptionCellButton = useCallback(
    (parentID: string, type: string) => {
      const textButtonCell = generateNewElement(ElementTypesEnum.OPTION_CELL, {
        data: {
          type,
        },
        parentID,
      });

      addElement(textButtonCell, parentID);
    },
    [addElement, generateNewElement],
  );

  const addBlock = useCallback(
    (
      outputID?: string,
      position?: XYPosition,
      focus = true,
      initialData?: object,
    ): string => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const block = generateNewElement(ElementTypesEnum.BLOCK, {
        position: {
          x: newPosition.x,
          y: newPosition.y,
        },
        label: t('canvas.blockDefaultTitle', {
          count: blockSerialNumber + 1,
        }),
        ...initialData,
      });

      addElement(block);
      setBlockSerialNumber(blockSerialNumber + 1);

      if (outputID) {
        const sourceElement = getElementByOutputID(outputID);
        if (sourceElement) connect(sourceElement, block);
      }

      if (focus) {
        focusToNode(newPosition, block.id);
      }

      return block.id;
    },
    [
      addElement,
      blockSerialNumber,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      generateNewElement,
      getElementByOutputID,
      setBlockSerialNumber,
      t,
    ],
  );

  const addNodeEditorMenu = useCallback(
    (sourceID: string, nodeID: string) => {
      const id = uuid();
      const parentBlock = getElement(nodeID);

      const _position = parentBlock?.position || { x: 0, y: 0 };

      if (parentBlock) {
        const newNode = {
          id,
          position: {
            x: _position.x + 500,
            y: _position.y,
          },
          data: { sourceOutputID: sourceID },
          type: NodeTypesEnum.CREATE_MENU_NODE,
          zIndex: 99,
          selected: true
        } as Node;

        setNodes((prev) =>
          applyNodeChanges(
            [
              {
                item: newNode,
                type: 'add',
              },
            ],
            prev,
          ),
        );
        setEdges((prev) =>
          applyEdgeChanges(
            [
              {
                id: sourceID,
                type: 'remove',
              },
              {
                item: {
                  id: sourceID,
                  source: nodeID,
                  sourceHandle: sourceID,
                  target: id,
                },
                type: 'add',
              },
            ],
            prev,
          ),
        );
        // 為避免透過 go to block 新增 block 時 create block menu 的位置超出畫布，調整 focus 位置以避免其發生
        focusToNode({ x: newNode.position.x, y: newNode.position.y + 200 });
      }
    },
    [focusToNode, getElement, setEdges, setNodes],
  );

  const addShareLink = useCallback(
    (outputID?: string, position?: XYPosition) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const shareLink = generateNewElement(ElementTypesEnum.SHARE_LINK, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
        label: t('canvas.shareLinkDefaultTitle', {
          count: blockSerialNumber + 1,
        }),
      });

      addElement(shareLink);
      setBlockSerialNumber(blockSerialNumber + 1);

      if (outputID) {
        const sourceElement = getElementByOutputID(outputID);
        if (sourceElement) connect(sourceElement, shareLink);
      }

      focusToNode(newPosition, shareLink.id);
      return shareLink.id;
    },
    [
      addElement,
      blockSerialNumber,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      generateNewElement,
      getElementByOutputID,
      setBlockSerialNumber,
      t,
    ],
  );

  const addSendCoupon = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addSendCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      ) as BaseElement;

      if (couponElement) {
        if (outputID) {
          const sourceElement = getElementByOutputID(outputID);
          if (sourceElement) connect(sourceElement, couponElement);
        }

        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          applyNodeChanges(
            [
              {
                id: couponElement.id,
                type: 'select',
                selected: true,
              },
            ],
            nodes,
          );
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      addSendCouponTemplate,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      getElementByOutputID,
      nodes,
      uiState,
    ],
  );

  const addShowCoupon = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addShowCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      ) as BaseElement;

      if (couponElement) {
        if (outputID) {
          const sourceElement = getElementByOutputID(outputID);
          if (sourceElement) connect(sourceElement, couponElement);
        }
        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          applyNodeChanges(
            [
              {
                id: couponElement.id,
                type: 'select',
                selected: true,
              },
            ],
            nodes,
          );
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      addShowCouponTemplate,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      getElementByOutputID,
      nodes,
      uiState,
    ],
  );

  const addRedeemCoupon = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const template = addRedeemCouponTemplate(newPosition);
      const couponElement = template.find(
        (i) => i.elementType === ElementTypesEnum.COUPON,
      ) as BaseElement;
      if (couponElement) {
        if (outputID) {
          const sourceElement = getElementByOutputID(outputID);
          if (sourceElement) connect(sourceElement, couponElement);
        }
        if (focus) {
          focusToNode(newPosition, couponElement.id);
        } else {
          applyNodeChanges(
            [
              {
                id: couponElement.id,
                type: 'select',
                selected: true,
              },
            ],
            nodes,
          );
        }
        uiState.setDrawerType(DrawerTypesEnum.COUPON);
      }
      return couponElement?.id ?? '';
    },
    [
      addRedeemCouponTemplate,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      getElementByOutputID,
      nodes,
      uiState,
    ],
  );

  const addCondition = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.CONDITION, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      newElement.label = t('canvas.conditionDefaultTitle', {
        count: blockSerialNumber + 1,
      });

      addElement(newElement);
      setBlockSerialNumber(blockSerialNumber + 1);

      if (outputID) {
        const sourceElement = getElementByOutputID(outputID);
        if (sourceElement) connect(sourceElement, newElement);
      }

      const newCell = generateNewElement(ElementTypesEnum.CONDITION_CELL, {
        nodeID: newElement.id,
        parentID: newElement.id,
        rule: Rules.IS,
      });
      addElement(newCell, newElement.id);

      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      setOnFocusCellID(newCell.id);
      uiState.setDrawerType(DrawerTypesEnum.CONDITION_CELL);

      return newElement.id;
    },
    [
      addElement,
      blockSerialNumber,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      generateNewElement,
      getElementByOutputID,
      setBlockSerialNumber,
      setOnFocusCellID,
      t,
      uiState,
    ],
  );

  const addConditionElse = useCallback(
    (parentID: string, addIndex?: number) => {
      const newElement = generateNewElement(ElementTypesEnum.CONDITION_CELL, {
        nodeID: parentID,
        parentID,
        rule: Rules.ELSE,
      });

      addElement(newElement, parentID, addIndex);
      setOnFocusCellID(newElement.id);
    },
    [addElement, generateNewElement, setOnFocusCellID],
  );

  const addAction = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.ACTION, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      newElement.label = t('canvas.actionDefaultTitle', {
        count: blockSerialNumber + 1,
      });

      addElement(newElement);
      setBlockSerialNumber(blockSerialNumber + 1);

      if (outputID) {
        const sourceElement = getElementByOutputID(outputID);
        if (sourceElement) connect(sourceElement, newElement);
      }
      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      uiState.setDrawerType(DrawerTypesEnum.CONDITION_CELL);

      return newElement.id;
    },
    [
      addElement,
      blockSerialNumber,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      generateNewElement,
      getElementByOutputID,
      setBlockSerialNumber,
      t,
      uiState,
    ],
  );

  const addActionSetAttribute = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.SET,
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_ATTRIBUTE);
    },
    [addElement, generateNewElement, setOnFocusCellID, uiState],
  );

  const addActionRemoveAttribute = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.REMOVE,
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_ATTRIBUTE);
    },
    [addElement, generateNewElement, setOnFocusCellID, uiState],
  );

  const addActionSubscribeSequence = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.SUBSCRIBE_SEQUENCE,
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_SEQUENCE);
    },
    [addElement, generateNewElement, setOnFocusCellID, uiState],
  );

  const addActionUnsubscribeSequence = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.UNSUBSCRIBE_SEQUENCE,
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_SEQUENCE);
    },
    [addElement, generateNewElement, setOnFocusCellID, uiState],
  );

  const addActionExportGoogleSheet = useCallback(
    (parentID: string, addIndex?: number) => {
      const cellItem = generateNewElement(ElementTypesEnum.ACTION_ELEMENT, {
        nodeID: parentID,
        parentID,
        actionType: ActionCellTypesEnum.EXPORT_GOOGLE_SHEET,
        data: {
          properties: [],
        },
      });

      addElement(cellItem, parentID, addIndex);
      setOnFocusCellID(cellItem.id);
      uiState.setDrawerType(DrawerTypesEnum.ACTION_EXPORT_GOOGLE_SHEET);
    },
    [addElement, generateNewElement, setOnFocusCellID, uiState],
  );

  const addNote = useCallback(
    (position?: XYPosition, focus = true) => {
      // 新 block 的 x,y 座標
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.NOTE, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
      });

      addElement(newElement);
      if (focus) {
        focusToNode(newPosition, newElement.id);
      }
      return newElement.id;
    },
    [addElement, centerX, centerY, elements, focusToNode, generateNewElement],
  );

  const addJumpToFlow = useCallback(
    (outputID?: string, position?: XYPosition, focus = true) => {
      const newPosition =
        position ||
        getUniquePosition(
          centerX,
          centerY,
          elements,
          NEW_ELEMENT_POSITION_DISTANCE,
        );

      const newElement = generateNewElement(ElementTypesEnum.JUMP_TO_FLOW, {
        position: {
          x: newPosition.x ?? 0,
          y: newPosition.y ?? 0,
        },
        data: {
          elementID: uuid(),
        },
      });

      newElement.label = t('canvas.conditionDefaultTitle', {
        count: blockSerialNumber + 1,
      });

      addElement(newElement);
      setBlockSerialNumber(blockSerialNumber + 1);

      if (outputID) {
        const sourceElement = getElementByOutputID(outputID);
        if (sourceElement) connect(sourceElement, newElement);
      }

      if (focus) {
        focusToNode(newPosition, newElement.id);
      }

      return newElement.id;
    },
    [
      addElement,
      blockSerialNumber,
      centerX,
      centerY,
      connect,
      elements,
      focusToNode,
      generateNewElement,
      getElementByOutputID,
      setBlockSerialNumber,
      t,
    ],
  );

  return {
    removeCreateBlockMenu,
    getInitialElements,
    generateNewElement,
    addCellButton,
    addRecurringNotification,
    addTextButton,
    addBlock,
    addNodeEditorMenu,
    addShareLink,
    addSendCoupon,
    addShowCoupon,
    addRedeemCoupon,
    addCondition,
    addConditionElse,
    addAction,
    addActionSetAttribute,
    addActionRemoveAttribute,
    addActionSubscribeSequence,
    addActionUnsubscribeSequence,
    addActionExportGoogleSheet,
    addImage,
    addGallery,
    addGalleryImage,
    addNote,
    addCollectUserAnswer,
    addOptionCellButton,
    addJumpToFlow,
  };
}

export default useCanvasAdd;

import { Button } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '28px 24px',
}));

export const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const ReplyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ReplyTitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyPostsWrapperStyled = styled(Box)(({ theme }) => ({
  maxHeight: '320px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const ReplyPostRowStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '24px',
  padding: '12px 16px',

  '& > img': {
    width: '44px',
    height: '44px',
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '4px',
  },
}));

export const ReplyPostRowDescriptionStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  overflow: 'hidden',

  '& > div': {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const ApplyButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: '8px',
}));

import {
  ContextModalState,
  DrawerTypesEnum,
  ModalContext,
  ModalState,
  ModalTypesEnum,
} from '@frontend/editor/interface';
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';

export const UICtx = createContext<ModalContext>({} as ModalContext);

export const UIProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [contextModalState, setContextModalState] =
    useState<ContextModalState>();
  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    info: '',
    content: '',
    handleConfirm: () => null,
  });
  // 控制打開的 modal
  const [modalType, setModalType] = useState<ModalTypesEnum>(
    ModalTypesEnum.CLOSE,
  );
  const [drawerType, setDrawerType] = useState<DrawerTypesEnum>(
    DrawerTypesEnum.CLOSE,
  );

  // view drawer 的下拉選單
  const [entryDataType, setEntryDataType] = useState<string>('ALL');
  const [viewFlowEntryType, setViewFlowEntryType] = useState(1);
  const [viewFlowEntryID, setViewFlowEntryID] = useState('');

  const [hoveredElement, setHoveredElement] = useState({ id: '', layer: 0 });

  const [isLoading, setIsLoading] = useState<string[]>([]);

  const openModal = useCallback((type: ModalTypesEnum) => {
    setModalType(type);
  }, []);

  const closeModal = useCallback(() => {
    setModalType(ModalTypesEnum.CLOSE);
  }, []);

  // id: hovered 物件 id, layer: 物件的層級, Canvas 為 0, Block 為 1, Element 為 2, Cell 為 3
  const onHoverElement = (id: string, layer: number) => {
    setHoveredElement({ id, layer });
  };

  const addLoading = useCallback(
    (id: string) => {
      if (!isLoading.includes(id)) {
        setIsLoading([...isLoading, id]);
      }
    },
    [isLoading],
  );

  const removeLoading = useCallback(
    (id: string) => {
      if (isLoading.includes(id)) {
        setIsLoading(isLoading.filter((i) => i !== id));
      }
    },
    [isLoading],
  );

  const resetLoading = useCallback(() => {
    setIsLoading([]);
  }, []);

  const closeDrawer = useCallback(
    () => setDrawerType(DrawerTypesEnum.CLOSE),
    [],
  );

  return (
    <UICtx.Provider
      value={{
        contextModalState,
        entryDataType,
        viewFlowEntryID,
        viewFlowEntryType,
        modalState,
        modalType,
        hoveredElement,
        drawerType,
        isLoading: isLoading.length > 0,
        setContextModalState,
        setViewFlowEntryID,
        setViewFlowEntryType,
        setEntryDataType,
        addLoading,
        removeLoading,
        resetLoading,
        setDrawerType,
        closeDrawer,
        setModalState,
        openModal,
        closeModal,
        onHoverElement,
      }}
    >
      {children}
    </UICtx.Provider>
  );
};

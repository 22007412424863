import {
  useMailVerify,
  usePasswordVerify,
} from '@frontend/sorghum/features/mail-verify';
import { useSignupMicro } from '@frontend/sorghum/features/micro';
import { SELECT_CHANNEL, withPageBoundary } from '@frontend/sorghum/utils';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import {
  EMAIL,
  LIMIT,
  LOGIN,
  MICRO,
  PAGES,
  PASSWORD,
  PROJECT_INVITE,
  RESET,
  SIGNUP,
  VERIFY,
  VERIFY_UNAVAILABLE,
} from '@frontend/sorghum/utils';
import { useRoutes } from './useRoutes';

const Home = lazy(() =>
  import('@frontend/sorghum/features/home').then(
    ({ SorghumFeaturesHome: Page }) => ({
      default: Page,
    }),
  ),
);

const Login = lazy(() =>
  import('@frontend/sorghum/features/login').then(
    ({ SorghumFeaturesLogin: Page }) => ({
      default: Page,
    }),
  ),
);

const MailVerify = lazy(() =>
  import('@frontend/sorghum/features/mail-verify').then(
    ({ SorghumFeaturesMailVerify: Page }) => ({
      default: Page,
    }),
  ),
);

const VerifyExpired = lazy(() =>
  import('@frontend/sorghum/features/verify-unavailable').then(
    ({ SorghumFeaturesVerifyUnavailable: Page }) => ({
      default: Page,
    }),
  ),
);

const ResetPassword = lazy(() =>
  import('@frontend/sorghum/features/reset-password').then(
    ({ SorghumFeaturesResetPassword: Page }) => ({
      default: Page,
    }),
  ),
);

const ResetPasswordForm = lazy(() =>
  import('@frontend/sorghum/features/reset-password-form').then(
    ({ SorghumFeaturesResetPasswordForm: Page }) => ({
      default: Page,
    }),
  ),
);

const Micro = lazy(() =>
  import('@frontend/sorghum/features/micro').then(
    ({ SorghumFeaturesMicro: Page }) => ({
      default: Page,
    }),
  ),
);

const EmailLimit = lazy(() =>
  import('@frontend/sorghum/features/email-limit').then(
    ({ SorghumFeaturesEmailLimit: Page }) => ({
      default: Page,
    }),
  ),
);

const FacebookPages = lazy(() =>
  import('@frontend/sorghum/features/facebook-pages').then(
    ({ SorghumFeaturesFacebookPages: Page }) => ({
      default: Page,
    }),
  ),
);

const ProjectInvite = lazy(() =>
  import('@frontend/sorghum/features/project-invite').then(
    ({ SorghumFeaturesProjectInvite: Page }) => ({
      default: Page,
    }),
  ),
);

const SelectChannel = lazy(() =>
  import('@frontend/sorghum/features/select-channel').then(
    ({ SorghumFeaturesSelectChannel: Page }) => ({
      default: Page,
    }),
  ),
);

export const HomeRoute = () => {
  const { mode, facebookAppID, permission } = useRoutes();

  return (
    <Route element={withPageBoundary(<Home />)}>
      <Route
        path={LOGIN}
        element={
          <Login
            mode={mode}
            facebookAppID={facebookAppID}
            permission={permission}
            pageType="LOGIN"
          />
        }
      />
      <Route
        path={SIGNUP}
        element={
          <Login
            mode={mode}
            facebookAppID={facebookAppID}
            permission={permission}
            pageType="SIGNUP"
          />
        }
      />
      <Route path={VERIFY}>
        <Route path={EMAIL} element={<MailVerify usePage={useMailVerify} />} />
        <Route path={VERIFY_UNAVAILABLE} element={<VerifyExpired />} />
        <Route
          path={PASSWORD}
          element={<MailVerify usePage={usePasswordVerify} />}
        />
        <Route path={LIMIT} element={<EmailLimit />} />
      </Route>
      <Route path={RESET}>
        <Route index element={<ResetPassword />} />

        <Route path={PASSWORD} element={<ResetPasswordForm />} />
      </Route>
      <Route path={MICRO} element={<Micro usePage={useSignupMicro} />} />
      <Route path={SELECT_CHANNEL} element={<SelectChannel />} />
      <Route
        path={PAGES}
        element={
          <FacebookPages
            facebookAppID={facebookAppID}
            permission={permission}
            mode={mode}
          />
        }
      />
      <Route
        index
        element={
          <Login
            mode={mode}
            facebookAppID={facebookAppID}
            permission={permission}
            pageType="LOGIN"
          />
        }
      />
      <Route path={PROJECT_INVITE} element={<ProjectInvite />} />
    </Route>
  );
};

import { useGetAccountTutorial } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum, SocialTypeEnum } from '@frontend/sorghum/interface';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useMessenger = () => {
  const socialType = SocialTypeEnum.FACEBOOK;
  const [t] = useTranslation('messenger');
  const { modalType, openModal } = useContext(UICtx);
  const { data: tutorialData } = useGetAccountTutorial();

  useEffect(() => {
    // 如果第一次進入此頁並且沒有開啟任何任務 modal 則自動開啟教程
    if (tutorialData?.marketingGuru) {
      if (!tutorialData?.marketingGuru.customer.hasInteracted) {
        if (modalType === ModalTypesEnum.CLOSE) {
          openModal(ModalTypesEnum.LEVEL2_LESSON1);
        }
      }
    }
  }, [modalType, openModal, tutorialData?.marketingGuru]);

  return {
    socialType,
    t,
  };
};

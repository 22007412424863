import { GetCommentAutoReplyConditionResponse } from '@frontend/sorghum/interface';
import { FAKE_COMMENT_DATA_ID } from '@frontend/sorghum/utils';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_CONDITION } from '../apis';

const defaultData = {
  id: '',
  name: '',
  replyTo: 1,
  replyContentType: 1,
  publicReplyContent: [''],
  condition: [],
} as GetCommentAutoReplyConditionResponse;

async function getCommentReplyCondition(id: string) {
  return axios
    .get(`${COMMENT_REPLY_CONDITION}?id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultData;
    });
}

export function useGetCommentReplyCondition(id: string) {
  return useQuery<GetCommentAutoReplyConditionResponse>({
    queryKey: ['get-comment-reply-condition', { id }],
    queryFn: () => {
      // comment auto reply 的 tutorial 假資料不需要打 API
      if (id && id !== FAKE_COMMENT_DATA_ID) {
        return getCommentReplyCondition(id);
      } else {
        return defaultData;
      }
    },
  });
}

import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { useTranslation } from 'react-i18next';

export const useThreads = () => {
  const socialType = SocialTypeEnum.THREADS;
  const [t] = useTranslation('threads');

  return {
    socialType,
    t,
  };
};

import {
  GOOGLE_API_SCOPE_ENUM,
  GoogleAuthContext,
} from '@frontend/sorghum/interface';
import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from 'react';

interface GoogleAuthProviderProps {
  redirectTo: string;
  clientID: string;
}

const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

export const GoogleAuthCtx = createContext<GoogleAuthContext>(
  {} as GoogleAuthContext,
);

const windowStyle = 'width=500,height=800';
const domain = window.location.origin;

export const GoogleAuthProvider: React.FC<
  PropsWithChildren<GoogleAuthProviderProps>
> = ({ clientID, redirectTo, children }) => {
  const [refreshed, setRefreshed] = useState<boolean>(false);
  const getGoogleAuthUrl = (scope: string) => {
    const url = `${oauth2Endpoint}?scope=${scope}&include_granted_scopes=true&response_type=code&access_type=offline&prompt=consent&client_id=${clientID}&redirect_uri=${decodeURIComponent(
      domain + redirectTo,
    )}`;
    return url;
  };

  const openAuthWindow = (scopes: GOOGLE_API_SCOPE_ENUM[]) => {
    setRefreshed(false);
    const url = getGoogleAuthUrl(scopes.join(' '));
    window.open(url, '_blank', windowStyle);
  };

  const googleSheetAuth = () => {
    const scope = [
      GOOGLE_API_SCOPE_ENUM.USERINFO_EMAIL,
      GOOGLE_API_SCOPE_ENUM.USERINFO_PROFILE,
      GOOGLE_API_SCOPE_ENUM.DRIVE_FILE,
    ];

    openAuthWindow(scope);
  };

  useEffect(() => {
    const timerID = setInterval(() => {
      if (!refreshed && localStorage.getItem('refresh-google-code')) {
        setRefreshed(true);
        localStorage.removeItem('refresh-google-code');
      }
    }, 2000);

    return () => {
      clearInterval(timerID);
    };
  }, [refreshed]);

  return (
    <GoogleAuthCtx.Provider
      value={{ refreshed, googleSheetAuth, openAuthWindow }}
    >
      {children}
    </GoogleAuthCtx.Provider>
  );
};

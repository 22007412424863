import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material';
import { FC } from 'react';

interface TooltipProps extends MuiTooltipProps {
  // true 時不會出現 tooltip
  disabled?: boolean;
  offset?: [number, number];
}

/**
 * Renders a tooltip component.
 * @param {boolean} props.disabled - disabled tooltip.
 * @return {React.ReactNode} The rendered tooltip component.
 */
export const Tooltip: FC<TooltipProps> = ({
  disabled,
  children,
  offset = [0, -12],
  ...props
}: TooltipProps) => {
  if (disabled) {
    return children;
  } else {
    return (
      <MuiTooltip
        {...props}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: offset, // 設置與元素之間的距離 [水平, 垂直]
              },
            },
          ],
        }}
      >
        {children}
      </MuiTooltip>
    );
  }
};

export default Tooltip;

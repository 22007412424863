import {
  Button,
  ButtonMenu,
  Dialog,
  MenuItem,
  Typography,
} from '@frontend/components/ui';
import { Alert } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import {
  useDeleteProjectPermission,
  useGetAccountInfo,
  useGetProject,
  useGetProjectPermission,
  usePostProjectInvite,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  AccountPermission,
  GetProjectPermission,
  ModalTypesEnum,
  UseSettingType,
} from '@frontend/sorghum/interface';
import {
  BillingInformation,
  Box,
  FacebookErrorModal,
  FacebookPagesModal,
} from '@frontend/sorghum/ui';
import { copyToClipboard, usePath } from '@frontend/sorghum/utils';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Avatar, Grid, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
import { get } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  HeadShotContainerStyled,
  InviteInfoContainerStyled,
  ItemContainerStyled,
  ItemInfoContainerStyled,
  ItemsContainerStyled,
  PageContainerStyled,
  PaperStyled,
  RightContainerStyled,
  TitleContainerStyled,
} from './styles';

export interface SorghumFeaturesSettingProps {
  appID: string;
  permission: string;
  mode: string;
  usePage: (
    facebookAppID: string,
    permission: string,
    mode: string,
  ) => UseSettingType;
}

export function SorghumFeaturesSetting({
  appID,
  permission,
  mode,
  usePage,
}: SorghumFeaturesSettingProps) {
  const {
    pages,
    isConnect,
    isConnecting,
    facebookModalOpen,
    facebookErrorModal,
    connectionErrorMessage,
    errorPageID,
    closeFacebookErrorModal,
    refreshPage,
    openModal,
    closeModal,
    disconnectPages,
    connectPages,
    TeamMemberInviteDescription,
    isDisabledInviteButton,
    Icon,
    connectedPageLink,
    connectedPageName,
    accountName,
    socialTypeName,
    planTitle,
    price,
    period,
    billing,
  } = usePage(appID, permission, mode);

  const [t] = useTranslation(socialTypeName.toLowerCase());
  const UIState = useContext(UICtx);

  const { navigateToUpgrade } = usePath();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { data: projectPermissionListData } = useGetProjectPermission();
  const { mutate: deleteMember } = useDeleteProjectPermission();
  const { data: account } = useGetAccountInfo();

  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const [isOpenDeleteMemberModal, setIsOpenDeleteMemberModal] = useState(false);
  useState<boolean>(false);
  const [memberData, setMemberData] = useState({
    id: '',
    name: '',
    permission: 2,
  });
  const [inviteLink, setInviteLink] = useState('');
  const [inviteLinkPermission, setInviteLinkPermission] = useState(
    AccountPermission.ADMIN,
  );

  const { mutate: postInviteLink } = usePostProjectInvite();

  const handleConfirmDeleteMember = () => {
    deleteMember(memberData.id);
    Alert.info(
      t('setting.removeModal.successMessage', { userName: memberData.name }),
    );
    setIsOpenDeleteMemberModal(false);
  };

  const projectPermissionList: GetProjectPermission[] = get(
    projectPermissionListData,
    'data',
    [],
  );

  const handleCancelPlan = useCallback(() => {
    UIState.openModal(ModalTypesEnum.UNSUBSCRIBE_PAYMENT);
  }, [UIState]);

  const handleUpgradePlan = useCallback(() => {
    navigateToUpgrade(project?.socialType, projectID);
  }, [navigateToUpgrade, project?.socialType, projectID]);

  const handleInviteClick = useCallback(
    (permission: AccountPermission) => {
      setIsOpenInviteModal(true);
      setInviteLinkPermission(permission);
      postInviteLink(permission, {
        onSuccess: (data) => {
          if (data.code === 20000) {
            setInviteLink(data.data.url);
          }
        },
      });
    },
    [postInviteLink],
  );

  const isViewer = useMemo(
    () => account?.permission === AccountPermission.VIEWER,
    [account?.permission],
  );

  return (
    <>
      <PageContainerStyled>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <PaperStyled>
              <Box sx={{ mb: '28px' }}>
                <Typography variant="h5">{t('setting.linkAccount')}</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ bgcolor: blue[500], marginRight: '16px' }}>
                    <Icon />
                  </Avatar>
                  {isConnect && (
                    <ItemInfoContainerStyled>
                      <Box className="name">
                        <Typography
                          {...(connectedPageLink && {
                            sx: {
                              cursor: 'pointer',
                            },
                            onClick: () =>
                              window.open(connectedPageLink, '_blank'),
                            variant: 'overline',
                          })}
                        >
                          {connectedPageName}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">
                          {t('setting.associate')}
                          &nbsp;
                        </Typography>
                        <Typography variant="overline">
                          {accountName}
                        </Typography>
                      </Box>
                    </ItemInfoContainerStyled>
                  )}
                  {!isConnect && (
                    <Box sx={{ ml: '10px' }}>{socialTypeName}</Box>
                  )}
                </Box>
                <Box>
                  {!isConnect && (
                    <Button
                      variant="text"
                      onClick={openModal}
                      disabled={isViewer}
                    >
                      {t('setting.connect')}
                    </Button>
                  )}
                  {isConnect && (
                    <>
                      <Button
                        variant="text"
                        color="error"
                        onClick={disconnectPages}
                        disabled={isViewer}
                      >
                        {t('setting.disconnect')}
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={refreshPage}
                        disabled={isViewer}
                      >
                        {t('setting.refresh')}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </PaperStyled>
          </Grid>

          <Grid item md={6}>
            <RightContainerStyled>
              <PaperStyled>
                <Typography variant="h5" color="grey.800">
                  {t('setting.payment.billing')}
                </Typography>
                <BillingInformation
                  planTitle={planTitle}
                  billing={billing}
                  price={price}
                  period={period}
                  project={project}
                  handleUpgradePlan={handleUpgradePlan}
                  isViewer={isViewer}
                  handleCancelPlan={handleCancelPlan}
                />
              </PaperStyled>
              <PaperStyled className="members">
                <TitleContainerStyled>
                  <Typography variant="h5">
                    {t('setting.teamMembers')}
                  </Typography>
                  <ButtonMenu
                    title={t('setting.inviteButton.invite')}
                    endIcon={<ArrowDropDownOutlinedIcon />}
                    disabled={isViewer || isDisabledInviteButton}
                  >
                    <MenuItem
                      onClick={() => handleInviteClick(AccountPermission.ADMIN)}
                      content={t('setting.inviteButton.admin')}
                    />
                    <MenuItem
                      onClick={() =>
                        handleInviteClick(AccountPermission.VIEWER)
                      }
                      content={t('setting.inviteButton.viewer')}
                    />
                  </ButtonMenu>
                </TitleContainerStyled>
                <TeamMemberInviteDescription />
                <ItemsContainerStyled>
                  {projectPermissionList.map((item, index) => (
                    <ItemContainerStyled key={item.id}>
                      <Box className="left-side-items">
                        <HeadShotContainerStyled
                          sx={{
                            ...(item.headshot && {
                              backgroundImage: `url(${item.headshot})`,
                            }),
                          }}
                        />
                        <ItemInfoContainerStyled>
                          <Box className="name">
                            <Typography variant="body1" color="grey.900">
                              {item.name} &nbsp;
                            </Typography>
                            {index === 0 && (
                              <Typography variant="caption" color="grey.900">
                                {`(${t('setting.you')})`}
                              </Typography>
                            )}
                          </Box>
                          <Typography variant="caption" color="grey.700">
                            {item.permission === AccountPermission.OWNER
                              ? t('setting.role.owner')
                              : item.permission === AccountPermission.ADMIN
                              ? t('setting.role.admin')
                              : t('setting.role.viewer')}
                          </Typography>
                        </ItemInfoContainerStyled>
                      </Box>
                      {index !== 0 && (
                        <Button
                          onClick={() => {
                            setMemberData({
                              id: item.id,
                              name: item.name,
                              permission: item.permission,
                            });
                            setIsOpenDeleteMemberModal(true);
                          }}
                          disabled={isViewer}
                        >
                          {t('common.remove')}
                        </Button>
                      )}
                    </ItemContainerStyled>
                  ))}
                </ItemsContainerStyled>
              </PaperStyled>
            </RightContainerStyled>
          </Grid>
        </Grid>
        <FacebookPagesModal
          open={facebookModalOpen}
          title={t('pages.title')}
          description={t('pages.info')}
          refresh={refreshPage}
          handleClose={closeModal}
          pages={pages}
          connect={connectPages}
          isConnectLoading={isConnecting}
          errorMessage={connectionErrorMessage}
          errorPageID={errorPageID}
        />
        <FacebookErrorModal
          open={facebookErrorModal}
          handleClose={closeFacebookErrorModal}
        />
      </PageContainerStyled>
      <Dialog
        id="invite-modal"
        size="s"
        title={t('setting.inviteModal.title', {
          role:
            inviteLinkPermission === AccountPermission.ADMIN
              ? t('setting.role.adminLowercase')
              : t('setting.role.viewerLowercase'),
        })}
        open={isOpenInviteModal}
        cancelBtnText={t('common.close')}
        handleClose={() => setIsOpenInviteModal(false)}
        closeBtn={false}
      >
        <Typography variant="body1">
          {t('setting.inviteModal.description')}
        </Typography>
        <InviteInfoContainerStyled>
          <TextField className="input-field" size="small" value={inviteLink} />
          <Button
            variant="contained"
            sx={{ textTransform: 'capitalize' }}
            onClick={() => {
              copyToClipboard(
                inviteLink,
                document.getElementById('invite-modal') as HTMLElement,
              );
              Alert.info(t('setting.infoLinkCopied'));
            }}
          >
            {t('common.copy')}
          </Button>
        </InviteInfoContainerStyled>
      </Dialog>
      <Dialog
        size="xs"
        title={t('setting.removeModal.title', {
          role:
            memberData.permission === AccountPermission.ADMIN
              ? t('setting.role.admin')
              : t('setting.role.viewer'),
          userName: memberData.name,
          projectName: project?.projectName as string,
        })}
        open={isOpenDeleteMemberModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.remove')}
        handleClose={() => setIsOpenDeleteMemberModal(false)}
        handleConfirm={handleConfirmDeleteMember}
        closeBtn={false}
        color="error"
        isNegative
      >
        <Typography variant="body1">
          {t('setting.removeModal.description', {
            role:
              memberData.permission === AccountPermission.ADMIN
                ? t('setting.role.admin')
                : t('setting.role.viewer'),
          })}
        </Typography>
      </Dialog>
    </>
  );
}

export default SorghumFeaturesSetting;

import React, { PropsWithChildren, createContext } from 'react';

interface ThreadsContext {
  linkThreads: () => void;
}

interface ThreadsProviderProps {
  appID: string;
}

export const ThreadsCtx = createContext<ThreadsContext>({
  linkThreads: () => null,
});

export const ThreadsProvider: React.FC<
  PropsWithChildren<ThreadsProviderProps>
> = ({ appID, children }) => {
  const scope = [
    'threads_basic',
    'threads_manage_replies',
    'threads_read_replies',
    'threads_content_publish'
  ];

  const domain = window.location.origin;

  const redirectURI = `${domain}/redirect-threads`;

  const link = `https://www.threads.net/oauth/authorize?client_id=${appID}&redirect_uri=${redirectURI}&scope=${scope.join(
    ',',
  )}&response_type=code`;

  const linkThreads = () => {
    window.open(link, '_blank', 'width=500,height=800');
  };

  return (
    <ThreadsCtx.Provider
      value={{
        linkThreads,
      }}
    >
      {children}
    </ThreadsCtx.Provider>
  );
};

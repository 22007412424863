import { Button, Dialog, NoticeBox } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { useGetProject } from '@frontend/sorghum/data-access';
import {
  PublishStatus,
  UsePageDefaultAnswerReturn,
} from '@frontend/sorghum/interface';
import {
  BasicSetting,
  DefaultAnswerFacebook,
  DefaultAnswerPreview,
  PageContainer,
} from '@frontend/sorghum/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

export interface SorghumFeaturesDefaultAnswerProps {
  usePage: () => UsePageDefaultAnswerReturn;
}

const DefaultAnswerContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
}));

export function SorghumFeaturesDefaultAnswer({
  usePage,
}: SorghumFeaturesDefaultAnswerProps) {
  const [t] = useTranslation();
  const {
    publishStatus,
    defaultAnswer,
    breadcrumbItems,
    breadcrumbNow,
    tabValue,
    isSaving,
    onlineFlow,
    flowName,
    isLoaded,
    isAlertModalOpen,
    setTabValue,
    setDefaultAnswerStatus,
    onPublish,
    setDefaultAnswer,
    onUpdateFlowID,
    closeModal,
    goToFlow,
  } = usePage();

  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { isViewer } = useProjectPermission();

  const InstagramContent = () => {
    return <Box></Box>;
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <PageContainer
        history={breadcrumbItems}
        now={breadcrumbNow}
        socialType={project?.socialType}
        customButton={
          <Button
            variant="contained"
            size="small"
            disabled={
              (publishStatus === PublishStatus.PUBLISHED && !isSaving) ||
              isViewer
            }
            color={
              publishStatus === PublishStatus.UNPUBLISH
                ? 'primary'
                : 'secondary'
            }
            onClick={onPublish}
          >
            {t(
              `common.topBar.${
                publishStatus === PublishStatus.UNPUBLISH ? 'publish' : 'update'
              }`,
            )}
          </Button>
        }
        isShowNoticeBox={
          publishStatus === PublishStatus.UNPUBLISHED_CHANGES && !isViewer
        }
        noticeBoxChildren={
          <NoticeBox
            message={t('basicSetting.alert.unpublishChange')}
            linkText={t('common.update')}
            onLinkClick={onPublish}
          />
        }
      >
        <DefaultAnswerContainerStyled>
          <BasicSetting
            title={t('basicSetting.defaultAnswer.title')}
            tabValue={tabValue}
            menuStatus={defaultAnswer.enable}
            setMenuStatus={setDefaultAnswerStatus}
            setTabValue={setTabValue}
            facebookContent={
              <DefaultAnswerFacebook
                onlineFlow={onlineFlow}
                data={defaultAnswer}
                setData={setDefaultAnswer}
                onUpdateFlowID={onUpdateFlowID}
              />
            }
            previewPosition="top"
            instagramContent={<InstagramContent />}
            mobilePreviewContent={<DefaultAnswerPreview data={defaultAnswer} />}
          />
        </DefaultAnswerContainerStyled>
      </PageContainer>
      {/* default answer 設定沒有發布的 flow 時的提示 modal */}
      <Dialog
        size="xs"
        title={t('basicSetting.defaultAnswer.modal.unpublish.title', {
          status:
            publishStatus === PublishStatus.UNPUBLISH ? 'publish' : 'update',
        })}
        content={t('basicSetting.defaultAnswer.modal.unpublish.content', {
          name: flowName,
        })}
        open={isAlertModalOpen}
        handleClose={closeModal}
        cancelBtnText={t('common.cancel')}
        confirmBtnText={t('common.ok')}
        handleConfirm={goToFlow}
      />
    </>
  );
}

export default SorghumFeaturesDefaultAnswer;

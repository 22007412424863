import { queryClient } from '@frontend/sorghum/external-providers';
import { PatchCommentAutoReplyPost } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { COMMENT_REPLY_POSTS } from '../apis';

interface Props {
  id: string;
  isAutoSave: boolean;
  replyType: number;
  posts?: PatchCommentAutoReplyPost[];
}

async function patchCommentReplyPosts(props: Props) {
  return axios
    .patch(`${COMMENT_REPLY_POSTS}`, props)
    .then((res) => res.data)
    .catch((err) => {
      return {
        code: 400,
        msg: err,
      };
    });
}

export function usePatchCommentReplyPosts() {
  return useMutation({
    mutationKey: ['patch-comment-reply-post'],
    mutationFn: (props: Props) => patchCommentReplyPosts(props),
    onSuccess: (_, props: Props) => {
      queryClient.refetchQueries(['get-comment-reply', { uuid: props.id }]);
    },
  });
}

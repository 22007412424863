import { floor, isNumber, isUndefined } from 'lodash';

export const convertNumberToK = (count: number | undefined) => {
  if (isNumber(count)) {
    if (count > 9999) {
      return `${floor(count / 1000).toLocaleString()}K`;
    } else {
      return count;
    }
  }

  return '-';
};

export const convertNumberToPercentage = (
  count: number | string | undefined,
) => {
  if (typeof count === 'string') {
    return count;
  }

  if (isNumber(count)) {
    return `${count.toFixed(1)}%`;
  }

  return '';
};

export const formatNumberWithCommas = (inputNumber: number) => {
  if (isUndefined(inputNumber)) return '';

  // Convert the number to a string
  const numberString = inputNumber.toString();

  // Check if the length of the string is greater than 3
  if (numberString.length > 3) {
    // Use regular expressions to add commas as thousands separators
    return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return numberString;
  }
};

import { globalTheme } from '@frontend/components/external-providers';
import SortIcon from '@mui/icons-material/Sort';
import { Checkbox } from '@mui/material';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FC, UIEvent, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../loading/loading';
import { Cell } from './cell';
import RowDragPreview from './dragPreview';
import { Header } from './header';
import Row from './row';
import './table.css';

export { sortByDate } from './sort';

export type TableSortingState = SortingState;

export interface TableProps {
  columnTitles: TableColumnProps[];
  data: any[];
  dragInfo?: string;
  isLoading?: boolean;
  emptyWrapper?: JSX.Element;
  checkbox?: boolean;
  onScrollToBottom?: () => void;
  onSorting?: (sortingState: SortingState) => void;
}

export type ColumnType = 'STATUS' | 'DATE' | 'CHECKBOX' | 'OTHERS';

export interface TableColumnProps {
  key: string;
  title?: string;
  type: ColumnType;
  sortable?: boolean;
  sortingFn?: (a: string, b: string) => number;
}

const TableSortIcon: FC<{ desc?: boolean }> = ({ desc }) => (
  <SortIcon
    fontSize="small"
    sx={{ ...(desc && { transform: 'scaleY(-1)' }), marginLeft: '4px' }}
  />
);

export interface BoxDragPreviewProps {
  title: string;
}

export const Table: FC<TableProps> = ({
  columnTitles,
  data,
  dragInfo = '',
  onScrollToBottom,
  onSorting,
  isLoading,
  emptyWrapper,
  checkbox = false,
}: TableProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});

  const columnHelper = createColumnHelper<any>();

  const tableData = columnTitles.map(
    ({ key, title, type, sortable, sortingFn }: TableColumnProps) => {
      return columnHelper.accessor(key, {
        ...(sortingFn &&
          !onSorting && {
            sortingFn: (rowA: any, rowB: any, columnId: any): number => {
              return sortingFn(
                rowA.getValue(columnId),
                rowB.getValue(columnId),
              );
            },
          }),
        ...(onSorting && {
          sortingFn: (rowA: any, rowB: any, columnId: any): number => 0,
        }),
        enableSorting: sortable,
        cell: (info) => <Cell type={type} data={info.renderValue()} />,
        header: () => (
          <Header id={`table-header-${key}`} title={title} type={type} />
        ),
      });
    },
  );

  const table = useReactTable({
    data,
    columns: tableData,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      sorting,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    if (onSorting) {
      if (sorting) {
        onSorting(sorting);
      }
    }
  }, [onSorting, sorting]);

  // const rerender = useReducer(() => ({}), {})[1];

  const selectedRows = useMemo(
    () => table.getSelectedRowModel().flatRows.map((row) => row.original),
    [table],
  );

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.clientHeight + e.currentTarget.scrollTop >=
      e.currentTarget.scrollHeight;
    if (bottom && e.currentTarget.scrollHeight > 60 && onScrollToBottom) {
      onScrollToBottom();
    }
  };

  return (
    <div
      style={{
        height: '100%',
      }}
      ref={ref}
      className="container"
      onScroll={handleScroll}
    >
      <RowDragPreview
        info={dragInfo}
        count={table.getSelectedRowModel().rows.length}
      />
      <table cellSpacing={0}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {checkbox && (
                <th className="checkbox">
                  <Checkbox
                    size="small"
                    {...{
                      checked: table.getIsAllPageRowsSelected(),
                      indeterminate: table.getIsSomePageRowsSelected(),
                      onChange: table.getToggleAllPageRowsSelectedHandler(),
                    }}
                  />
                </th>
              )}
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div
                    style={{
                      display: 'inline-flex',
                      ...(sorting.findIndex((x) => x.id === header.id) !==
                        -1 && {
                        color: globalTheme.palette?.['primary']?.main,
                      }),
                    }}
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: <TableSortIcon desc={true} />,
                      desc: <TableSortIcon />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Row
              id={`table-row-${row.original.id}`}
              key={row.id}
              selected={row.getIsSelected()}
              dropData={selectedRows}
            >
              {checkbox && (
                <td className="checkbox" key={`table_first_checkbox_${row.id}`}>
                  <Checkbox
                    size="small"
                    {...{
                      checked: row.getIsSelected(),
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                    }}
                  />
                </td>
              )}
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} id={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </Row>
          ))}
        </tbody>
      </table>
      {emptyWrapper && !isLoading && data.length <= 0 && emptyWrapper}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default Table;

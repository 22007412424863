import { TableTreeItem } from '@frontend/components/interface';
import {
  DefaultPostIcon,
  TableTree,
  Tooltip,
  Typography,
} from '@frontend/components/ui';
import {
  usePostDataOperationCommentReplyDetail,
  usePostDataOperationCommentReplyList,
} from '@frontend/sorghum/data-access';
import { COMMENT_AUTO_REPLY, CONTENT, usePath } from '@frontend/sorghum/utils';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { Box, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import { CommentAutoReplyCellWithData } from '../comment-auto-reply-list-detail/comment-auto-reply-list-detail';
import { TableContainerWithPagination } from '../table-container/table-container';

interface Props {
  startDate: string;
  endDate: string;
}

const ContainerStyled = styled(Box)(({ theme }) => ({
  padding: '24px',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '20px',
  background: theme.palette['grey']['white'],
  boxShadow: theme.shadows[2],
}));

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 20px',
  boxSizing: 'border-box',
  height: '48px',
  '.cell-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const HeaderTableCellStyled = styled(TableCell)(({ theme }) => ({
  paddingLeft: '20px',
  boxSizing: 'border-box',

  '&.first-cell': {
    paddingLeft: '48px',
  },
}));

export const TableCellInnerContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.vertical-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
}));

export const TextOverflowStyled = styled(Box)(({ theme }) => ({
  '& > p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette['grey'][100],
}));

const ROW_PER_PAGE = 15;

export const AutoReply: FC<Props> = ({ startDate, endDate }: Props) => {
  const { navigateToProjectPage } = usePath();
  const [t] = useTranslation();
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useImmer<TableTreeItem[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const { data: list, isFetching: isDataLoading } =
    usePostDataOperationCommentReplyList({
      startDate,
      endDate,
      offset: page * ROW_PER_PAGE,
      limit: 5,
    });

  const { mutate: getDetail } = usePostDataOperationCommentReplyDetail();

  // 下一層的數據
  const openFirstLayer = useCallback(
    (id: string) => {
      if (tableData.find((i) => i.id === id)?.items !== undefined) return;

      getDetail(
        {
          commentReplyId: id,
          startDate,
          endDate,
        },
        {
          onSuccess: (data) => {
            if (data.conditions) {
              const list = data.conditions.map((item) => {
                return {
                  id: item.id,
                  layer: 2,
                  rowItem: [
                    {
                      class: 'grey',
                      isShowIcon: true,
                      element: (
                        <Typography
                          variant="body2"
                          color="grey.700"
                          sx={{
                            marginLeft: '16px',
                            width: '200px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.name}
                        </Typography>
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          commentID={item.id}
                          num={item.comments}
                          uniqNum={item.uniqComments}
                          numType="comments"
                          name={item.name}
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          commentID={item.id}
                          num={item.publicReply}
                          uniqNum={item.uniqPublicReply}
                          numType="publicReply"
                          name={item.name}
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          commentID={item.id}
                          num={item.privateReply}
                          uniqNum={item.uniqPrivateReply}
                          numType="privateReply"
                          name={item.name}
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          commentID={item.id}
                          num={item.conversations}
                          uniqNum={item.uniqConversations}
                          numType="conversations"
                          name={item.name}
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                  ],
                  items: item.posts
                    ? item.posts.map((_postItem) => {
                        return {
                          id: _postItem.id,
                          layer: 3,
                          rowItem: [
                            {
                              class: 'grey',
                              element: (
                                <>
                                  {_postItem.fullPicture ? (
                                    <ImageContainerStyled
                                      sx={{
                                        backgroundImage: `url(${_postItem.fullPicture})`,
                                      }}
                                    />
                                  ) : (
                                    <ImageContainerStyled>
                                      <DefaultPostIcon />
                                    </ImageContainerStyled>
                                  )}
                                  <Box className="cell-container">
                                    {_postItem.message}
                                  </Box>
                                </>
                              ),
                            },
                            {
                              class: 'grey',
                              element: (
                                <CommentAutoReplyCellWithData
                                  num={_postItem.comments}
                                  uniqNum={_postItem.uniqComments}
                                  isLoading={isDataLoading}
                                />
                              ),
                            },
                            {
                              class: 'grey',
                              element: (
                                <CommentAutoReplyCellWithData
                                  num={_postItem.publicReply}
                                  uniqNum={_postItem.uniqPublicReply}
                                  isLoading={isDataLoading}
                                />
                              ),
                            },
                            {
                              class: 'grey',
                              element: (
                                <CommentAutoReplyCellWithData
                                  num={_postItem.privateReply}
                                  uniqNum={_postItem.uniqPrivateReply}
                                  isLoading={isDataLoading}
                                />
                              ),
                            },
                            {
                              class: 'grey',
                              element: (
                                <CommentAutoReplyCellWithData
                                  num={_postItem.conversations}
                                  uniqNum={_postItem.uniqConversations}
                                  isLoading={isDataLoading}
                                />
                              ),
                            },
                          ],
                        };
                      })
                    : [],
                };
              });
              setTableData((prev) => {
                prev
                  .filter((i) => i.id === id)
                  .forEach((i) => {
                    i.items = list;
                  });
              });
            }
          },
        },
      );
    },
    [endDate, getDetail, isDataLoading, setTableData, startDate, tableData],
  );

  useEffect(() => {
    setIsLoaded(false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (!isLoaded && list?.commentReplies) {
      const tableList = list?.commentReplies.map((item) => {
        return {
          id: item.id,
          layer: 1,
          onClickToggleButton: () => {
            openFirstLayer(item.id);
          },
          rowItem: [
            {
              class: 'clickable',
              isShowIcon: true,
              element: (
                <Typography
                  variant="body2"
                  color="grey.700"
                  sx={{
                    marginLeft: '16px',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={() =>
                    navigateToProjectPage(
                      `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                    )
                  }
                >
                  {item.name}
                </Typography>
              ),
            },
            {
              class: 'clickable',
              onClick: () =>
                navigateToProjectPage(
                  `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                ),
              element: (
                <CommentAutoReplyCellWithData
                  commentID={item.id}
                  num={item.comments}
                  uniqNum={item.uniqComments}
                  numType="comments"
                  name={item.name}
                  isLoading={isDataLoading}
                />
              ),
            },
            {
              onClick: () =>
                navigateToProjectPage(
                  `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                ),
              element: (
                <CommentAutoReplyCellWithData
                  commentID={item.id}
                  num={item.publicReply}
                  uniqNum={item.uniqPublicReply}
                  numType="publicReply"
                  name={item.name}
                  isLoading={isDataLoading}
                />
              ),
            },
            {
              onClick: () =>
                navigateToProjectPage(
                  `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                ),
              element: (
                <CommentAutoReplyCellWithData
                  commentID={item.id}
                  num={item.privateReply}
                  uniqNum={item.uniqPrivateReply}
                  numType="privateReply"
                  name={item.name}
                  isLoading={isDataLoading}
                />
              ),
            },
            {
              onClick: () =>
                navigateToProjectPage(
                  `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                ),
              element: (
                <CommentAutoReplyCellWithData
                  commentID={item.id}
                  num={item.conversations}
                  uniqNum={item.uniqConversations}
                  numType="conversations"
                  name={item.name}
                  isLoading={isDataLoading}
                />
              ),
            },
          ],
          items: [],
        };
      });

      setTableData(tableList);
      setIsLoaded(true);
    }
  }, [
    isDataLoading,
    isLoaded,
    list?.commentReplies,
    navigateToProjectPage,
    openFirstLayer,
    setTableData,
  ]);

  return (
    <ContainerStyled>
      <Typography component="div" variant="subtitle2" color="grey.800">
        {t('dataDashboard.operationAnalysis.autoReply.analysis.title')}
      </Typography>
      <TableContainerWithPagination
        pagination={{
          offset: list?.offset,
          totalCount: list?.totalCount,
          rowsPerPage: ROW_PER_PAGE,

          setPage: setPage,
        }}
        searchText={
          list?.commentReplies && list?.commentReplies?.length > 0
            ? undefined
            : t('commentAutoReply.list.table.noSearchText')
        }
        showPagination={list?.commentReplies && list.commentReplies?.length > 0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <HeaderTableCellStyled width={120}>
                {t('commentAutoReply.list.table.header.name')}
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.comments')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.comments',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.publicReply')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.publicReply',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.privateReply')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                  <Tooltip
                    placement="top"
                    title={t(
                      'dataDashboard.operationAnalysis.autoReply.analysis.tooltip.privateReply',
                    )}
                  >
                    <InfoIcon
                      sx={{
                        color: 'bluegrey.600',
                        width: '16px',
                        height: '16px',
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
              <HeaderTableCellStyled width={50}>
                <TableCellInnerContainerStyled>
                  <Box className="vertical-container">
                    {t('commentAutoReply.list.table.header.conversions')}
                    <Typography variant="caption" color="bluegrey.500">
                      {t('commentAutoReply.list.table.header.usersPerTime')}
                    </Typography>
                  </Box>
                </TableCellInnerContainerStyled>
              </HeaderTableCellStyled>
            </TableRow>
            <TableTree tableList={tableData} />
          </TableHead>
        </Table>
      </TableContainerWithPagination>
    </ContainerStyled>
  );
};

export default AutoReply;

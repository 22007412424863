import {
  DndProvider,
  ThemeProvider,
  ToastifyProvider,
} from '@frontend/components/external-providers';
import { EditorProvider } from '@frontend/editor/external-providers';
import {
  GoogleAuthProvider,
  NotificationProvider,
  QueryClientProvider,
  ThreadsProvider,
  UIProvider,
  queryClient,
} from '@frontend/sorghum/external-providers';
import { SorghumFeaturesGoogleAuth } from '@frontend/sorghum/features/google-auth';
import {
  AUTH_CALLBACK,
  FACEBOOK,
  GOOGLE,
  GOOGLE_AUTH,
  LOGIN,
  MAIN,
  PAGE_DESKTOP_ONLY,
  PAGE_GOOGLE_CALLBACK,
  PAGE_NOT_FOUND,
  PAGE_PERMISSION_DENIED,
  PAGE_REDIRECT,
  PAGE_REDIRECT_THREADS,
  PAGE_TAP_PAY_CALLBACK,
  PRIVACY_POLICY,
  ROOT,
  UPGRADE,
  withPageBoundary,
} from '@frontend/sorghum/utils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import originalDayjs from 'dayjs';
import { lazy, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { environment } from '../environments/environment';
import './axios';
import { authorizations } from './facebook-authorization';
import { HomeRoute, MessengerRoute, ThreadsRoute } from './routes';

const Facebook = lazy(() =>
  import('@frontend/sorghum/features/facebook').then(
    ({ SorghumFeaturesFacebook: Page }) => ({
      default: Page,
    }),
  ),
);

const GoogleAuth = lazy(() =>
  import('@frontend/sorghum/features/google-auth').then(
    ({ SorghumFeaturesGoogleAuth: Page }) => ({
      default: Page,
    }),
  ),
);

const PageNotFound = lazy(() =>
  import('@frontend/sorghum/features/page-not-found').then(
    ({ SorghumFeaturesPageNotFound: Page }) => ({
      default: Page,
    }),
  ),
);

const PageShareLinkRedirect = lazy(() =>
  import('@frontend/sorghum/features/redirect').then(
    ({ SorghumFeaturesRedirect: Page }) => ({
      default: Page,
    }),
  ),
);

const ProjectPermissionDenied = lazy(() =>
  import('@frontend/sorghum/features/project-permission-denied').then(
    ({ SorghumFeaturesProjectPermissionDenied: Page }) => ({
      default: Page,
    }),
  ),
);

const PrivatePolicy = lazy(() =>
  import('@frontend/sorghum/features/privacy-policy').then(
    ({ SorghumFeaturesPrivatePolicy: Page }) => ({
      default: Page,
    }),
  ),
);

const DesktopOnly = lazy(() =>
  import('@frontend/sorghum/features/desktop-only').then(
    ({ SorghumFeaturesDesktopOnly: Page }) => ({
      default: Page,
    }),
  ),
);

const SorghumFeaturesTapPayCallbackCreate = lazy(() =>
  import('@frontend/sorghum/features/tap-pay-callback').then(
    ({ SorghumFeaturesTapPayCallback: Page }) => ({
      default: Page,
    }),
  ),
);

const Upgrade = lazy(() =>
  import('@frontend/sorghum/features/setting-upgrade').then(
    ({ SorghumFeaturesSettingUpgrade: Page }) => ({ default: Page }),
  ),
);

const Redirect = lazy(() =>
  import('@frontend/sorghum/features/threads-redirect').then(
    ({ SorghumFeaturesThreadsRedirect: Page }) => ({
      default: Page,
    }),
  ),
);

export function App() {
  const { i18n } = useTranslation();
  const {
    mode,
    facebookAppID,
    meadEndpoint,
    googleClientID,
    gtmCode,
    googleStorageEndpoint,
    tapPayAppID,
    threadsAppID,
  } = environment;
  const authorizationString = authorizations.join(',');
  const token = localStorage.getItem('token');
  const { pathname } = useLocation();

  const tagManagerArgs = {
    gtmId: gtmCode,
  };

  TagManager.initialize(tagManagerArgs);

  // token 改變時重置 react-query
  useEffect(() => {
    queryClient.invalidateQueries();
  }, [token, pathname]);

  // set default language to dayjs(will be used in flow table last modified)
  useEffect(() => {
    originalDayjs.locale(i18n.language.replace(/_/g, '-'));
  }, [i18n.language]);

  return (
    <QueryClientProvider>
      <ThreadsProvider appID={threadsAppID}>
        <GoogleAuthProvider
          clientID={googleClientID}
          redirectTo={PAGE_GOOGLE_CALLBACK}
        >
          <NotificationProvider>
            <ThemeProvider>
              <DndProvider>
                <UIProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <EditorProvider>
                      <ToastifyProvider />
                      <Routes>
                        <Route path={ROOT}>
                          <Route
                            path={PRIVACY_POLICY}
                            element={<PrivatePolicy />}
                          />
                          <Route
                            path={GOOGLE_AUTH}
                            element={<SorghumFeaturesGoogleAuth />}
                          />
                          <Route
                            index
                            element={<Navigate to={`/${LOGIN}`} />}
                          />
                          {HomeRoute()}
                          {MessengerRoute()}
                          {ThreadsRoute()}
                          {/* upgrade */}
                          <Route
                            path={`${MAIN}/:projectID/${UPGRADE}`}
                            element={<Upgrade tapPayAppID={tapPayAppID} />}
                          />
                          <Route
                            path={`${UPGRADE}`}
                            element={<Upgrade tapPayAppID={tapPayAppID} />}
                          />
                          <Route path={FACEBOOK}>
                            <Route
                              path={AUTH_CALLBACK}
                              element={withPageBoundary(
                                <Facebook
                                  mode={mode}
                                  facebookAppID={facebookAppID}
                                  permission={authorizationString}
                                />,
                              )}
                            />
                          </Route>
                          <Route path={GOOGLE}>
                            <Route
                              path={AUTH_CALLBACK}
                              element={withPageBoundary(<GoogleAuth />)}
                            />
                          </Route>
                          <Route
                            path={PAGE_REDIRECT}
                            element={withPageBoundary(
                              <PageShareLinkRedirect
                                meadEndpoint={meadEndpoint}
                              />,
                            )}
                          />
                          <Route
                            path={PAGE_REDIRECT_THREADS}
                            element={<Redirect appID={threadsAppID} />}
                          />
                          <Route
                            path={PAGE_PERMISSION_DENIED}
                            element={<ProjectPermissionDenied />}
                          />
                        </Route>
                        <Route
                          path={PAGE_DESKTOP_ONLY}
                          element={<DesktopOnly />}
                        />
                        <Route
                          path={PAGE_TAP_PAY_CALLBACK}
                          element={
                            <SorghumFeaturesTapPayCallbackCreate
                              googleStorageEndpoint={googleStorageEndpoint}
                            />
                          }
                        />
                        <Route
                          path={PAGE_NOT_FOUND}
                          element={<PageNotFound />}
                        />
                        <Route path="*" element={<PageNotFound />} />
                      </Routes>
                    </EditorProvider>
                  </LocalizationProvider>
                </UIProvider>
              </DndProvider>
            </ThemeProvider>
          </NotificationProvider>
        </GoogleAuthProvider>
      </ThreadsProvider>
    </QueryClientProvider>
  );
}

export default App;

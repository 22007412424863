import { Chip } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetProject } from '@frontend/sorghum/data-access';
import { ProjectStatus } from '@frontend/sorghum/interface';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ThreadsProjectTag: FC = () => {
  const [t] = useTranslation('threads');
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);

  switch (project?.status) {
    case ProjectStatus.TRIAL:
      return <Chip label={t('badge.trial')} size="small" />;
    case ProjectStatus.ADVANCE:
      return <Chip label={t('badge.advanced')} size="small" />;
    case ProjectStatus.VIEW_ONLY:
    default:
      return <Chip color="error" label={t('badge.limit')} size="small" />;
  }
};

export default ThreadsProjectTag;

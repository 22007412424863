import { Typography } from '@frontend/components/ui';
import { Alert, internalLinkToast } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import { formatNumberWithCommas } from '@frontend/editor/utils';
import {
  useGetBilling,
  useGetFacebookPages,
  useGetProject,
  useProjectConnect,
  useProjectDisconnect,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  GetBillingPlanEnum,
  PaymentStatus,
  ProjectDisconnectProps,
} from '@frontend/sorghum/interface';
import { FacebookIcon } from '@frontend/sorghum/ui';
import {
  FACEBOOK_FAN_PAGE_READ_MORE_EN,
  FACEBOOK_FAN_PAGE_READ_MORE_ZH,
  sendGAEvent,
  useDocumentTitle,
  useFacebook,
  usePath,
  VIEWER_PERMISSION_EN,
  VIEWER_PERMISSION_ZH,
} from '@frontend/sorghum/utils';
import { Link } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useMessengerSetting = (
  facebookAppID: string,
  permission: string,
  mode: string,
) => {
  const [t, i18next] = useTranslation();
  const uiState = useContext(UICtx);
  const [facebookModalOpen, setFacebookModalOpen] = useState<boolean>(false);
  const [facebookErrorModal, setFacebookErrorModal] = useState<boolean>(false);
  const [connectionErrorMessage, setConnectionErrorMessage] = useState<
    string | undefined
  >();
  const [errorPageID, setErrorPageID] = useState<string | undefined>();
  const { data: projectID, isFetching: isFetchingProject } = useGetProjectID();
  const { mutate: disconnect } = useProjectDisconnect();
  const { navigateToProjectPage } = usePath();

  const {
    LoginStatus,
    token: fbToken,
    requestPermission,
    login,
  } = useFacebook({
    appId: facebookAppID,
    mode,
  });

  const { data: linked } = useProjectLinked(projectID as string);
  const { mutate: connect, isLoading: isConnecting } = useProjectConnect();
  const { data: project } = useGetProject(projectID);
  const { data: billing } = useGetBilling();

  const { data: pages, refetch } = useGetFacebookPages(fbToken);

  const openModal = useCallback(() => {
    login(permission, (response) => {
      if (response.status === LoginStatus.CONNECTED) {
        setFacebookModalOpen(true);
      }
    });
  }, [LoginStatus.CONNECTED, login, permission]);

  const closeFacebookErrorModal = () => {
    requestPermission();
    setFacebookErrorModal(false);
  };

  const refreshPage = useCallback(() => {
    requestPermission();
    refetch();
  }, [refetch, requestPermission]);

  const disconnectPages = useCallback(() => {
    if (linked?.fb?.id) {
      const props: ProjectDisconnectProps = {
        projectId: projectID as string,
        target: 'fb',
        targetId: linked?.fb?.id.toString(),
      };

      const disconnectFn = () =>
        disconnect(props, {
          onSuccess: (res) => {
            if (res.code === 20000) {
              Alert.success(t('setting.disconnectedMessage'));
            }
          },
        });

      uiState.openCommonModal(
        t('setting.disconnectModal.title'),
        t('setting.disconnectModal.description'),
        disconnectFn,
        t('setting.disconnect'),
        'error',
      );
    }
  }, [disconnect, linked?.fb?.id, projectID, t, uiState]);

  const connectPages = useCallback(
    (id: string) =>
      connect(
        { target: 'fb', targetId: id, projectId: projectID },
        {
          onSuccess: (data, variables) => {
            if (data.code === 20000) {
              sendGAEvent(
                'Setting',
                'Toast Hyperlink',
                'Setting - toast - Back to the last flow link - click',
                '',
              );
              setFacebookModalOpen(false);
              internalLinkToast(
                t('setting.successMessage'),
                t('setting.start'),
                'success',
                () => navigateToProjectPage(),
                'toast_flow',
              );
            } else {
              setErrorPageID(variables?.targetId);
              setConnectionErrorMessage(data?.msg);
            }
          },
        },
      ),
    [connect, navigateToProjectPage, projectID, t],
  );

  const { readMoreUrl, viewerPermissionUrl } = useMemo(() => {
    if (i18next.language === 'en_us') {
      return {
        readMoreUrl: FACEBOOK_FAN_PAGE_READ_MORE_EN,
        viewerPermissionUrl: VIEWER_PERMISSION_EN,
      };
    } else {
      return {
        readMoreUrl: FACEBOOK_FAN_PAGE_READ_MORE_ZH,
        viewerPermissionUrl: VIEWER_PERMISSION_ZH,
      };
    }
  }, [i18next.language]);

  const connectedPageLink = `https://www.facebook.com/profile.php?id=${linked?.fb?.id}`;

  const connectedPageName = linked?.fb?.name;
  const accountName = linked?.fb?.accountName;

  const Icon = () => <FacebookIcon size="xl" />;

  const TeamMemberInviteDescription = () => (
    <Typography variant="body1" color="grey.600">
      <Trans i18nKey="setting.inviteDescription">
        You can invite teammates to manage or view your project, learn more
        about the permission access
        <Link
          sx={{ color: 'info.main' }}
          onClick={() => window.open(viewerPermissionUrl, '_blank')}
        >
          {t('setting.inviteLink')}
        </Link>
      </Trans>
    </Typography>
  );

  const isDisabledInviteButton = false;

  const socialTypeName = t('setting.facebook');

  const { planTitle, price, period } = useMemo(() => {
    let planTitle = '';
    let price = '-';
    let period = '';

    if (billing) {
      switch (billing.plan) {
        case GetBillingPlanEnum.TRIAL: {
          planTitle = t('setting.payment.trial');
          price = 'NT$ 0';
          period = `/${t('setting.payment.month')}`;
          break;
        }
        case GetBillingPlanEnum.ADVANCE: {
          planTitle = t('setting.payment.advance');
          price = `NT$ ${formatNumberWithCommas(billing.amount ?? 0)}`;
          period = `/${
            billing.period === 1
              ? t('setting.payment.month')
              : t('setting.payment.year')
          }`;
          break;
        }
        case GetBillingPlanEnum.BETA: {
          planTitle = t('setting.payment.betaVersion');
          price = t('setting.payment.free');
          period = `/${t('setting.payment.month')}`;
          break;
        }
        default: {
          planTitle = t('setting.payment.expired');
          price = '-';
          period = '';
        }
      }
      if (project?.paymentStatus === PaymentStatus.ABNORMAL) {
        planTitle = t('setting.payment.trial');
        price = 'NT$ 0';
        period = `/${t('setting.payment.month')}`;
      }
    }

    return {
      price,
      period,
      planTitle,
    };
  }, [billing, project?.paymentStatus, t]);

  useLayoutEffect(() => {
    if (isFetchingProject) {
      uiState.addLoadingStatus('fetch-project');
    } else {
      uiState.removeLoadingStatus('fetch-project');
    }
  }, [uiState, isFetchingProject]);

  useEffect(() => {
    if (pages?.error === 'TOKEN_EXPIRED' && facebookModalOpen) {
      login();
    }
  }, [facebookModalOpen, login, pages]);

  useDocumentTitle(t('title.setting'));

  return {
    pages: pages?.data,
    linked,
    facebookModalOpen,
    facebookErrorModal,
    connectionErrorMessage,
    errorPageID,
    isConnect: !!linked?.fb?.id,
    isConnecting,
    refreshPage,
    disconnectPages,
    connectPages,
    openModal,
    closeFacebookErrorModal,
    closeModal: () => setFacebookModalOpen(false),
    TeamMemberInviteDescription,
    isDisabledInviteButton,
    Icon,
    connectedPageLink,
    connectedPageName,
    accountName,
    socialTypeName,
    planTitle,
    price,
    period,
    billing,
  };
};

import { uuid } from '@frontend/components/utils';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteNestedGroup from '../autocomplete-nested-group/autocomplete-nested-group';
import Chip from '../chip/chip';

export interface TagOption {
  id: string;
  name: string;
  newElement?: true;
  group?: string | null;
  allowRemove?: boolean;
}

export interface TagSelectorProps {
  value: TagOption[];
  options: TagOption[];
  helperText?: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  allowNew?: boolean;
  maxLength?: number;
  minHeight?: number;
  error?: boolean;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  setValue: (list: TagOption[]) => void;
  enableSearch?: boolean;
  size?: 'small' | 'medium';
}

const TextFieldStyled = styled(TextField)<{ $minHeight?: number }>(
  ({ theme, $minHeight }) => {
    const TextFieldSetStyle = {
      ...($minHeight && { minHeight: $minHeight }),
      borderColor: get(theme, 'palette.grey.300', ''),
    };
    return {
      width: '100%',

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          ...TextFieldSetStyle,
        },
        '&:hover fieldset': {
          ...TextFieldSetStyle,
        },
        '&.Mui-focused fieldset': {
          ...TextFieldSetStyle,
        },
        '& .MuiInputBase-input': {
          width: '100px',
        },
      },
    };
  },
);

export function TagSelector({
  options,
  value,
  placeholder,
  helperText,
  required,
  allowNew = true,
  maxLength,
  disabled,
  minHeight,
  error,
  onInputChange,
  onBlur,
  setValue,
  enableSearch = true, // 設為 false 時輸入文字不會出現下拉選單，目前用在 comment reply 內
  size = 'medium',
}: TagSelectorProps) {
  const [t] = useTranslation();
  const needGroup = options.find((i) => !!i.group);

  const [duplicateTagError, setDuplicateTagError] = useState(false);

  return (
    <Autocomplete
      open={!enableSearch ? false : undefined}
      options={options}
      value={[...value]}
      multiple
      size={size}
      disableClearable
      disabled={disabled}
      clearOnBlur
      forcePopupIcon={false}
      isOptionEqualToValue={(option: TagOption, value: TagOption) =>
        option.id === value.id
      }
      onChange={(_, newValue: TagOption[]) => {
        setValue(
          newValue.map((item: TagOption) => {
            return {
              ...item,
              name: item.name
                .replace(t('people.tagModal.addPrefix'), '')
                .replace(new RegExp('"', 'g'), ''),
            };
          }),
        );
      }}
      {...(needGroup && {
        groupBy: (option: TagOption) => option.group || '',
        renderGroup: (params) => <AutocompleteNestedGroup {...params} />,
      })}
      getOptionLabel={(option: TagOption) => (option as TagOption).name}
      filterOptions={(options: TagOption[], state) => {
        const filteredOptions = options.filter(
          (item: TagOption) =>
            item.name.includes(state.inputValue) &&
            !value.find((i) => i.id === item.id),
        );
        // 如果有輸入新的值才出現新增 tag 的選項
        if (
          allowNew &&
          state.inputValue.trim() !== '' &&
          !options.find((i) => i.name === state.inputValue) &&
          !value.find((i) => i.name === state.inputValue)
        ) {
          const newOptions = [
            {
              name: `${t('people.tagModal.addPrefix')}"${state.inputValue}"`,
              id: uuid(),
              newElement: true,
            } as TagOption,
            ...filteredOptions,
          ];
          return newOptions;
        }
        return filteredOptions;
      }}
      renderOption={(props, option: TagOption) => (
        <li key={`autocomplete_${option.name}}`} {...props}>
          <Typography variant="menuItem" color="grey.500">
            {option.name}
          </Typography>
        </li>
      )}
      renderInput={(params) => {
        return (
          <TextFieldStyled
            {...params}
            $minHeight={minHeight}
            variant="outlined"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onInputChange && onInputChange(e);
              params.inputProps.onChange && params.inputProps.onChange(e);
            }}
            size={size}
            error={(required && value.length === 0) || duplicateTagError}
            required={required}
            placeholder={placeholder}
            hiddenLabel
            helperText={
              duplicateTagError
                ? t('people.tagModal.errorDuplicateTag')
                : helperText
            }
            inputProps={{
              ...params.inputProps,
              disabled,
              maxLength,
              onBlur,
              onKeyDown: (e) => {
                if (!enableSearch) {
                  if (e.key === 'Enter') {
                    if (
                      value.find((item) => item.name === e.currentTarget.value)
                    ) {
                      setDuplicateTagError(true);
                    } else {
                      setValue([
                        ...value,
                        {
                          id: e.currentTarget.value,
                          name: e.currentTarget.value,
                        },
                      ]);
                      setDuplicateTagError(false);
                    }
                  }
                }
              },
            }}
          />
        );
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option: TagOption, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              disabled={
                options.find((i) => i.id === option.id)?.allowRemove ===
                  false || disabled
              }
              key={option.id}
              variant="outlined"
              color="primary"
              label={option.name}
              size={size}
            />
          );
        });
      }}
    />
  );
}

export default TagSelector;

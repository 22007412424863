import { Button } from '@frontend/components/ui';
import { UsePageMicroReturn } from '@frontend/sorghum/interface';
import { FormInput, Typography } from '@frontend/sorghum/ui';
import { InputLabel } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import {
  REGEX_CHECK_EMAIL,
  REGEX_CHECK_TAIWAN_PHONE_NUMBER,
} from '@frontend/editor/utils';
import { SorghumFeatureMicroFormValue } from '@frontend/sorghum/interface';
import { BREAK_POINT, useRwd } from '@frontend/sorghum/utils';
import { ErrorMessage } from '@hookform/error-message';
import {
  BottomButtonContainerStyled,
  DescriptionContainerStyled,
  FormContainerStyled,
  FormInnerContainerStyled,
  InputFieldContainerStyled,
  MicroContainerStyled,
  PhoneInputContainerStyled,
  PhoneInputWrapperStyled,
  TitleContainerStyled,
} from './styles';

export interface SorghumFeaturesMicroProps<T> {
  usePage: () => UsePageMicroReturn<T>;
}

// type ItemValueType = '1' | '2' | '3' | '4' | '5' | '6';

const PhoneInputComponent = ({
  formName,
  formError,
  label,
  control,
  isTaiwanPhoneNumber,
}: {
  formName: 'phone';
  formError?: FieldErrors;
  label?: string;
  setValue: UseFormSetValue<SorghumFeatureMicroFormValue>;
  control: Control<SorghumFeatureMicroFormValue>;
  isTaiwanPhoneNumber: boolean;
}) => {
  const [t] = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  return (
    <PhoneInputContainerStyled
      $isError={!isEmpty(formError?.['phone'])}
      className={isFocused ? 'focused' : ''}
    >
      <InputLabel className="phone-input-label" shrink required>
        {label}
      </InputLabel>
      <PhoneInputWrapperStyled $isError={!isEmpty(formError?.['phone'])}>
        <Controller
          control={control}
          name={formName}
          rules={{
            required: t('common.required'),
            validate: (val: string) => {
              if (!val) {
                return t('common.required');
              }

              if (isTaiwanPhoneNumber) {
                if (!REGEX_CHECK_TAIWAN_PHONE_NUMBER.test(val)) {
                  return t('micro.errorValidPhone');
                }
              }
              return true;
            },
          }}
          render={({ field: { ref, ...field } }) => {
            return (
              <PhoneInput
                country={'tw'}
                masks={{ tw: '..........' }}
                {...field}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
            );
          }}
        />
      </PhoneInputWrapperStyled>
      {isEmpty(formError) && (
        <Typography
          className="phone-input-helper-text"
          variant="h6"
          fontSize={12}
          fontWeight={400}
          letterSpacing="initial"
          color="grey.500"
        >
          {t('micro.phoneHelperText')}
        </Typography>
      )}
      <ErrorMessage
        errors={formError}
        name={formName}
        render={({ message }) => (
          <Typography variant="body2" color="error.main">
            {message}
          </Typography>
        )}
      />
    </PhoneInputContainerStyled>
  );
};

export function SorghumFeaturesMicro({
  usePage,
}: SorghumFeaturesMicroProps<SorghumFeatureMicroFormValue>) {
  const [t] = useTranslation();
  const [isComplete, setIsComplete] = useState<boolean>(false);
  // const [selectedIndustry, setSelectedIndustry] = useState<string>('');
  const { breakPoint } = useRwd();

  const { userName, pageName, submit, isSendingMicro, email } = usePage();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm<SorghumFeatureMicroFormValue>({
    mode: 'onSubmit',
    defaultValues: {
      company: pageName || userName,
      email,
    },
  });

  const data = watch();

  const isTaiwanPhoneNumber = useCallback((phone: string): boolean => {
    if (phone && phone.startsWith('886')) {
      return true;
    }
    return false;
  }, []);

  // const departmentList = useMemo(
  //   () => [
  //     { value: '1', label: t('micro.departmentOption.socialMediaMarketing') },
  //     { value: '2', label: t('micro.departmentOption.digitalAdvertising') },
  //     { value: '3', label: t('micro.departmentOption.contentMarketing') },
  //     { value: '4', label: t('micro.departmentOption.productMarketing') },
  //     { value: '5', label: t('micro.departmentOption.marketAnalysis') },
  //     { value: '6', label: t('micro.departmentOption.brandManagement') },
  //     { value: '7', label: t('micro.departmentOption.publicRelations') },
  //     { value: '8', label: t('micro.departmentOption.marketingCampaigns') },
  //     { value: '9', label: t('micro.departmentOption.notInMarketing') },
  //   ],
  //   [t],
  // );

  // const surveyOptionData = useMemo(() => {
  //   return {
  //     generic: {
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.generic.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.generic.2'),
  //         },
  //         {
  //           value: '3',
  //           label: t('micro.business.generic.3'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.generic.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.generic.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.generic.6'),
  //         },
  //       ],
  //     },
  //     retail: {
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.retail.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.retail.2'),
  //         },
  //         {
  //           value: '3',
  //           label: t('micro.business.retail.3'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.retail.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.retail.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.retail.6'),
  //         },
  //       ],
  //     },
  //     ec: {
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.ec.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.ec.2'),
  //         },
  //         {
  //           value: '3',
  //           label: t('micro.business.ec.3'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.ec.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.ec.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.ec.6'),
  //         },
  //       ],
  //     },
  //     newMedia: {
  //       // new media 選項三從缺
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.newMedia.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.newMedia.2'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.newMedia.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.newMedia.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.newMedia.6'),
  //         },
  //       ],
  //     },
  //     marketingAgency: {
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.marketingAgency.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.marketingAgency.2'),
  //         },
  //         {
  //           value: '3',
  //           label: t('micro.business.marketingAgency.3'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.marketingAgency.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.marketingAgency.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.marketingAgency.6'),
  //         },
  //       ],
  //     },
  //     foodAndBeverage: {
  //       optionList: [
  //         {
  //           value: '1',
  //           label: t('micro.business.foodAndBeverage.1'),
  //         },
  //         {
  //           value: '2',
  //           label: t('micro.business.foodAndBeverage.2'),
  //         },
  //         {
  //           value: '3',
  //           label: t('micro.business.foodAndBeverage.3'),
  //         },
  //         {
  //           value: '4',
  //           label: t('micro.business.foodAndBeverage.4'),
  //         },
  //         {
  //           value: '5',
  //           label: t('micro.business.foodAndBeverage.5'),
  //         },
  //         {
  //           value: '6',
  //           label: t('micro.business.foodAndBeverage.6'),
  //         },
  //       ],
  //     },
  //   };
  // }, [t]);

  // const selectedIndustrySurveyOptionList = (industryIndex: string) => {
  //   switch (industryIndex) {
  //     case '1':
  //       return surveyOptionData.retail.optionList;
  //     case '5':
  //       return surveyOptionData.ec.optionList;
  //     case '6':
  //       return surveyOptionData.newMedia.optionList;
  //     case '7':
  //       return surveyOptionData.marketingAgency.optionList;
  //     case '8':
  //       return surveyOptionData.foodAndBeverage.optionList;
  //     default:
  //       return surveyOptionData.generic.optionList;
  //   }
  // };

  useEffect(() => {
    setIsComplete(
      !isEmpty(data.company) &&
        !isEmpty(data.email) &&
        !isEmpty(data.phone) &&
        data.phone !== '886',
    );
  }, [data]);

  useEffect(() => {
    if (!isEmpty(email)) {
      setValue('email', email);
    }
  }, [email, setValue]);

  const onSubmit = handleSubmit(submit);

  return (
    <MicroContainerStyled>
      <form onSubmit={onSubmit} style={{ width: '100%', height: '100%' }}>
        <FormContainerStyled>
          <FormInnerContainerStyled>
            <TitleContainerStyled>
              <Typography variant="h4">{`${t(
                'micro.title',
              )} ${userName}`}</Typography>
            </TitleContainerStyled>
            <DescriptionContainerStyled>
              <Typography variant="body2" fontWeight="400" color="grey.700">
                {t('micro.description')}
              </Typography>
            </DescriptionContainerStyled>
            <InputFieldContainerStyled>
              <FormInput
                formName="email"
                formError={errors}
                label={t('micro.labelEmail')}
                register={register('email', {
                  required: t('common.required'),
                  pattern: {
                    value: REGEX_CHECK_EMAIL,
                    message: t('micro.errorValidEmail'),
                  },
                })}
                required
              />
            </InputFieldContainerStyled>
            <InputFieldContainerStyled>
              <PhoneInputComponent
                formName="phone"
                label={t('micro.labelPhoneNumber')}
                setValue={setValue}
                formError={errors}
                control={control}
                isTaiwanPhoneNumber={isTaiwanPhoneNumber(data.phone)}
              />
            </InputFieldContainerStyled>
            <InputFieldContainerStyled>
              <FormInput
                formName="company"
                formError={errors}
                label={t('micro.companyTitle')}
                register={register('company', {
                  required: t('common.required'),
                })}
                inputDescription={t('micro.companyDescription')}
                required
              />
            </InputFieldContainerStyled>
            {/* <SelectContainerStyled>
              <Select
                defaultValue=""
                fullWidth
                size="small"
                required
                label={t('micro.departmentTitle')}
                register={register('department')}
              >
                {departmentList.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </Select>
            </SelectContainerStyled>
            <SelectContainerStyled>
              <Select
                fullWidth
                size="small"
                label={t('micro.industryTitle')}
                register={register('industry')}
                required
                value={selectedIndustry}
                onChange={(e) => {
                  reset({
                    ...data,
                    '1': false,
                    '2': false,
                    '3': false,
                    '4': false,
                    '5': false,
                    '6': false,
                  });
                  setSelectedIndustry(e.target.value);
                }}
              >
                {industryItems.map((item) => (
                  <SelectItem key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </Select>
            </SelectContainerStyled>
            {!isEmpty(selectedIndustry) && (
              <Box>
                <Typography
                  variant="subtitle2"
                  color="grey.800"
                  sx={{ marginBottom: '16px' }}
                >
                  {t('micro.businessTitle')}
                </Typography>
                <Box flexDirection="column" display="flex" mb="12px">
                  {selectedIndustrySurveyOptionList(selectedIndustry).map(
                    (item, index) => (
                      <Controller
                        key={index}
                        control={control}
                        name={item.value as ItemValueType}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => {
                          return (
                            <Checkbox
                              label={item.label}
                              onChange={onChange}
                              checked={!!value}
                              variant="body1"
                            />
                          );
                        }}
                      />
                    ),
                  )}
                </Box>
              </Box>
            )} */}
            {/* <Box mb="1rem">
              <Typography
                variant="h6"
                fontWeight="600"
                fontSize="1rem"
                color="grey.800"
              >
                <Trans i18nKey="micro.botQuestion" />
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row">
              <Box flexDirection="row" width="50%">
                <Button
                  sx={{ mr: '1rem' }}
                  variant={watch('isUsed') ? 'contained' : 'outlined'}
                  color={watch('isUsed') ? 'primary' : 'inherit'}
                  onClick={() => setValue('isUsed', true)}
                >
                  <Trans i18nKey="common.yes" />
                </Button>
                <Button
                  variant={watch('isUsed') === false ? 'contained' : 'outlined'}
                  color={watch('isUsed') === false ? 'primary' : 'inherit'}
                  onClick={() => setValue('isUsed', false)}
                >
                  {t('common.no')}
                </Button>
              </Box>
            </Box> */}
          </FormInnerContainerStyled>
          <BottomButtonContainerStyled>
            <Button
              id="start_to_use"
              fullWidth={breakPoint < BREAK_POINT.MD}
              loading={isSendingMicro}
              loadingColor="white"
              type="submit"
              disabled={!isComplete || isSendingMicro}
              variant="contained"
              color="primary"
            >
              {t('micro.submit')}
            </Button>
          </BottomButtonContainerStyled>
        </FormContainerStyled>
      </form>
    </MicroContainerStyled>
  );
}

export default SorghumFeaturesMicro;

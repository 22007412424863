import { useCanvasView } from '@frontend/editor/data-access';
import {
  EditorCtx,
  UICtx as EditorUICtx,
} from '@frontend/editor/external-providers';
import {
  DrawerTypesEnum,
  ModalTypesEnum as EditorModalTypesEnum,
} from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useProjectLinked,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { Box, styled } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, { useStoreApi } from 'reactflow';
import 'reactflow/dist/style.css';
import ConnectionLineComponent from '../connection-line-component/connection-line-component';
import Control from '../control/control';
import DrawerThreadsCommentReplyConditions from '../drawer-threads-comment-reply-conditions/drawer-threads-comment-reply-conditions';
import DrawerThreadsCommentReplyPeriod from '../drawer-threads-comment-reply-period/drawer-threads-comment-reply-period';
import DrawerThreadsCommentReplyTo from '../drawer-threads-comment-reply-to/drawer-threads-comment-reply-to';
import {
  CustomFocusMarker,
  CustomMarker,
} from '../editor-custom-edge/editor-custom-edge';
import NodeCommentAutoReplyBlock from '../node-threads-comment-reply-block/node-threads-comment-reply-block';
import NodePublicReplyBlock from '../node-threads-public-reply-block/node-threads-public-reply-block';

const initialEdges = [
  {
    source: '1',
    target: '2',
    id: '1',
  },
];

const nodeTypes = {
  BLOCK: NodeCommentAutoReplyBlock,
  PUBLIC_REPLY: NodePublicReplyBlock,
};

interface Props {
  commentAutoReplyID: string;
}
const ContainerStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  background: theme.palette['grey']['200'],
}));

export const FlowThreadsCommentAutoReply = ({ commentAutoReplyID }: Props) => {
  const store = useStoreApi();
  const { nodeInternals } = store.getState();
  const {
    drawerType,
    openModal: openEditorModal,
    closeDrawer,
  } = useContext(EditorUICtx);
  const { openModal } = useContext(UICtx);
  const { data } = useGetCommentReply(commentAutoReplyID);
  const { data: linked } = useProjectLinked();
  const { isDrawerUpdate } = useContext(EditorCtx);
  const { focusNodeByReactFlowID } = useCanvasView();
  const [isLoaded, setIsLoaded] = useState(false);
  const [panOnDrag, setPanOnDrag] = useState(true);

  const initialNodes = [
    {
      id: '1',
      type: 'BLOCK',
      data: { commentAutoReplyID },
      position: {
        x: 0,
        y: 0,
      },
    },
    {
      id: '2',
      type: 'PUBLIC_REPLY',
      data: {
        commentAutoReplyID,
      },
      position: {
        x: 400,
        y: 0,
      },
    },
  ];

  const isActive = data?.isActive;

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      if (!linked?.threads?.id) {
        e.preventDefault();
        e.stopPropagation();
        openModal(ModalTypesEnum.CONNECT_THREADS_MODAL);
      }
    },
    [linked?.threads?.id, openModal],
  );

  const handleCanvasClick = useCallback(
    (e: React.MouseEvent) => {
      if (isDrawerUpdate) {
        e.preventDefault();
        e.stopPropagation();
        openEditorModal(EditorModalTypesEnum.CLOSE_UNSAVED_DRAWER);
      } else {
        closeDrawer();
      }
    },
    [closeDrawer, isDrawerUpdate, openEditorModal],
  );

  useEffect(() => {
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0 && !isLoaded) {
      setTimeout(() => {
        focusNodeByReactFlowID('1');
      }, 0);
      setIsLoaded(true);
    }
  }, [focusNodeByReactFlowID, isLoaded, nodeInternals]);

  return (
    <ContainerStyled onClickCapture={handleClick}>
      {drawerType === DrawerTypesEnum.THREADS_COMMENT_REPLY_TO && !isActive && (
        <DrawerThreadsCommentReplyTo />
      )}
      {drawerType === DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD &&
        !isActive && <DrawerThreadsCommentReplyPeriod />}
      {drawerType === DrawerTypesEnum.THREADS_COMMENT_REPLY_CONDITIONS &&
        !isActive && <DrawerThreadsCommentReplyConditions />}
      <ReactFlow
        onClickCapture={handleCanvasClick}
        defaultNodes={initialNodes}
        defaultEdges={initialEdges}
        nodeTypes={nodeTypes}
        minZoom={0.1}
        connectionLineComponent={ConnectionLineComponent}
        nodesDraggable={false}
        panOnDrag={panOnDrag}
        onNodeMouseEnter={() => setPanOnDrag(false)}
        onNodeMouseLeave={() => setPanOnDrag(true)}
        preventScrolling={true}
        deleteKeyCode={null}
      >
        <Control showSelectionMode={false} />
      </ReactFlow>
      <CustomMarker />
      <CustomFocusMarker />
    </ContainerStyled>
  );
};

export enum AccountPermission {
  'OWNER' = 1,
  'ADMIN' = 2,
  'VIEWER' = 3,
}

export interface GetAccountInfoResponseType {
  userName: string;
  step: 'fb_connect' | 'question' | 'choose_project' | 'complete';
  language: 'en_us' | 'zh_tw';
  headshot?: string;
  tutorial: boolean;
  registrationSource?: number;
  email?: string;
  isOnboarding: boolean;
  //1: owner, 2: admin, 3: viewer
  permission?: AccountPermission;
  onboardingLevel: string;
}

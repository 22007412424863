import { SocialTypeEnum } from '@frontend/sorghum/interface';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useCustomizedTranslation = (type: SocialTypeEnum | undefined) => {
  const [messenger] = useTranslation();
  const [threads] = useTranslation('threads');

  const t = useMemo(() => {
    switch (type) {
      case SocialTypeEnum.FACEBOOK:
        return messenger;
      case SocialTypeEnum.THREADS:
        return threads;
      default:
        return messenger;
    }
  }, [messenger, threads, type]);

  return {
    t,
  };
};

import {
  PostProjectRequest,
  PostProjectResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { PROJECT } from '../apis';

async function postCreateProject(
  props: PostProjectRequest,
): Promise<ResponseWithData<PostProjectResponse>> {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneOffset = new Date().getTimezoneOffset();

  return axios
    .post(PROJECT, { ...props, timezone, timezoneOffset })
    .then((res) => res.data);
}

export function usePostProject() {
  return useMutation({
    mutationKey: ['post-project'],
    mutationFn: (props: PostProjectRequest) => postCreateProject(props),
  });
}

export default usePostProject;

import { Dialog } from '@frontend/components/ui';
import {
  useGetAccountInfo,
  usePostAccountTutorial,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { usePath } from '@frontend/sorghum/utils';
import { Box, styled, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Step1After from '../../images/onboarding/onboarding-welcome-step1-after.png';
import Step1Before from '../../images/onboarding/onboarding-welcome-step1-before.png';
import Step2After from '../../images/onboarding/onboarding-welcome-step2-after.png';
import Step2Before from '../../images/onboarding/onboarding-welcome-step2-before.png';
import Step3After from '../../images/onboarding/onboarding-welcome-step3-after.png';
import Step3Before from '../../images/onboarding/onboarding-welcome-step3-before.png';
import Step4After from '../../images/onboarding/onboarding-welcome-step4-after.png';
import Step4Before from '../../images/onboarding/onboarding-welcome-step4-before.png';
import SurveyBar from '../survey-bar/survey-bar';

interface TourOnboardingWelcomeModalProps {
  open: boolean;
  completed: boolean;
  onStart: () => void;
  onClose: () => void;
}

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const ImageRowWrapperStyled = styled(Box)(({ theme }) => ({
  zIndex: 100,
  display: 'inline-flex',
  justifyContent: 'space-between',
}));

const StepWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > img': {
    width: '200px',
    height: '200px',
    marginBottom: '16px',
  },
}));

const SurveyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  marginTop: '16px',
  height: '350px',
  width: '100%',
  gap: '116px',
}));

export const TourOnboardingWelcomeModal: FC<
  TourOnboardingWelcomeModalProps
> = ({
  open,
  completed,
  onStart,
  onClose,
}: TourOnboardingWelcomeModalProps) => {
  const [t] = useTranslation();
  const { openModal, setIsOpenMissionListModal } = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { mutate: updateAccountTutorial } = usePostAccountTutorial();
  const [step, setStep] = useState(1);
  const [score, setScore] = useState<number>(0);
  const { data: info } = useGetAccountInfo();
  const mode = completed ? 'finish' : 'start';

  const finishTutorial = useCallback(
    (type?: 'later' | 'template') => {
      updateAccountTutorial(
        {
          marketingExpert: {
            canvasTutorial: {
              score,
            },
          },
        },
        {
          onSuccess: () => {
            onClose();
            if (type === 'template') {
              navigateToProjectPage();
              openModal(ModalTypesEnum.LEVEL1_LESSON2);
            } else {
              setIsOpenMissionListModal(true);
              navigateToProjectPage();
            }
          },
        },
      );
    },
    [
      navigateToProjectPage,
      onClose,
      openModal,
      score,
      setIsOpenMissionListModal,
      updateAccountTutorial,
    ],
  );

  const { title, confirmBtnText, handleConfirm } = useMemo(() => {
    if (step === 2) {
      return {
        title: t('tour.modal.welcome.survey.title'),
        confirmBtnText: t('tour.modal.welcome.survey.start'),
        handleConfirm: () => finishTutorial('template'),
      };
    } else if (mode === 'finish') {
      return {
        title: t('tour.modal.welcome.finish.title', { name: info?.userName }),
        confirmBtnText: t('tour.modal.welcome.finish.next'),
        handleConfirm: () => setStep(2),
      };
    }
    return {
      title: t(`tour.modal.welcome.start.title`, { name: info?.userName }),
      confirmBtnText: t(`tour.modal.welcome.start.button`),
      handleConfirm: onStart,
    };
  }, [finishTutorial, info?.userName, mode, onStart, step, t]);

  return (
    <Dialog
      size="md"
      title={title}
      open={open}
      {...(step === 2 && {
        cancelBtnText: t('tour.modal.welcome.survey.later'),
      })}
      confirmBtnText={confirmBtnText}
      closeBtn={false}
      autoClose={false}
      handleClose={() => finishTutorial('later')}
      handleConfirm={handleConfirm}
      disableEscapeKeyDown
      disableConfirmButton={step === 2 && score === 0}
    >
      {step === 1 ? (
        <ContentWrapperStyled>
          {!completed && (
            <Typography variant="body3" color="grey.500" alignSelf="end">
              {t(`tour.modal.welcome.${mode}.time`)}
            </Typography>
          )}
          <Typography>
            <Typography component="span" variant="body1" color="grey.800">
              {t(`tour.modal.welcome.${mode}.description`)}
            </Typography>
            {mode === 'start' && (
              <Typography component="span" variant="body1" color="grey.600">
                {t(`tour.modal.welcome.start.memo`)}
              </Typography>
            )}
          </Typography>
          <ImageRowWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step1After : Step1Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.welcome.start.connect')}
              </Typography>
            </StepWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step2After : Step2Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.welcome.start.inviteSubscribe')}
              </Typography>
            </StepWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step3After : Step3Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.welcome.start.collectData')}
              </Typography>
            </StepWrapperStyled>
            <StepWrapperStyled>
              <img
                src={completed ? Step4After : Step4Before}
                alt="step1-before"
              />
              <Typography variant="body1" color="grey.700">
                {t('tour.modal.welcome.start.previewAndPublish')}
              </Typography>
            </StepWrapperStyled>
          </ImageRowWrapperStyled>
        </ContentWrapperStyled>
      ) : (
        <SurveyWrapperStyled>
          <Typography variant="body1" color="grey.800">
            {t('tour.modal.welcome.survey.description')}
          </Typography>
          <SurveyBar
            options={[1, 2, 3, 4, 5]}
            value={score}
            firstText={t('tour.modal.welcome.survey.unhelpful')}
            lastText={t('tour.modal.welcome.survey.helpful')}
            setValue={setScore}
          />
          <Typography variant="body1" color="grey.800">
            {t('tour.modal.welcome.survey.memo')}
          </Typography>
        </SurveyWrapperStyled>
      )}
    </Dialog>
  );
};

export default TourOnboardingWelcomeModal;

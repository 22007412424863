import {
  Divider,
  EllipsisItemProps,
  GradientButton,
  Menu,
  MenuItem,
  Typography,
} from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import {
  useGetAccountInfo,
  useGetBilling,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  AccountPermission,
  GetBillingPlanEnum,
  GetEditorBasicFlowStatusResponse,
  ModalTypesEnum,
} from '@frontend/sorghum/interface';
import {
  AI_GUIDED,
  calculateRemainDays,
  dayjs,
  PAGE_COMMENT_AUTO_REPLY_LIST,
  PAGE_COUPON_LIST,
  PAGE_SEQUENCE,
  SELF_GUIDED,
  sendGAEvent,
  TEMPLATE_SELECTION,
  usePath,
} from '@frontend/sorghum/utils';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Menu as MuiMenu } from '@mui/material';
import { styled } from '@mui/system';
import { FC, useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyIcon, FilledIcon, HalfIcon } from '../icons';
import MissionBoard from '../mission-board/mission-board';

interface StatusIconProps {
  requiredCount: number;
  finishedCount: number;
}

export interface DrawerGroup {
  id: string;
  label: string;
}

/* eslint-disable-next-line */
export interface DrawerMenuProps {
  selectGroup: string;
  groups: DrawerGroup[];
  ellipsisItems: EllipsisItemProps[];
  basicSettingStatus: GetEditorBasicFlowStatusResponse;
  setSelectGroup: (id: string) => void;
  onDropFlow: (flows: string[], groupId: string) => void;
  onFlowGroupClick: (id: string) => void;
  onBasicSettingClick: (settingType: string) => void;
  onCreateNewGroupClick: () => void;
  onResortGroup: (arr: string[]) => void;
  isShining: boolean;
}

const drawerWidth = '260px';

const DrawerMenuContainerStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette['grey'][100],
  display: 'inline-flex',
  height: '100%',
}));

const DrawerMenuMainWrapper = styled(Box)(({ theme }) => ({
  width: drawerWidth,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const GroupWrapper = styled(Box)(({ theme }) => ({}));

const BasicSettingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
}));

const DrawerToggleBarStyled = styled(Box)(({ theme }) => ({
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const TopFlowWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette['grey'][900],
}));

const ButtonCreateWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  color: theme.palette['grey'][900],
}));

const BasicSettingTitleWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'inline-flex',
  justifyContent: 'space-between',
}));

const BasicSettingItemWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingLeft: theme.spacing(2),
  boxSizing: 'border-box',

  '&.add-padding-bottom': {
    paddingBottom: '60px',
  },
}));

const BasicSettingStatusIconStyled = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  width: 0,
}));

const BadgeStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '4px 8px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: `${theme.palette['primary']['main']}10`,
  borderRadius: '4px',
}));

const MenuItemStyled = styled(Box)(({ theme }) => ({
  padding: '6px 0',
  width: '240px',
  whiteSpace: 'pre-wrap',
}));

const StatusIcon: FC<StatusIconProps> = ({
  finishedCount,
  requiredCount,
}: StatusIconProps) => {
  if (finishedCount === 0) {
    return <EmptyIcon fontSize="small" />;
  }
  const result = finishedCount / requiredCount;
  switch (result) {
    case 1: {
      return <FilledIcon fontSize="small" />;
    }
    case 0.5: {
      return <HalfIcon fontSize="small" />;
    }
    default: {
      return <EmptyIcon fontSize="small" />;
    }
  }
};

export const DrawerMenu: FC<DrawerMenuProps> = ({
  groups,
  basicSettingStatus,
  ellipsisItems,
  selectGroup,
  setSelectGroup,
  onFlowGroupClick,
  onDropFlow,
  onBasicSettingClick,
  onResortGroup,
  onCreateNewGroupClick,
  isShining,
}) => {
  const [t] = useTranslation();
  const { navigateToProjectPage } = usePath();
  const { isViewer } = useProjectPermission();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { openModal } = useContext(UICtx);

  const open = Boolean(anchorEl);
  const { data: billing } = useGetBilling();
  const { data: account } = useGetAccountInfo();

  // const SortableItem: React.ComponentClass<
  //   SortableElementProps & { value: DrawerGroup },
  //   any
  // > = SortableElement(({ value }: { value: DrawerGroup }) => {
  //   const [props, drop] = useDrop(() => ({
  //     accept: DragType.ROW,
  //     drop: (_item, monitor) => {
  //       const item = monitor.getItem() as { dropData: any[] };
  //       if (item.dropData) {
  //         const flows = item.dropData.map((x) => x.id);
  //         onDropFlow(flows, value.id);
  //       }
  //     },
  //   }));
  //   return (
  //     <Box ref={drop}>
  //       <MenuItem
  //         key={value.id}
  //         selected={selectGroup === value.id}
  //         content={value.label}
  //         onClick={() => handleGroupClick(value.id)}
  //         StartIcon={<FolderIcon />}
  //         items={ellipsisItems}
  //       />
  //     </Box>
  //   );
  // });

  // const SortableList: React.ComponentClass<
  //   SortableContainerProps & { items: DrawerGroup[] },
  //   any
  // > = SortableContainer(({ items }: { items: DrawerGroup[] }) => {
  //   return (
  //     <div>
  //       {items.map((value, index) => (
  //         <SortableItem
  //           key={`sort-group-item-${value}-${index}`}
  //           index={index}
  //           value={value}
  //         />
  //       ))}
  //     </div>
  //   );
  // });

  // const onSortEnd = ({
  //   oldIndex,
  //   newIndex,
  // }: {
  //   oldIndex: number;
  //   newIndex: number;
  // }) => {
  //   const newArray = resortList(groups, oldIndex, newIndex);
  //   onResortGroup(newArray.map((x) => x.id));
  // };

  const [isOpen, setIsOpen] = useState(true);
  const [isBasicSettingOpen, setIsBasicSettingOpen] = useState(true);
  const [isGrowthToolOpen, setIsGrowthToolOpen] = useState(true);

  const handleGroupClick = useCallback(
    (id: string) => {
      setSelectGroup(id);
      onFlowGroupClick(id);
    },
    [onFlowGroupClick, setSelectGroup],
  );

  const handleClick = () => {
    openModal(ModalTypesEnum.START_WITH);
    sendGAEvent(
      'Automated Messaging',
      'Create',
      'Automated Messaging - create BTN - click',
      '',
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleBasicSetting = useCallback(() => {
    setIsBasicSettingOpen(!isBasicSettingOpen);
  }, [isBasicSettingOpen]);

  const toggleGrowthTool = useCallback(() => {
    setIsGrowthToolOpen(!isGrowthToolOpen);
  }, [isGrowthToolOpen]);

  return (
    <DrawerMenuContainerStyled>
      {isOpen && (
        <DrawerMenuMainWrapper display="flex">
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <TopFlowWrapper
              style={{
                padding: '24px 16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6">
                {t('flows.leftSidebar.title')}
              </Typography>
              {billing?.plan === GetBillingPlanEnum.TRIAL &&
                account?.permission !== AccountPermission.VIEWER && (
                  <BadgeStyled>
                    <Typography variant="note" color="grey.900">
                      <Trans
                        i18nKey="flows.leftSidebar.badge.trialPlanCountdown"
                        values={{
                          day: calculateRemainDays({
                            endDate: dayjs(billing?.endedAt),
                          }),
                        }}
                      />
                    </Typography>
                  </BadgeStyled>
                )}
              {billing?.plan === GetBillingPlanEnum.ADVANCE &&
                account?.permission !== AccountPermission.VIEWER && (
                  <BadgeStyled>
                    <Typography variant="note" color="grey.900">
                      <Trans i18nKey="flows.leftSidebar.badge.advancedPlan" />
                    </Typography>
                  </BadgeStyled>
                )}
              {billing?.plan === GetBillingPlanEnum.EXPIRY &&
                account?.permission !== AccountPermission.VIEWER && (
                  <BadgeStyled>
                    <Typography variant="note" color="grey.900">
                      <Trans i18nKey="flows.leftSidebar.badge.trialEnd" />
                    </Typography>
                  </BadgeStyled>
                )}
            </TopFlowWrapper>
            <ButtonCreateWrapper>
              <GradientButton
                id="create"
                isShining={isShining}
                onClick={handleClick}
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                sx={{
                  justifyContent: 'flex-start',
                }}
                disabled={isViewer}
              >
                {t('flows.leftSidebar.create')}
              </GradientButton>
              <MuiMenu
                id="create-menu"
                anchorEl={anchorEl}
                open={open}
                onClick={handleClose}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigateToProjectPage(
                      `/${TEMPLATE_SELECTION}/${SELF_GUIDED}`,
                    );
                    sendGAEvent(
                      'Template',
                      'Template path selection',
                      'Automate - Create btn - start from template - click',
                      '',
                    );
                  }}
                  content={
                    <MenuItemStyled>
                      {t('flows.leftSidebar.addMenu.template')}
                    </MenuItemStyled>
                  }
                />
                <MenuItem
                  onClick={() => {
                    navigateToProjectPage(
                      `/${TEMPLATE_SELECTION}/${AI_GUIDED}`,
                    );
                    sendGAEvent(
                      'Template',
                      'Template path selection',
                      'Automate - Create btn - start from AI recommended template - click',
                      '',
                    );
                  }}
                  content={
                    <MenuItemStyled>
                      {t('flows.leftSidebar.addMenu.ai')}
                    </MenuItemStyled>
                  }
                />
                <MenuItem
                  onClick={() => {
                    sendGAEvent(
                      'Template',
                      'Template path selection',
                      'Automate - Create btn - start from blank canvas - click',
                      '',
                    );
                  }}
                  content={
                    <MenuItemStyled>
                      {t('flows.leftSidebar.addMenu.blank')}
                    </MenuItemStyled>
                  }
                />
              </MuiMenu>
            </ButtonCreateWrapper>
            <GroupWrapper>
              <Menu>
                {/* <MenuItem
                  selected={selectGroup === ''}
                  onClick={() => handleGroupClick('')}
                  content={<Trans i18nKey="flows.leftSidebar.all" />}
                  StartIcon={<Inbox />}
                /> */}

                {/* {groups && (
                  <SortableList
                    items={groups}
                    onSortEnd={onSortEnd}
                    pressDelay={200}
                  />
                )} */}
              </Menu>
            </GroupWrapper>
          </Box>
          <BasicSettingWrapper>
            <Divider />
            <BasicSettingTitleWrapper>
              <Typography variant="h6">
                {t('flows.leftSidebar.basicSetting')}
              </Typography>
              {isBasicSettingOpen ? (
                <UnfoldLessIcon onClick={toggleBasicSetting} />
              ) : (
                <UnfoldMoreIcon onClick={toggleBasicSetting} />
              )}
            </BasicSettingTitleWrapper>
            {/* 底下的 basic setting */}
            {isBasicSettingOpen && (
              <BasicSettingItemWrapper>
                <Menu>
                  {/* Welcome Message */}
                  <MenuItem
                    id="welcome_message"
                    onClick={() => onBasicSettingClick('welcome')}
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.welcomeMessage')}
                      </Typography>
                    }
                    EndIcon={
                      <BasicSettingStatusIconStyled>
                        {basicSettingStatus?.welcomeMessage.finishedCount ===
                          0 && (
                          <CircleOutlinedIcon sx={{ color: 'warning.main' }} />
                        )}
                      </BasicSettingStatusIconStyled>
                    }
                  />
                  {/* Persistent Menu */}
                  <MenuItem
                    id="persistent_menu"
                    onClick={() => onBasicSettingClick('menu')}
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.persistentMenu')}
                      </Typography>
                    }
                    EndIcon={
                      <BasicSettingStatusIconStyled>
                        {basicSettingStatus?.persistentMenu.finishedCount ===
                          0 && (
                          <CircleOutlinedIcon sx={{ color: 'warning.main' }} />
                        )}
                      </BasicSettingStatusIconStyled>
                    }
                  />
                  {/* Conversation Starter */}
                  {/* <MenuItem
                    onClick={() => onBasicSettingClick('starter')}
                    content={
                      <Typography variant="body2">
                        Conversation Starter(
                        {basicSettingStatus.conversationStarter.finishedCount}/
                        {basicSettingStatus.conversationStarter.requiredCount})
                      </Typography>
                    }
                    EndIcon={
                      <BasicSettingStatusIconStyled>
                        <StatusIcon
                          finishedCount={
                            basicSettingStatus.conversationStarter.finishedCount
                          }
                          requiredCount={
                            basicSettingStatus.conversationStarter.requiredCount
                          }
                        />
                      </BasicSettingStatusIconStyled>
                    }
                  /> */}
                  {/* Default Answer */}
                  <MenuItem
                    id="default_answer"
                    onClick={() => onBasicSettingClick('answer')}
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.defaultAnswer')}
                      </Typography>
                    }
                    EndIcon={
                      <BasicSettingStatusIconStyled>
                        {basicSettingStatus?.defaultAnswer.finishedCount ===
                          0 && (
                          <CircleOutlinedIcon sx={{ color: 'warning.main' }} />
                        )}
                      </BasicSettingStatusIconStyled>
                    }
                  />
                  {/* Keywords */}
                  {/* <MenuItem
                    onClick={() => onBasicSettingClick('keyword')}
                    content={
                      <Typography variant="body2">Keywords (0/1)</Typography>
                    }
                    EndIcon={
                      <BasicSettingStatusIconStyled>
                        <EmptyIcon fontSize="small" />
                      </BasicSettingStatusIconStyled>
                    }
                  /> */}
                </Menu>
              </BasicSettingItemWrapper>
            )}
            <BasicSettingTitleWrapper>
              <Typography variant="h6">
                {t('flows.leftSidebar.growthTool')}
              </Typography>
              {isGrowthToolOpen ? (
                <UnfoldLessIcon onClick={toggleGrowthTool} />
              ) : (
                <UnfoldMoreIcon onClick={toggleGrowthTool} />
              )}
            </BasicSettingTitleWrapper>
            {isGrowthToolOpen && (
              <BasicSettingItemWrapper className="add-padding-bottom">
                <Menu>
                  <MenuItem
                    id="follow_up"
                    onClick={() => navigateToProjectPage(PAGE_SEQUENCE)}
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.sequence')}
                      </Typography>
                    }
                  />
                  <MenuItem
                    id="coupon"
                    onClick={() => navigateToProjectPage(PAGE_COUPON_LIST)}
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.coupon')}
                      </Typography>
                    }
                  />
                  <MenuItem
                    id="growth_comment_auto_reply"
                    onClick={() =>
                      navigateToProjectPage(PAGE_COMMENT_AUTO_REPLY_LIST)
                    }
                    content={
                      <Typography variant="body2">
                        {t('flows.leftSidebar.comment')}
                        {' ➚'}
                      </Typography>
                    }
                  />
                </Menu>
              </BasicSettingItemWrapper>
            )}
          </BasicSettingWrapper>
        </DrawerMenuMainWrapper>
      )}
      <MissionBoard />
      <DrawerToggleBarStyled onClick={toggleDrawer}>
        {isOpen && <ChevronLeftIcon fontSize="small" />}
        {!isOpen && <ChevronRightIcon fontSize="small" />}
      </DrawerToggleBarStyled>
    </DrawerMenuContainerStyled>
  );
};

export default DrawerMenu;

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// 中間 f 是白色的
import { ReactComponent as FacebookMD } from '../../images/facebook-medium.svg';

// 中間 f 是透明的
import { ReactComponent as FacebookTrans } from '../../images/facebook.svg';

interface FacebookIconProps extends SvgIconProps {
  size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const FacebookIcon = (props: FacebookIconProps) => (
  <SvgIcon
    {...props}
    component={
      props?.size === 'sm' ||
      props.size === 'md' ||
      props?.size === 'lg' ||
      props?.size === 'xl'
        ? FacebookMD
        : FacebookTrans
    }
    sx={{
      ...(props?.size === 'sm'
        ? { width: '16px', height: '16px' }
        : props?.size === 'lg'
        ? { width: '32px', height: '32px' }
        : props?.size === 'xl'
        ? { width: '40px', height: '40px' }
        : ''),
    }}
  />
);

export default FacebookIcon;

import {
  DashboardSourceChannelData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_SOURCE_CHANNEL } from '../apis';

const defaultValue = [] as DashboardSourceChannelData[];

async function postDataDashboardSourceChannelData(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardSourceChannelData[]> {
  return axios
    .post(`${DATA_DASHBOARD_SOURCE_CHANNEL}?projectId=${projectID}`, params)
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardSourceChannelData(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-channel-data'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardSourceChannelData(projectID, props);
    },
  });
}

export default usePostDataDashboardSourceChannelData;

import {
  PostAccountQuestionProps,
  Response,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { ACCOUNT_QUESTION } from '../apis';

async function postAccountQuestion(
  data: PostAccountQuestionProps,
): Promise<Response> {
  return axios.post(ACCOUNT_QUESTION, data).then((res) => {
    if (res.data.code !== 20000) {
      throw Error(res.data.code);
    }
    return res.data;
  });
}

export function usePostAccountQuestion() {
  return useMutation({
    mutationKey: ['account-question-post'],
    mutationFn: (props: PostAccountQuestionProps) => postAccountQuestion(props),
  });
}

export default usePostAccountQuestion;

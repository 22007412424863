import { Select } from '@frontend/components/ui';
import {
  Box,
  Button,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const PeopleContainerStyled = styled(Box)(({ theme }) => ({
  padding: '32px 40px',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette['grey'][100],
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: '20px',
}));

export const FilterContainerWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '18px 24px',
  backgroundColor: theme.palette['grey']['white'],
  borderRadius: '8px',
  border: `1px solid ${theme.palette['grey'][200]}`,
  width: '100%',
  overflow: 'scroll',
}));

export const FilterRightItemContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'calc(100% - 675px)',

  '.search-bar': {
    width: 'calc(100% - 128px)',
  },

  '@media (max-width: 1280px)': {
    width: '440px',
    '.search-bar': {
      paddingRight: '24px',
    },
  },
}));

export const FilterLeftItemContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '@media (max-width: 1280px)': {
    '.date-picker': {
      width: '368px',
    },
  },
}));

export const FilterContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '16px 24px',
  alignItems: 'center',
  background: theme.palette['grey']['white'],
  borderRadius: '8px',
  border: `1px solid ${theme.palette['grey'][300]}`,
  gap: '8px',
  overflow: 'hidden',
}));

export const ActionButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '12px',
}));

export const PaperContainerStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'calc(100% - 172px)',
  width: '100%',
  overflow: 'scroll',
  borderRadius: '8px',
  border: `1px solid ${theme.palette['grey'][300]}`,
}));

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  height: 'calc(100% - 48px)',

  '.sticky-start': {
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette['grey']['white'],
    paddingTop: '12px',
    paddingBottom: '8px',
  },

  '.sticky': {
    position: 'sticky',
    left: '52px',
    backgroundColor: theme.palette['grey']['white'],
    paddingLeft: '40px',
  },

  '.sticky-end': {
    position: 'sticky',
    backgroundColor: theme.palette['grey']['white'],
    right: 0,
  },

  table: {
    tableLayout: 'fixed',
    '& > thead > tr > th': {
      color: theme.palette['bluegrey'][700],
    },
  },
}));

export const TableHeadContainerStyled = styled(TableHead)(({ theme }) => ({
  th: {
    background:
      'linear-gradient(0deg, rgba(96, 125, 139, 0.05), rgba(96, 125, 139, 0.05)), #FFFFFF',
    border: 0,
    boxShadow: 'none',
  },
}));

export const TableRowStyled = styled(TableRow)<{ $checked?: boolean }>(
  ({ theme, $checked }) => ({
    ...($checked && {
      background: `${theme.palette['background']['primary']['5']} !important`,
    }),

    '&:hover': {
      backgroundColor: theme.palette['grey'][100],
      cursor: 'pointer',
    },
  }),
);

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: '0 0 0 20px',
  boxSizing: 'border-box',
  height: '48px',
  '&.more-btn-column': {
    padding: 0,
  },

  '&.tag-column': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette['primary']['main'],
  },
}));

export const TableCellStickyStyled = styled(TableCellStyled)<{
  $checked?: boolean;
}>(({ theme, $checked }) => ({
  position: 'sticky',
  left: 0,
  padding: 0,

  '& > div': {
    borderRight: `1px solid ${theme.palette['grey'][200]}`,
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '44px',
    padding: '12px 0 8px 20px',
    width: '100%',
    height: '100%',
  },
}));

export const HeaderTableStickyHeaderStyled = styled(TableCell)(({ theme }) => ({
  padding: 0,
  position: 'sticky',
  left: 0,
  background:
    'linear-gradient(0deg, rgba(96, 125, 139, 0.05), rgba(96, 125, 139, 0.05)), #FFFFFF',
  '& > div': {
    borderRight: `1px solid ${theme.palette['grey'][200]}`,
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: '44px',
    width: '100%',
    height: '100%',
    padding: '12px 0 8px 20px',
  },
}));

export const HeaderTableCellStyled = styled(TableCell)(({ theme }) => ({
  paddingLeft: '20px',
  boxSizing: 'border-box',

  '&.clickable': {
    '&: hover': {
      background: theme.palette['bluegrey'][50],
    },
    cursor: 'pointer',
  },

  '&.sticky-header': {
    zIndex: 30,
    position: 'sticky',
    left: 52,
    borderRight: `1px solid ${theme.palette['grey'][200]}`,
    background:
      'linear-gradient(0deg, rgba(96, 125, 139, 0.05), rgba(96, 125, 139, 0.05)), #FFFFFF',
    '&:after': {
      content: '',
      position: 'absolute',
    },
  },
  '&.sticky-bottom': {
    zIndex: 30,
    position: 'sticky',
    right: 0,
  },
}));

export const SortHeaderStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '10px',

  '& svg': {
    fontSize: '14px',
  },
}));

export const TextOverflowStyled = styled(Box)(({ theme }) => ({
  '& > p': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const SubscribedTextStyled = styled(Box)<{ $subscribed: boolean }>(
  ({ theme, $subscribed }) => ({
    borderRadius: '4px',
    width: 'fit-content',
    padding: '2px 8px',
    background:
      theme.palette['background'][$subscribed ? 'primary' : 'bluegrey500'][5],
    border: `1px solid ${
      theme.palette['background'][$subscribed ? 'primary' : 'bluegrey500'][5]
    }`,
  }),
);

export const PaginationStyled = styled(TablePagination)<{ component: 'div' }>(
  ({ theme }) => ({
    overflow: 'hidden',
    borderTop: `1px solid ${theme.palette['grey'][200]}`,
    height: '48px',
    '.MuiTablePagination-selectLabel': {
      display: 'none',
    },
    '.MuiInputBase-root': {
      display: 'none',
    },
    '.MuiTablePagination-displayedRows': {
      color: theme.palette['grey'][700],
    },
    '& > div': {
      minHeight: '48px',
      height: '48px',
    },
  }),
);

export const ButtonStyled = styled(Button)(({ theme }) => ({
  marginRight: '12px',
  '&.Mui-disabled': {
    color: theme.palette['grey']['white'],
  },
}));

export const ResultCountStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px 10px 0',
}));

export const StickyCellContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& > div': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const PhotoStyled = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
}));

export const EmptyContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
}));

export const ButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

export const TagCellStyled = styled(TableCell)(({ theme }) => ({
  color: theme.palette['primary']['main'],
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export const TagStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: `${theme.palette['primary']['main']}0D`,
  marginRight: '8px',
  padding: '2px 8px',
  borderRadius: '4px',
  border: `${theme.palette['primary']['main']}1A`,
  color: theme.palette['primary']['main'],
  '&.attr-tag': {
    backgroundColor: `${theme.palette['secondary']['dark']}0D`,
    '& > span': {
      color: theme.palette['secondary']['dark'],
    },
  },
}));

export const InfoContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '.user-profile': {
    display: 'flex',
    flexDirection: 'column',
  },
  padding: '8px 0',
  marginBottom: '24px',
}));

export const TagsContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  borderTop: `1px solid ${theme.palette['grey'][200]}`,
}));

export const HighlightContainerStyled = styled(Typography)<{
  component: 'span';
}>(({ theme }) => ({
  color: theme.palette['grey']['black'],
  backgroundColor: `${theme.palette['warning']['light']}`,
  borderRadius: '2px',
  border: `1px solid ${theme.palette['warning']['main']}`,
}));

export const TextContainerStyled = styled(Typography)<{ component: 'span' }>(
  ({ theme }) => ({
    border: `1px solid transparent`,
  }),
);

export const ConditionFilterStyled = styled(Select)(({ theme }) => ({
  width: '120px',
}));

export const AutocompleteContainerStyled = styled(Box)(({ theme }) => ({
  width: '120px',
  marginRight: '8px',
}));

export const DividerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  border: `1px solid ${theme.palette['grey'][300]}`,
}));

import { globalTheme } from '@frontend/components/external-providers';
import { TextField, Typography } from '@frontend/components/ui';
import { internalLinkToast, uuid } from '@frontend/components/utils';
import { useProjectPermission } from '@frontend/editor/data-access';
import {
  useGetCommentReply,
  useGetCommentReplyCondition,
  usePatchCommentReplyCondition,
  usePostCommentAutoReplyActive,
} from '@frontend/sorghum/data-access';
import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/Message';
import { Box, styled } from '@mui/material';
import { get } from 'lodash';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NodeProps, Position, useViewport } from 'reactflow';
import Button from '../button/button';
import EditorBlockBorder from '../editor-block-border/editor-block-border';
import EditorBlockContainer from '../editor-block-container/editor-block-container';
import EditorFloatPanel from '../editor-float-panel/editor-float-panel';
import { HandlePoint } from '../handle-point/handle-point';
import NodeHeader from '../node-header/node-header';

const BodyWrapperStyled = styled(Box)(({ theme }) => ({
  width: '316px',
  borderRadius: '0 0 12px 12px',
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 12px 12px 12px',
  gap: '10px',
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
  '& fieldset': {
    borderColor: `transparent`,
  },
  '& .MuiOutlinedInput-root.Mui-disabled fieldset': {
    borderColor: `transparent`,
  },

  '& .Mui-disabled': {
    '-webkit-text-fill-color': theme.palette['grey'][900],
  },
}));

const ElementWrapperStyled = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '12px',
  borderRadius: '8px',
  background: theme.palette['grey'][100],
}));

const InfoWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '8px',
  background: theme.palette['info']['light'],
}));

interface ItemInputProps {
  id: string;
  text: string;
  disabled: boolean;
  handleChange: (id: string, text: string) => void;
}

const ItemInput: FC<ItemInputProps> = ({
  id,
  text,
  disabled,
  handleChange,
}: ItemInputProps) => {
  const [t] = useTranslation('threads');
  const ref = useRef<HTMLInputElement>(null);
  return (
    <TextFieldStyled
      multiline
      fullWidth
      defaultValue={text}
      color="info"
      disabled={disabled}
      inputProps={{
        ref,
        maxLength: 500,
      }}
      size="small"
      error={text.length <= 0}
      onBlur={(e) => handleChange(id, e.target.value)}
      placeholder={t('commentReplyDetail.publicReply.placeholder')}
    />
  );
};

export const NodeThreadsPublicReplyBlock = ({
  id,
  selected,
  data,
}: NodeProps) => {
  const [t] = useTranslation('threads');
  const { zoom } = useViewport();
  const { data: commentAutoReplyData } = useGetCommentReply(
    data.commentAutoReplyID,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const isActive = commentAutoReplyData?.isActive;
  const { isViewer } = useProjectPermission();
  const conditionID = get(commentAutoReplyData, 'replyCondition.data.0.id', '');
  const { data: commentAutoReplyCondition } =
    useGetCommentReplyCondition(conditionID);
  const { mutate: activeComment } = usePostCommentAutoReplyActive();
  const { mutate: updateCondition } = usePatchCommentReplyCondition();

  const [hoverID, setHoverID] = useState<string | undefined>();
  const [contents, setContents] = useState<{ text: string; id: string }[]>([]);

  const handleChange = useCallback(
    (id: string, newValue: string) => {
      if (!commentAutoReplyCondition) return;

      setContents((prev) => {
        const newContents = prev.map((item) => {
          if (item.id === id) return { ...item, text: newValue };
          return item;
        });
        updateCondition({
          id: data.commentAutoReplyID,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyCondition,
              isWarning: false,
              publicReplyContent: newContents.map((i) => i.text),
            },
          ],
        });
        return newContents;
      });
    },
    [commentAutoReplyCondition, data.commentAutoReplyID, updateCondition],
  );

  const handleAddClick = useCallback(() => {
    if (!commentAutoReplyCondition) return;

    setContents((prev) => {
      const newContents = [...prev, { text: '', id: uuid() }];
      updateCondition({
        id: data.commentAutoReplyID,
        isAutoSave: true,
        replyCondition: [
          {
            ...commentAutoReplyCondition,
            isWarning: false,
            publicReplyContent: newContents.map((i) => i.text),
          },
        ],
      });
      return newContents;
    });
  }, [commentAutoReplyCondition, data.commentAutoReplyID, updateCondition]);

  const handleRemoveClick = useCallback(
    (index: number) => {
      if (!commentAutoReplyCondition) return;

      setContents((prev) => {
        const newContents = prev.filter((_, i) => i !== index);
        updateCondition({
          id: data.commentAutoReplyID,
          isAutoSave: true,
          replyCondition: [
            {
              ...commentAutoReplyCondition,
              isWarning: false,
              publicReplyContent: newContents.map((i) => i.text),
            },
          ],
        });
        return newContents;
      });
    },
    [commentAutoReplyCondition, data.commentAutoReplyID, updateCondition],
  );

  const handleBlockClick = useCallback(() => {
    if (isViewer) return;
    if (isActive) {
      internalLinkToast(
        t('commentReplyDetail.alert.info'),
        t('commentReplyDetail.alert.infoAction'),
        'info',
        () =>
          activeComment({
            id: data.commentAutoReplyID,
            enable: false,
          }),
      );
    }
  }, [activeComment, data.commentAutoReplyID, isActive, isViewer, t]);

  useEffect(() => {
    if (
      !isLoaded &&
      conditionID &&
      commentAutoReplyCondition?.publicReplyContent
    ) {
      setContents(
        commentAutoReplyCondition?.publicReplyContent.map((i) => ({
          text: i,
          id: uuid(),
        })),
      );
      setIsLoaded(true);
    }
  }, [commentAutoReplyCondition, conditionID, isLoaded]);

  return (
    <EditorBlockContainer nodeID={id} onClick={handleBlockClick}>
      <HandlePoint
        id="1"
        type="target"
        position={Position.Left}
        styles={{
          top: '5%',
        }}
        isConnected={false}
      />
      <EditorBlockBorder
        zoom={zoom}
        nodeID={id}
        selected={selected}
        color={get(globalTheme, 'palette.info.main', '')}
      >
        <NodeHeader
          color={globalTheme?.palette?.['primary']?.['main'] as string}
          background={globalTheme.palette?.['blue'][50]}
          icon={<MessageIcon fontSize="small" />}
          title={t('commentReplyDetail.publicReply.title')}
          readonly
        />
        <BodyWrapperStyled>
          <InfoWrapperStyled>
            <Typography variant="body2" color="info.dark">
              {t('commentReplyDetail.publicReply.info')}
            </Typography>
          </InfoWrapperStyled>
          {contents.map((item, index) => {
            return (
              <ElementWrapperStyled
                key={`element-${item.id}`}
                onMouseEnter={() => setHoverID(item.id)}
                onMouseLeave={() => setHoverID(undefined)}
              >
                {hoverID === item.id && !isActive && !isViewer && (
                  <EditorFloatPanel
                    index={index}
                    draggable={false}
                    direction="column"
                    handleDelete={() => handleRemoveClick(index)}
                    backgroundColor={globalTheme?.palette?.['grey']?.[100]}
                  />
                )}
                <ItemInput
                  id={item.id}
                  disabled={!!isActive || isViewer}
                  text={item.text}
                  handleChange={handleChange}
                />
              </ElementWrapperStyled>
            );
          })}
          {contents.length < 10 && !isActive && !isViewer && (
            <Button
              onClick={handleAddClick}
              startIcon={<AddIcon fontSize="small" />}
              variant="outlined"
              color="bluegrey300"
              dash
              fullWidth
              sx={{ textTransform: 'none' }}
            >
              {t('commentReplyDetail.publicReply.add')}
            </Button>
          )}
        </BodyWrapperStyled>
      </EditorBlockBorder>
    </EditorBlockContainer>
  );
};

export default memo(NodeThreadsPublicReplyBlock);

import { TableTreeItem } from '@frontend/components/interface';
import {
  DefaultPostIcon,
  EllipsisMenu,
  MenuItem,
  TableTree,
  Tooltip,
} from '@frontend/components/ui';
import { useProjectPermission } from '@frontend/editor/data-access';
import { convertNumberToK } from '@frontend/editor/utils';
import {
  useGetCommentReplyListDetail,
  usePostCommentReplyDetail,
} from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  CommentRepliesType,
  PostCommentReplyDetailType,
  PostDataCommentReplyResponse,
} from '@frontend/sorghum/interface';
import {
  COMMENT_AUTO_REPLY,
  CONTENT,
  dayjs,
  usePath,
} from '@frontend/sorghum/utils';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Box, Link, Skeleton, styled, Typography } from '@mui/material';
import { get, isEqual, isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentAutoReplyListDetailProps {
  replyList: CommentRepliesType[];
  isDataLoading?: boolean;
  replyListNumberData: PostDataCommentReplyResponse;
}

const ImageContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '32px',
  height: '32px',
  borderRadius: '4px',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette['grey'][100],
}));

export const CommentAutoReplyCellWithData = ({
  num,
  uniqNum,
  isLink,
  numType,
  commentID,
  name,
  isLoading,
  id,
}: {
  num?: number;
  uniqNum?: number;
  isLink?: boolean;
  numType?: string;
  commentID?: string;
  name?: string;
  isLoading?: boolean;
  id?: string;
}) => {
  const { navigateToPeopleWithCondition } = usePath();

  return (
    <Box className="cell-container flex-end">
      {isLoading ? (
        <Skeleton variant="text" width={100} animation="wave" />
      ) : (
        <Box className="cell-container">
          {isLink ? (
            <Link
              id={id}
              variant="overline"
              onClick={() =>
                navigateToPeopleWithCondition({
                  searchFilter: 'commentReply',
                  search: numType,
                  operator: '1',
                  key: commentID,
                  name,
                })
              }
            >
              <Typography variant="body2" color="bluegrey.700">
                {isUndefined(uniqNum) ? '-' : convertNumberToK(uniqNum)}&nbsp;
              </Typography>
            </Link>
          ) : (
            <Typography variant="body2" color="bluegrey.700">
              {isUndefined(uniqNum) ? '-' : convertNumberToK(uniqNum)}&nbsp;
            </Typography>
          )}

          <Typography variant="body2" color="grey.500">
            {'/'}&nbsp;
            {isUndefined(num) ? '-' : convertNumberToK(num)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StatusText = ({ status }: { status: number }) => {
  const [t] = useTranslation();

  if (status === 2) {
    return (
      <Typography variant="body2" color="success.main">
        {t('commentAutoReply.list.table.active')}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color="grey.500" sx={{ fontStyle: 'italic' }}>
        {t('commentAutoReply.list.table.inactive')}
      </Typography>
    );
  }
};

const PeriodText = ({
  periodType,
  period,
}: {
  periodType: number;
  period?: { startedAt: string; endedAt: string };
}) => {
  const [t] = useTranslation();

  if (periodType === 1) {
    return (
      <Typography variant="body2" color="grey.500">
        {dayjs(period?.startedAt).tz().format('YYYY/MM/DD, HH:mm')} -{' '}
        {dayjs(period?.endedAt).tz().format('YYYY/MM/DD, HH:mm')}
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color="grey.500">
        {t('commentAutoReply.list.table.longTerm')}
      </Typography>
    );
  }
};

export function CommentAutoReplyListDetail({
  replyList,
  isDataLoading = false,
  replyListNumberData,
}: CommentAutoReplyListDetailProps) {
  const [t] = useTranslation();
  const uiState = useContext(UICtx);

  const [commentReplyID, setCommentReplyID] = useState('');
  const [channelID, setChannelID] = useState('');
  const [commentReplyList, setCommentReplyList] = useState<TableTreeItem[]>([]);

  const { navigateToProjectPage } = usePath();
  const { isViewer } = useProjectPermission();
  const { data: detailData } = useGetCommentReplyListDetail(commentReplyID);
  const { data: detailDataNumber } = usePostCommentReplyDetail(
    commentReplyID,
    channelID,
  );

  const numberData = useCallback(
    (id: string) => {
      const specificConditionNumberData = get(
        detailDataNumber,
        id,
        {} as PostCommentReplyDetailType,
      );
      return specificConditionNumberData;
    },
    [detailDataNumber],
  );

  const replyStatus = get(
    replyList.find((item) => item.id === commentReplyID),
    'status',
    0,
  );

  useEffect(() => {
    const tableList = replyList.map((item) => {
      const numberData = replyListNumberData[item.id] || {
        comments: 0,
        uniqComments: 0,
        publicReply: 0,
        uniqPublicReply: 0,
        privateReply: 0,
        uniqPrivateReply: 0,
        conversations: 0,
        uniqConversations: 0,
      };

      return {
        id: item.id,
        layer: 1,
        onClickToggleButton: () => {
          setCommentReplyID(item.id);
        },
        rowItem: [
          {
            class: 'clickable',
            isShowIcon: true,
            element: (
              <>
                {item.status === 3 && (
                  <Tooltip
                    placement="top"
                    title={t('commentAutoReply.list.disconnectTooltip')}
                  >
                    <ReportProblemOutlinedIcon
                      sx={{ color: 'error.main', marginLeft: '6px' }}
                    />
                  </Tooltip>
                )}
                <Typography
                  variant="body2"
                  color="grey.700"
                  sx={{
                    marginLeft: '16px',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigateToProjectPage(
                      `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
                    );
                  }}
                >
                  {item.name}
                </Typography>
              </>
            ),
          },
          {
            class: 'clickable',
            onClick: () =>
              navigateToProjectPage(
                `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
              ),
            element: <StatusText status={item.status} />,
          },
          {
            onClick: () =>
              navigateToProjectPage(
                `/${COMMENT_AUTO_REPLY}/${item.id}/${CONTENT}`,
              ),
            element: (
              <PeriodText period={item.period} periodType={item.periodType} />
            ),
          },
          {
            element: (
              <CommentAutoReplyCellWithData
                commentID={item.id}
                num={numberData.comments}
                uniqNum={numberData.uniqComments}
                isLink
                numType="comments"
                name={item.name}
                isLoading={isDataLoading}
                id="comment_auto_reply_condition_comments"
              />
            ),
          },
          {
            element: (
              <CommentAutoReplyCellWithData
                commentID={item.id}
                num={numberData.publicReply}
                uniqNum={numberData.uniqPublicReply}
                isLink
                numType="publicReply"
                name={item.name}
                isLoading={isDataLoading}
                id="comment_auto_reply_condition_public_reply"
              />
            ),
          },
          {
            element: (
              <CommentAutoReplyCellWithData
                commentID={item.id}
                num={numberData.privateReply}
                uniqNum={numberData.uniqPrivateReply}
                isLink
                numType="privateReply"
                name={item.name}
                isLoading={isDataLoading}
                id="comment_auto_reply_condition_private_reply"
              />
            ),
          },
          {
            element: (
              <CommentAutoReplyCellWithData
                commentID={item.id}
                num={numberData.conversations}
                uniqNum={numberData.uniqConversations}
                isLink
                numType="conversations"
                name={item.name}
                isLoading={isDataLoading}
                id="comment_auto_reply_condition_conversations"
              />
            ),
          },
          {
            class: 'more-btn-column sticky-end',
            element: (
              <Box
                className="cell-container flex-end"
                onClick={(e) => e.stopPropagation()}
              >
                <EllipsisMenu disabled={isViewer}>
                  <MenuItem
                    onClick={() =>
                      uiState.openRenameCommentAutoReplyModal(item.id)
                    }
                    content={t('common.rename')}
                  />
                  <MenuItem
                    onClick={() =>
                      uiState.openDeleteCommentAutoReplyModal(item.id)
                    }
                    content={t('common.delete')}
                  />
                </EllipsisMenu>
              </Box>
            ),
          },
        ],
        items: [],
      };
    });

    setCommentReplyList((prev) => {
      const updateList = tableList.map((item) => {
        if (
          !isEqual(
            JSON.stringify(item),
            JSON.stringify(prev.find((prevItem) => prevItem.id === item.id)),
          )
        ) {
          //更名的時候 rowItem 裡面 element 更新 title 名稱
          const result = {
            ...item,
            ...prev.find((prevItem) => prevItem.id === item.id),
            ...(item.rowItem && { rowItem: item.rowItem }),
          };

          return result;
        }

        return item;
      });

      return updateList;
    });
  }, [
    isDataLoading,
    isViewer,
    navigateToProjectPage,
    replyList,
    replyListNumberData,
    setCommentReplyList,
    t,
    uiState,
  ]);

  // comment reply ID 改變時拿取新的 channel ID
  useEffect(() => {
    setChannelID(detailData?.projectChannelId ?? '');
  }, [detailData?.projectChannelId, commentReplyID]);

  // 拿到新的第二行資料，要放回原本的 commentReplyList
  useEffect(() => {
    if (detailData) {
      const list = detailData.conditions.map((item) => {
        return {
          id: item.id,
          layer: 2,
          rowItem: [
            {
              class: 'grey',
              isShowIcon: true,
              element: (
                <Typography
                  variant="body2"
                  color="grey.700"
                  sx={{
                    marginLeft: '16px',
                    width: '200px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {item.name}
                </Typography>
              ),
            },
            {
              class: 'grey',
            },
            {
              class: 'grey',
            },
            {
              class: 'grey',
              element: (
                <CommentAutoReplyCellWithData
                  num={numberData(item.id)?.comments}
                  uniqNum={numberData(item.id)?.uniqComments}
                  isLoading={isDataLoading}
                  id="comment_auto_reply_post_comments"
                />
              ),
            },
            {
              class: 'grey',
              element: (
                <CommentAutoReplyCellWithData
                  num={numberData(item.id)?.publicReply}
                  uniqNum={numberData(item.id)?.uniqPublicReply}
                  isLoading={isDataLoading}
                  id="comment_auto_reply_post_public_reply"
                />
              ),
            },
            {
              class: 'grey',
              element: (
                <CommentAutoReplyCellWithData
                  num={numberData(item.id)?.privateReply}
                  uniqNum={numberData(item.id)?.uniqPrivateReply}
                  isLoading={isDataLoading}
                  id="comment_auto_reply_post_private_reply"
                />
              ),
            },
            {
              class: 'grey',
              element: (
                <CommentAutoReplyCellWithData
                  num={numberData(item.id)?.conversations}
                  uniqNum={numberData(item.id)?.uniqConversations}
                  isLoading={isDataLoading}
                  id="comment_auto_reply_post_conversations"
                />
              ),
            },
            {
              class: 'grey',
            },
          ],
          items: detailData.posts
            ? Object.entries(detailData.posts).map(([key, _post]) => {
                return {
                  id: _post.id,
                  layer: 3,
                  title: _post.message,
                  isShowMask: replyStatus === 3,
                  warningText: t('commentAutoReply.list.disconnectPostText'),
                  postAmount: Object.keys(detailData.posts).length,
                  rowItem: [
                    {
                      class: 'grey',
                      isShowIcon: false,
                      element: (
                        <>
                          {_post?.fullPicture ? (
                            <ImageContainerStyled
                              sx={{
                                backgroundImage: `url(${_post?.fullPicture})`,
                              }}
                            />
                          ) : (
                            <ImageContainerStyled>
                              <DefaultPostIcon />
                            </ImageContainerStyled>
                          )}
                          <Typography
                            variant="body2"
                            color="grey.700"
                            sx={{
                              marginLeft: '16px',
                              width: '200px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {_post.message}
                          </Typography>
                        </>
                      ),
                    },
                    {
                      class: 'grey',
                    },
                    {
                      class: 'grey',
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          num={
                            get(detailDataNumber, 'post[_post.id]')?.comments
                          }
                          uniqNum={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.uniqComments
                          }
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          num={
                            get(detailDataNumber, 'post[_post.id]')?.publicReply
                          }
                          uniqNum={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.uniqPublicReply
                          }
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          num={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.privateReply
                          }
                          uniqNum={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.uniqPrivateReply
                          }
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    {
                      class: 'grey',
                      element: (
                        <CommentAutoReplyCellWithData
                          num={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.conversations
                          }
                          uniqNum={
                            get(detailDataNumber, 'post[_post.id]')
                              ?.uniqConversations
                          }
                          isLoading={isDataLoading}
                        />
                      ),
                    },
                    { class: 'grey' },
                  ],
                };
              })
            : [],
        };
      });

      setCommentReplyList((prev) => {
        const updatedData = prev.find((item) => item.id === commentReplyID);

        if (updatedData) {
          return [
            ...prev.slice(0, prev.indexOf(updatedData)),
            { ...updatedData, items: list },
            ...prev.slice(prev.indexOf(updatedData) + 1),
          ];
        }

        return prev;
      });
    }
  }, [
    commentReplyID,
    detailData,
    detailDataNumber,
    isDataLoading,
    numberData,
    replyStatus,
    setCommentReplyList,
    t,
  ]);

  return <TableTree tableList={commentReplyList} />;
}

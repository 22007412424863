import { SocialTypeEnum } from './Global.interface';

export enum PaymentStatus {
  NORMAL = 1,
  ABNORMAL = 2,
}

export enum ProjectStep {
  BETA_END = 1,
  TRIAL_END = 2,
  NONE = 3,
}

export enum ProjectStatus {
  TRIAL = 1,
  ADVANCE = 2,
  VIEW_ONLY = 3,
  BETA = 4,
}

export interface Project {
  projectId: string;
  projectName: string;
  picture?: string;
  isLinked: boolean;
  shortCode: string;
  timezone: string;
  timezoneOffset: number;
  paymentStatus: PaymentStatus;
  step: ProjectStep;
  status: ProjectStatus;
  expiryDate?: string;
  permission: boolean;
  socialType: SocialTypeEnum;
  // threads 渠道 customer 免費人數上限
  trialLimit?: number;
}

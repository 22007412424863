import {
  DashboardSourceChannelChartData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_SOURCE_CHANNEL_CHART } from '../apis';

const defaultValue = [] as DashboardSourceChannelChartData[];

async function postDataDashboardSourceChannelChart(
  projectID: string,
  params: PostDataDashboardProps,
): Promise<DashboardSourceChannelChartData[]> {
  return axios
    .post(
      `${DATA_DASHBOARD_SOURCE_CHANNEL_CHART}?projectId=${projectID}`,
      params,
    )
    .then((res) => res.data.data)
    .catch((err) => defaultValue);
}

export function usePostDataDashboardSourceChannelChart(projectID: string) {
  return useMutation({
    mutationKey: ['post-data-dashboard-source-channel-chart'],
    mutationFn: (props: PostDataDashboardProps) => {
      return postDataDashboardSourceChannelChart(projectID, props);
    },
  });
}

export default usePostDataDashboardSourceChannelChart;

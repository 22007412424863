import { ProjectLinked } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { PROJECT_LINKED } from '../apis';

async function getProjectLinked(id: string): Promise<ProjectLinked> {
  return axios.get(`${PROJECT_LINKED}/${id}`).then((res) => res.data.data);
}

export function useProjectLinked(id?: string) {
  const { projectID } = useParams();

  return useQuery<ProjectLinked | null>({
    queryKey: ['project-linked'],
    queryFn: () => {
      if (projectID) {
        return getProjectLinked(projectID);
      } else {
        return null;
      }
    },
  });
}

export default useProjectLinked;

import { ChartDataType } from '@frontend/components/interface';
import { ChartLine } from '@frontend/components/ui';
import { CHART_DATA_EMPTY_KEY_PREFIX } from '@frontend/components/utils';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostDataDashboardSourceFlowChart } from '@frontend/sorghum/data-access';
import {
  DashboardSourceFlowChartData,
  PostDataDashboardProps,
} from '@frontend/sorghum/interface';
import { dayjs } from '@frontend/sorghum/utils';

import { set } from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const DataDashboardSourceFlowChart: FC<PostDataDashboardProps> = ({
  startDate,
  endDate,
  condition,
  period,
}: PostDataDashboardProps) => {
  const [t] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const [chartData, setChartData] = useState<ChartDataType[]>([]);
  const { mutate: queryData, isLoading } = usePostDataDashboardSourceFlowChart(
    projectID as string,
  );

  const currentLocale = dayjs().locale();

  const onSuccess = useCallback(
    (data: DashboardSourceFlowChartData[]) => {
      setChartData(
        data.map((i) => {
          let newName = '';
          switch (period) {
            case 1: {
              newName = dayjs(i.startDate).format('MM/DD');
              break;
            }
            case 2: {
              newName =
                dayjs(i.startDate).format('MM/DD') +
                ' - ' +
                dayjs(i.endDate).format('MM/DD');
              break;
            }
            case 3: {
              newName = dayjs(i.startDate).format('MMM');
              break;
            }
          }
          const chartData = {
            name: newName,
          };
          i.flows.forEach((item, index) => {
            if (item.flowName) {
              set(chartData, item.flowName, item.count);
            } else {
              set(
                chartData,
                `${CHART_DATA_EMPTY_KEY_PREFIX}${index}`,
                item.count,
              );
            }
          });

          return chartData;
        }),
      );
    },
    [period],
  );

  const handleQuery = useCallback(() => {
    if (startDate && endDate && projectID && period) {
      queryData(
        { startDate, endDate, period, condition },
        {
          onSuccess: onSuccess,
        },
      );
    }
  }, [condition, endDate, onSuccess, period, projectID, queryData, startDate]);

  useEffect(handleQuery, [handleQuery, currentLocale]);

  return (
    <ChartLine
      data={chartData}
      title={t('dataDashboard.numberOfUniqueUsers')}
      emptyDisplayName={t('dataDashboard.deletedFlow')}
      isLoading={isLoading}
    />
  );
};

export default DataDashboardSourceFlowChart;

import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PageContainerStyled = styled(Box)(({ theme }) => ({
  padding: '32px',
  background: '#e5e5e5',
  minHeight: '100%',
  width: '100%',
}));

export const RightContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const PaymentErrorWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  background: theme.palette['background']['error'][5],
  padding: '12px 16px',
  gap: '8px',
  borderRadius: '4px',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '9.5px',
  },
}));

export const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '36px 40px',
  gap: '16px',

  '&.members': {
    maxHeight: '360px',
  },
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
}));

export const HeadShotContainerStyled = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  backgroundColor: theme.palette['grey'][200],
  marginRight: '16px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export const ItemsContainerStyled = styled(Box)(({ theme }) => ({
  overflow: 'auto',
}));

export const ItemContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '16px',

  '.left-side-items': {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const ItemInfoContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '.name': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
}));

export const InviteInfoContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '20px',

  '.input-field': {
    width: 'calc(100% - 80px)',
  },

  '& input': {
    color: theme.palette['grey'][400],
  },
}));

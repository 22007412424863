import { BillingPlan, SocialTypeEnum } from '@frontend/sorghum/interface';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { BILLING_PLAN } from '../apis';

const defaultValue: BillingPlan[] = [];

async function getBillingPlan(
  socialType: SocialTypeEnum,
): Promise<BillingPlan[]> {
  return axios
    .get(`${BILLING_PLAN}`, {
      params: {
        socialType,
      },
    })
    .then((res) => res.data.data)
    .catch((err) => {
      return defaultValue;
    });
}

export function useGetBillingPlan(socialType: SocialTypeEnum | undefined) {
  return useQuery<BillingPlan[]>({
    queryKey: ['get-billing-plan', { socialType }],
    queryFn: () => {
      if (socialType) {
        return getBillingPlan(socialType);
      } else {
        return defaultValue;
      }
    },
  });
}

import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const MicroContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: '48px 0 80px 0',

  [theme.breakpoints.down('md')]: {
    padding: '40px 0 0 0',
  },
}));

export const FormContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    height: 'calc(100vh - 80px)',
    paddingBottom: '12px',
  },
}));

export const InputFieldContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
}));

export const SelectContainerStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '24px',
}));

export const TitleContainerStyled = styled(Box)(({ theme }) => ({
  marginBottom: '8px',

  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

export const DescriptionContainerStyled = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
}));

export const FormInnerContainerStyled = styled(Box)(({ theme }) => ({
  // height: 'calc(100% - 40px)',
  // overflowY: 'scroll',
}));

export const BottomButtonContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
}));

export const PhoneInputContainerStyled = styled(Box)<{ $isError: boolean }>(
  ({ theme, $isError }) => ({
    position: 'relative',
    paddingBottom: '8px',
    '.phone-input-label': {
      position: 'absolute',
      zIndex: 1,
      left: '14px',
      padding: '0 6px',
      top: '-8px',
      backgroundColor: `${theme.palette['grey']['white']}`,
      marginLeft: '-4px',
      color: $isError
        ? theme.palette['error']['main']
        : theme.palette['grey']['500'],
    },

    '&.focused .phone-input-label': {
      color: $isError
        ? theme.palette['error']['main']
        : theme.palette['primary']['main'],
    },

    '.phone-input-helper-text': {
      marginTop: '5px',
    },
  }),
);

export const PhoneInputWrapperStyled = styled(Box)<{ $isError: boolean }>(
  ({ theme, $isError }) => ({
    width: '100%',

    '& .react-tel-input': {
      height: '40px',
    },

    '& .flag-dropdown': {
      backgroundColor: `${theme.palette['grey']['white']} !important`,
      borderRight: '0 !important',
      border: $isError
        ? `1px solid ${theme.palette['error']['main']}`
        : `1px solid ${theme.palette['grey']['500']}`,

      '&:hover': {
        border: $isError
          ? `1px solid ${theme.palette['error']['main']}`
          : '1px solid rgb(0, 0, 0, 0.87)',
      },

      '&:hover + .form-control': {
        border: $isError
          ? `1px solid ${theme.palette['error']['main']}`
          : '1px solid rgb(0, 0, 0, 0.87)',
      },
    },

    '& .react-tel-input .form-control': {
      height: '100%',
      width: '100%',
      border: $isError
        ? `1px solid ${theme.palette['error']['main']}`
        : `1px solid ${theme.palette['grey']['500']}`,
      borderRadius: '4px',

      '&:hover': {
        border: $isError
          ? `1px solid ${theme.palette['error']['main']}`
          : '1px solid rgb(0, 0, 0, 0.87)',
      },

      '&:hover + .flag-dropdown': {
        border: $isError
          ? `1px solid ${theme.palette['error']['main']}`
          : '1px solid rgb(0, 0, 0, 0.87)',
      },

      '&:focus, &:active': {
        borderWidth: '2px',
        borderColor: $isError
          ? theme.palette['error']['main']
          : theme.palette['primary']['main'],

        '+ .flag-dropdown': {
          borderWidth: '2px',
          borderColor: $isError
            ? theme.palette['error']['main']
            : theme.palette['primary']['main'],
        },
      },
    },
  }),
);

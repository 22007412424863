import { Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/sorghum/external-providers';
import { ModalTypesEnum } from '@frontend/sorghum/interface';
import { PAGE_UPGRADE } from '@frontend/sorghum/utils';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

export function UpgradeProjectModal() {
  const [t] = useTranslation();
  const { modalType, closeModal, currentProjectSocialType } = useContext(UICtx);

  const handleConfirm = useCallback(() => {
    closeModal();
    window.open(
      window.location.origin +
        PAGE_UPGRADE +
        `?social_type=${currentProjectSocialType}`,
      '_blank',
    );
  }, [closeModal, currentProjectSocialType]);

  return (
    <Dialog
      size="xs"
      open={modalType === ModalTypesEnum.UPGRADE_PROJECT}
      title={t('flows.leftSidebar.upgradeProjectModal.title')}
      content={t('flows.leftSidebar.upgradeProjectModal.content')}
      handleClose={closeModal}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('flows.leftSidebar.upgradeProjectModal.upgrade')}
      handleConfirm={handleConfirm}
      confirmButtonID="add_more_project_upgrade"
    />
  );
}

export default UpgradeProjectModal;

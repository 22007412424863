import { queryClient } from '@frontend/sorghum/external-providers';
import { Response } from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { FLOW } from '../apis';

interface Props {
  projectId: string;
  flowID: string;
  chart?: any;
  name: string;
}

async function patchEditorFlow(props: Props): Promise<Response> {
  return axios
    .patch(`${FLOW}?flowId=${props.flowID}&projectId=${props.projectId}`, {
      name: props.name,
    })
    .then((res) => res.data)
    .catch((err) => ({
      code: 400,
      msg: err,
    }));
}

export function usePatchEditorFlow() {
  return useMutation({
    mutationKey: ['patch-editor-flow'],
    mutationFn: (props: Props) => patchEditorFlow(props),
    onSuccess: (data, variables) => {
      if (data.code === 20000) {
        queryClient.invalidateQueries(['flow-list']);
        queryClient.invalidateQueries([
          'flow',
          { flowID: variables.flowID, projectID: variables.projectId },
        ]);
      }
    },
  });
}

export default usePatchEditorFlow;

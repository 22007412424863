import {
  TableTreeItem,
  TableTreeProps,
  TableTreeRowItem,
} from '@frontend/components/interface';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Box, TableCell, TableRow, styled } from '@mui/material';
import { isArray } from 'lodash';
import { Fragment, useState } from 'react';
import Typography from '../typography/typography';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  color: theme.palette['bluegrey'][700],
  '.cell-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  '.flex-end': {
    justifyContent: 'flex-end',
  },

  '.second': {
    paddingLeft: '24px',
  },

  '&.clickable': {
    cursor: 'pointer',
  },

  '&.grey': {
    backgroundColor: theme.palette['grey'][50],
  },
}));

const TableRowMaskStyled = styled(TableRow)<{ $height: number }>(
  ({ theme, $height }) => ({
    position: 'absolute',
    width: '100%',
    zIndex: '5',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: `${$height}px`,
    marginTop: '-65px',
  }),
);

interface LayerState {
  [id: string]: boolean;
}

export function TableTree({ tableList }: TableTreeProps) {
  const [layerOpenState, setLayerOpenState] = useState<LayerState>({});

  const toggleLayer = (id: string | number) => {
    setLayerOpenState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderTableRows = (items: TableTreeItem[]) => {
    return items.map((item: TableTreeItem) => (
      <Fragment key={`${item.layer}_${item.id}`}>
        <TableRow
          onClick={() => {
            toggleLayer(item.id);
          }}
          id={typeof item.id === 'string' ? item.id : `${item.id}`}
        >
          {item.rowItem.map((_rowItem: TableTreeRowItem, index: number) => {
            if (index === 0) {
              return (
                <TableCellStyled
                  key={`${item.layer}_${index}`}
                  className={_rowItem.class}
                  onClick={_rowItem.onClick}
                >
                  <Box
                    className="cell-container"
                    sx={{ paddingLeft: `${(item.layer - 1) * 24}px` }}
                  >
                    {_rowItem.isShowIcon && (
                      <ArrowDropDownOutlinedIcon
                        onClick={() => {
                          if (item.onClickToggleButton) {
                            item.onClickToggleButton();
                          }
                        }}
                        sx={{
                          transform: layerOpenState[item.id]
                            ? 'rotate(180deg)'
                            : 'rotate(0deg)',
                        }}
                      />
                    )}
                    {_rowItem.element}
                  </Box>
                </TableCellStyled>
              );
            }
            return (
              <TableCellStyled
                key={`${item.layer}_${index}`}
                onClick={_rowItem.onClick}
                className={_rowItem.class}
              >
                {_rowItem.element && _rowItem.element}
              </TableCellStyled>
            );
          })}
        </TableRow>
        {item.isShowMask && (
          <TableRowMaskStyled
            $height={item?.postAmount ? item?.postAmount * 65 : 65}
          >
            <Typography variant="body1" color="grey.700" component="td">
              {item.warningText ?? ''}
            </Typography>
          </TableRowMaskStyled>
        )}
        {isArray(item.items) &&
          layerOpenState[item.id] &&
          renderTableRows(item.items)}
      </Fragment>
    ));
  };

  return <>{renderTableRows(tableList)}</>;
}

import { Dialog } from '@frontend/components/ui';
import { UICtx } from '@frontend/editor/external-providers';
import { ModalTypesEnum } from '@frontend/editor/interface';
import { dayjs } from '@frontend/sorghum/utils';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CommentAutoReplyDateModalProps {
  timezoneOffset: number;
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  handleStartSelect: (newValue: dayjs.Dayjs | null) => void;
  handleEndSelect: (newValue: dayjs.Dayjs | null) => void;
}

const ModalContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

const DatePickerWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  width: '100%',
  paddingTop: '16px',
  gap: '24px',

  '& > div': {
    maxWidth: '360px',
  },
}));

export const ThreadsCommentReplyDateModal: FC<
  CommentAutoReplyDateModalProps
> = ({
  timezoneOffset,
  startDate,
  endDate,
  handleStartSelect,
  handleEndSelect,
}) => {
  const [t] = useTranslation();
  const [threads] = useTranslation('threads');

  const { closeModal, modalType } = useContext(UICtx);

  const startDateError = useMemo(() => {
    if (!startDate)
      return t('commentAutoReply.content.secondStep.startDateEmpty');
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        return t('commentAutoReply.content.secondStep.startDateBeforeEndDate');
      }
    }
    return '';
  }, [startDate, endDate, t]);

  const endDateError = useMemo(() => {
    if (!endDate) return t('commentAutoReply.content.secondStep.endDateEmpty');
    if (startDate && endDate) {
      if (endDate.isBefore(startDate)) {
        return t('commentAutoReply.content.secondStep.endDateAfterStartDate');
      }
    }
    return '';
  }, [startDate, endDate, t]);

  const handleConfirm = useCallback(() => {
    handleStartSelect(startDate);
    handleEndSelect(endDate);
    closeModal();
  }, [handleStartSelect, handleEndSelect, startDate, endDate, closeModal]);

  return (
    <Dialog
      size="s"
      title={threads('commentAutoReply.drawer.replyPeriod.modal.title')}
      confirmBtnText={t('common.done')}
      cancelBtnText={t('common.cancel')}
      open={modalType === ModalTypesEnum.THREADS_COMMENT_REPLY_DATE}
      handleClose={closeModal}
      handleConfirm={handleConfirm}
      disableConfirmButton={!startDate || !endDate}
    >
      <ModalContainerStyled>
        <Typography variant="body2" color="grey.900">
          {t('commentAutoReply.content.secondStep.subtitle1', {
            timezone: `${timezoneOffset >= 0 && '+'} ${timezoneOffset}`,
          })}
        </Typography>
        <DatePickerWrapperStyled>
          <DatePicker
            label={t('commentAutoReply.content.secondStep.startDateLabel')}
            disablePast
            value={startDate}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                handleStartSelect(newValue);
              } else {
                handleStartSelect(null);
              }
            }}
            minDate={dayjs()}
            maxDate={endDate ? endDate : dayjs().add(1, 'year')}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
                error: !!startDateError,
                helperText: startDateError,
              },
            }}
          />
          <TimePicker
            label={t('commentAutoReply.content.secondStep.startTimeLabel')}
            value={startDate || dayjs('12:00', 'HH:mm')}
            maxTime={
              startDate && endDate && startDate.diff(endDate) === 0
                ? endDate
                : dayjs('23:59', 'HH:mm')
            }
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                handleStartSelect(newValue);
              }
            }}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
              },
            }}
          />
        </DatePickerWrapperStyled>
        <DatePickerWrapperStyled>
          <DatePicker
            label={t('commentAutoReply.content.secondStep.endDateLabel')}
            disablePast
            value={endDate}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                handleEndSelect(newValue);
              } else {
                handleEndSelect(null);
              }
            }}
            minDate={startDate ? startDate : dayjs()}
            maxDate={dayjs().add(1, 'year')}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
                error: !!endDateError,
                helperText: endDateError,
              },
            }}
          />
          <TimePicker
            label={t('commentAutoReply.content.secondStep.endTimeLabel')}
            minTime={
              startDate && endDate && startDate.diff(endDate) === 0
                ? startDate
                : dayjs('00:00', 'HH:mm')
            }
            value={endDate || dayjs('12:00', 'HH:mm')}
            onChange={(newValue: dayjs.Dayjs | null) => {
              if (newValue) {
                handleEndSelect(newValue);
              }
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                required: true,
              },
            }}
          />
        </DatePickerWrapperStyled>
      </ModalContainerStyled>
    </Dialog>
  );
};

export default ThreadsCommentReplyDateModal;

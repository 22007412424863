import { Dialog, VideoModal } from '@frontend/components/ui';
import {
  useGetProjectID,
  useProjectPermission,
} from '@frontend/editor/data-access';
import { EditorCtx } from '@frontend/editor/external-providers';
import {
  useGetAccountInfo,
  useGetProject,
  usePostAccountTutorial,
} from '@frontend/sorghum/data-access';
import { NotificationCtx, UICtx } from '@frontend/sorghum/external-providers';
import {
  AccountPermission,
  LeftSideItem,
  ModalTypesEnum,
  NotificationTypeEnum,
  ProjectStep,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import {
  CommentAutoReplyOnboardingWelcomeModal,
  CopyFlowModal,
  DeleteCommentAutoReplyModal,
  PaymentSurveyModal,
  RenameBroadcastModal,
  RenameCommentAutoReplyModal,
  RenameCouponModal,
  RenameFlowModal,
  RenameSequenceModal,
} from '@frontend/sorghum/ui';
import {
  BROADCAST,
  COMMENT_AUTO_REPLY,
  DASHBOARD,
  FACEBOOK_TUTORIAL_EN,
  FACEBOOK_TUTORIAL_ZH,
  FLOW_ENTRY,
  PAGE_BROADCAST,
  PAGE_COMMENT_AUTO_REPLY_LIST,
  PAGE_DASHBOARD,
  PAGE_FLOW,
  PAGE_FLOW_ENTRY,
  PAGE_FLOW_VIEW,
  PAGE_PEOPLE,
  PAGE_SETTING,
  PAGE_TOUR_ONBOARDING,
  PEOPLE,
  SETTING,
  usePath,
} from '@frontend/sorghum/utils';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as BotIcon } from '../images/icon-bot.svg';

export const useMessenger = (googleStorageEndpoint: string) => {
  const [t, i18next] = useTranslation();
  const {
    flowID,
    name,
    modalData,
    title,
    content,
    confirm,
    cancel,
    isUpdated,
    modalType,
    closeModal,
    openModal,
    openNavigateModal,
    setIsUpdated,
  } = useContext(UICtx);
  const notificationState = useContext(NotificationCtx);
  const editorState = useContext(EditorCtx);
  const { pathname } = useLocation();
  const { isViewer } = useProjectPermission();
  const { navigateToProjectPage } = usePath();
  const { data: account } = useGetAccountInfo();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { mutate: updateTutorial } = usePostAccountTutorial();

  const handleModalConfirm = useCallback(() => {
    if (modalData.onConfirm) {
      modalData.onConfirm();
    }
    closeModal();
  }, [closeModal, modalData]);

  const beforeUnload = useCallback(
    (handleConfirm: () => void) => {
      if (isViewer) {
        handleConfirm();
        return;
      }
      if (pathname.includes(PAGE_FLOW) && !pathname.includes(PAGE_FLOW_VIEW)) {
        const isUpdated = editorState.checkDataUpdated();
        if (isUpdated) {
          openNavigateModal(handleConfirm);
        } else {
          handleConfirm();
        }
      } else if (isUpdated) {
        openNavigateModal(handleConfirm);
        setIsUpdated(false);
      } else {
        handleConfirm();
      }
    },
    [
      editorState,
      isUpdated,
      isViewer,
      openNavigateModal,
      pathname,
      setIsUpdated,
    ],
  );

  const redirectTo = useCallback(
    (page?: string) => {
      beforeUnload(() => navigateToProjectPage(page));
    },
    [beforeUnload, navigateToProjectPage],
  );

  const handleCanvasTutorialInteract = useCallback(() => {
    updateTutorial({
      marketingExpert: {
        canvasTutorial: {
          hasInteracted: true,
        },
      },
    });
  }, [updateTutorial]);

  const gotoTutorial = useCallback(
    () =>
      window.open(
        i18next.language.includes('en')
          ? FACEBOOK_TUTORIAL_EN
          : FACEBOOK_TUTORIAL_ZH,
        '_blank',
      ),
    [i18next.language],
  );

  const leftSideItems = useMemo<LeftSideItem[]>(
    () => [
      {
        id: 'side_automated',
        label: t('leftSidebar.basic'),
        value: FLOW_ENTRY,
        Icon: () => <BotIcon />,
        onClick: () => {
          redirectTo(PAGE_FLOW_ENTRY);
        },
      },
      {
        id: 'side_comment_auto_reply',
        label: t('flows.leftSidebar.comment'),
        value: COMMENT_AUTO_REPLY,
        Icon: () => <ReplyAllIcon />,
        onClick: () => redirectTo(PAGE_COMMENT_AUTO_REPLY_LIST),
      },
      {
        id: 'side_customer',
        label: t('leftSidebar.people'),
        value: PEOPLE,
        Icon: () => <PeopleOutlinedIcon />,
        onClick: () => redirectTo(PAGE_PEOPLE),
      },
      {
        id: 'side_broadcast',
        label: t('leftSidebar.broadcast'),
        value: BROADCAST,
        alert:
          notificationState.noticeBarType ===
          NotificationTypeEnum.NEW_RN_RECEIVER,
        Icon: () => <CampaignOutlinedIcon />,
        onClick: () => {
          redirectTo(PAGE_BROADCAST);
        },
      },
      {
        id: 'side_dashboard',
        label: t('leftSidebar.dataDashboard'),
        value: DASHBOARD,
        Icon: () => <InsertChartOutlinedIcon />,
        onClick: () => {
          redirectTo(PAGE_DASHBOARD);
        },
      },
      {
        id: 'side_setting',
        label: t('leftSidebar.setting'),
        value: SETTING,
        alert:
          notificationState.noticeBarType ===
          NotificationTypeEnum.PERMISSION_CHANGED,
        alertType: 'error',
        Icon: () => <SettingsOutlinedIcon />,
        onClick: () => {
          redirectTo(PAGE_SETTING);
        },
      },
    ],
    [t, notificationState.noticeBarType, redirectTo],
  );

  const modal = useMemo(() => {
    switch (modalType) {
      case ModalTypesEnum.RENAME_FLOW: {
        return <RenameFlowModal />;
      }
      case ModalTypesEnum.RENAME_SEQUENCE: {
        return <RenameSequenceModal />;
      }
      case ModalTypesEnum.RENAME_BROADCAST: {
        return <RenameBroadcastModal />;
      }

      case ModalTypesEnum.UNSUBSCRIBE_PAYMENT:
      case ModalTypesEnum.TRIAL_END:
      case ModalTypesEnum.OFFICIAL_VERSION_LAUNCHED: {
        return <PaymentSurveyModal socialType={SocialTypeEnum.FACEBOOK} />;
      }
      case ModalTypesEnum.RENAME_COUPON: {
        return <RenameCouponModal />;
      }
      /* TODO: 之後可以移動到對應的頁面內 */
      case ModalTypesEnum.RENAME_COMMENT_AUTO_REPLY: {
        return <RenameCommentAutoReplyModal />;
      }
      case ModalTypesEnum.DELETE_COMMENT_AUTO_REPLY: {
        return <DeleteCommentAutoReplyModal />;
      }
      case ModalTypesEnum.COPY_FLOW: {
        return (
          <CopyFlowModal
            projectId={projectID as string}
            flowID={flowID}
            flowName={name}
            open={modalType === ModalTypesEnum.COPY_FLOW}
            handleClose={closeModal}
          />
        );
      }
      /* level 1 step 1 mission modal */
      case ModalTypesEnum.LEVEL1_LESSON1: {
        return (
          <VideoModal
            size="m"
            open={modalType === ModalTypesEnum.LEVEL1_LESSON1}
            handleClose={() => {
              closeModal();
              handleCanvasTutorialInteract();
            }}
            title={t('tour.modal.learnCanvas.title')}
            content={t('tour.modal.learnCanvas.description')}
            lottieJsonUrl={`${googleStorageEndpoint}/static/video/onboarding/mission-01${
              i18next.language === 'en_us' ? '-en' : ''
            }.json`}
            cancelButtonText={t('tour.modal.learnCanvas.later')}
            confirmButtonText={t('tour.modal.learnCanvas.confirm')}
            onConfirm={() => {
              handleCanvasTutorialInteract();
              navigateToProjectPage(PAGE_TOUR_ONBOARDING);
              closeModal();
            }}
            confirmButtonID="level1_mission1_start_tutorial"
          />
        );
      }
      /* 共用的 modal */
      case ModalTypesEnum.COMMON: {
        return (
          <Dialog
            size="xs"
            title={title}
            content={content}
            open={true}
            handleClose={closeModal}
            cancelBtnText={cancel}
            confirmBtnText={confirm}
            color={modalData.color}
            handleConfirm={handleModalConfirm}
          />
        );
      }
      /* comment reply onboarding welcome modal */
      case ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_START:
      case ModalTypesEnum.COMMENT_AUTO_REPLY_ONBOARDING_WELCOME_FINISH: {
        return (
          <CommentAutoReplyOnboardingWelcomeModal
            googleStorageEndpoint={googleStorageEndpoint}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    cancel,
    closeModal,
    confirm,
    content,
    flowID,
    googleStorageEndpoint,
    handleCanvasTutorialInteract,
    handleModalConfirm,
    i18next.language,
    modalType,
    modalData.color,
    name,
    navigateToProjectPage,
    projectID,
    t,
    title,
  ]);

  // 進入專案後要自動開啟對應狀態的 modal
  useEffect(() => {
    if (project?.step === ProjectStep.BETA_END) {
      openModal(ModalTypesEnum.OFFICIAL_VERSION_LAUNCHED);
    } else if (
      project?.step === ProjectStep.TRIAL_END &&
      // role = viewer 時無法升級專案所以也看不到這個 modal
      account?.permission !== AccountPermission.VIEWER
    ) {
      openModal(ModalTypesEnum.TRIAL_END);
    }
  }, [project?.step, account?.permission, openModal]);

  return { modal, leftSideItems, redirectTo, gotoTutorial };
};

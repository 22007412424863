import { Dialog, Typography } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { usePostEditorFlow } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  PostEditorFlowResponse,
  ResponseWithData,
} from '@frontend/sorghum/interface';
import {
  PAGE_AI_GUIDED,
  PAGE_COMMENT_AUTO_REPLY_LIST,
  PAGE_FLOW,
  sendGAEvent,
  usePath,
} from '@frontend/sorghum/utils';
import { Box, styled } from '@mui/material';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Block1 from './block1.png';
import Block2 from './block2.png';
import Block3 from './block3.png';

const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  padding: '32px 24px',
  background: theme.palette['grey'][100],
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
}));

const CardWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '200px',
  borderRadius: '12px',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const ItemWrapperStyled = styled(Box)(({ theme }) => ({
  height: '100px',
  width: '200px',
  padding: '8px 16px',
  background: theme.palette['grey']['white'],
}));

export const StartWithModalModal = () => {
  const [t] = useTranslation();
  const {
    modalType,
    closeModal,
    setTour,
    setIsTouring,
    handleSetID,
    openModal,
  } = useContext(UICtx);
  const { navigateToProjectPage } = usePath();
  const { data: projectID } = useGetProjectID();

  const { mutate: createFlow } = usePostEditorFlow(projectID as string, 1, {
    onSuccess: (data: ResponseWithData<PostEditorFlowResponse>) => {
      closeModal();
      if (data.data.flowId) {
        setTimeout(() => {
          setTour([
            {
              targets: [],
              highlight: `table-row-${data.data.flowId}`,
              highlightType: 'dropdown',
            },
          ]);
          setIsTouring(true);
        }, 300);
        setTimeout(() => {
          setIsTouring(false);
          handleSetID(data.data.flowId);
          navigateToProjectPage(`${PAGE_FLOW}/${data.data.flowId}`);
          openModal(ModalTypesEnum.RENAME_FLOW);
        }, 2000);
      }
    },
  });

  const handleCreateFlow = useCallback(() => {
    sendGAEvent(
      'Automated Messaging',
      'Create path selection',
      'Automate - Create btn - start from blank canvas - click',
      '',
    );
    const newName = t('flows.leftSidebar.flowDefaultName');

    createFlow({ name: newName });
  }, [t, createFlow]);

  const handleTemplateClick = useCallback(() => {
    sendGAEvent(
      'Automated Messaging',
      'Create path selection',
      'Automate - Create btn - start from AI recommended template - click',
      '',
    );
    navigateToProjectPage(PAGE_AI_GUIDED);
  }, [navigateToProjectPage]);

  const handleCommentReplyClick = useCallback(() => {
    sendGAEvent(
      'Automated Messaging',
      'Create path selection',
      'Automate - Create btn - CommentAutoReply - click',
      '',
    );
    navigateToProjectPage(PAGE_COMMENT_AUTO_REPLY_LIST);
  }, [navigateToProjectPage]);

  return (
    <Dialog
      size="m"
      title={t('modal.startWith.title')}
      open={modalType === ModalTypesEnum.START_WITH}
      handleClose={closeModal}
    >
      <ContentWrapperStyled>
        <CardWrapperStyled onClick={handleCreateFlow}>
          <img src={Block1} alt="" width="200" height="120" />
          <ItemWrapperStyled>
            <Typography variant="subtitle2" color="grey.900">
              {t('modal.startWith.block1.title')}
            </Typography>
            <Typography variant="note" color="bluegrey.400">
              {t('modal.startWith.block1.content')}
            </Typography>
          </ItemWrapperStyled>
        </CardWrapperStyled>
        <CardWrapperStyled onClick={handleTemplateClick}>
          <img src={Block2} alt="" width="200" height="120" />
          <ItemWrapperStyled>
            <Typography variant="subtitle2" color="grey.900">
              {t('modal.startWith.block2.title')}
            </Typography>
            <Typography variant="note" color="bluegrey.400">
              {t('modal.startWith.block2.content')}
            </Typography>
          </ItemWrapperStyled>
        </CardWrapperStyled>
        <CardWrapperStyled onClick={handleCommentReplyClick}>
          <img src={Block3} alt="" width="200" height="120" />
          <ItemWrapperStyled>
            <Typography variant="subtitle2" color="grey.900">
              {t('modal.startWith.block3.title')}
            </Typography>
            <Typography variant="note" color="bluegrey.400">
              {t('modal.startWith.block3.content')}
            </Typography>
          </ItemWrapperStyled>
        </CardWrapperStyled>
      </ContentWrapperStyled>
    </Dialog>
  );
};

export default StartWithModalModal;

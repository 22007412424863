import {
  Color,
  CssBaseline,
  PaletteColorOptions,
  ThemeOptions,
} from '@mui/material';
import { ThemeProvider as Provider, createTheme } from '@mui/material/styles';
import { FC, PropsWithChildren } from 'react';

declare module '@mui/material' {
  interface Color {
    white: React.CSSProperties['color'];
    black: React.CSSProperties['color'];
    dark: React.CSSProperties['color'];
    light: React.CSSProperties['color'];
    main: React.CSSProperties['color'];
  }
}

interface CustomColor extends Color {
  750: React.CSSProperties['color'];
}

interface BackgroundColor {
  5: string;
  10: string;
  15?: string;
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    s: true;
    m: true;
    md: true;
    lg: true;
    xl: true;
    tablet: false;
    laptop: false;
    desktop: false;
  }
  interface Palette {
    blue: PaletteColorOptions;
    bluegrey: Partial<CustomColor>;
    purple: Partial<Color>;
    green: Partial<Color>;
    grass: Partial<Color>;
    orange: Partial<Color>;
    pink: Partial<Color>;
    brown: Partial<Color>;
    bluegrey500: PaletteColorOptions;
    bluegrey300: PaletteColorOptions;
    white: PaletteColorOptions;
    infoOnTooltips: PaletteColorOptions;
  }
  interface PaletteOptions {
    blue: Partial<Color>;
    bluegrey: Partial<CustomColor>;
    purple: Partial<Color>;
    green: Partial<Color>;
    grass: Partial<Color>;
    orange: Partial<Color>;
    pink: Partial<Color>;
    brown: Partial<Color>;
    bluegrey500?: PaletteColorOptions;
    bluegrey300?: PaletteColorOptions;
    white?: PaletteColorOptions;
    infoOnTooltips: PaletteColorOptions;
    chart: string[];
  }

  interface TypographyVariants {
    body3: React.CSSProperties;
    note: React.CSSProperties;
    overline: React.CSSProperties;
    overlineBold: React.CSSProperties;
    captionItalic: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    notoSans: React.CSSProperties;
    menuItem: React.CSSProperties;
    bannerTitle: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
    note: React.CSSProperties;
    overline: React.CSSProperties;
    overlineBold: React.CSSProperties;
    captionItalic: React.CSSProperties;
    buttonSmall: React.CSSProperties;
    buttonLarge: React.CSSProperties;
    notoSans: React.CSSProperties;
    menuItem: React.CSSProperties;
    bannerTitle: React.CSSProperties;
  }

  interface TypeBackground {
    primary: BackgroundColor;
    secondary: BackgroundColor;
    success: BackgroundColor;
    info: BackgroundColor;
    warning: BackgroundColor;
    error: BackgroundColor;
    black: BackgroundColor;
    bluegrey500: BackgroundColor;
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsSizeOverrides {
    tiny: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsSizeOverrides {
    large: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    tiny: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    captionItalic: true;
    buttonSmall: true;
    buttonLarge: true;
    note: true;
    overline: true;
    overlineBold: true;
    notoSans: true;
    menuItem: true;
    bannerTitle: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    bluegrey500: true;
    bluegrey300: true;
    grey300: true;
    white: true;
  }
}

export const globalTheme: ThemeOptions = {
  breakpoints: {
    values: {
      sm: 0,
      xs: 444,
      s: 600,
      m: 900,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  // spacing: [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 48, 60],
  palette: {
    primary: {
      light: '#3381FF',
      main: '#0062FF',
      dark: '#0044B2',
    },
    secondary: {
      light: '#D3EE33',
      main: '#C8EA00',
      dark: '#8CA300',
    },
    success: {
      light: '#DCFAE0',
      main: '#33CC4B',
      dark: '#106A1E',
    },
    info: {
      light: '#E1F5FE',
      main: '#039BE5',
      dark: '#01579B',
    },
    warning: {
      light: '#FFECB3',
      main: '#F0AD4E',
      dark: '#7A5C00',
    },
    error: {
      light: '#FCEDF1',
      main: '#E1343E',
      dark: '#6B1026',
    },
    infoOnTooltips: {
      main: '#00EAFF',
    },
    bluegrey500: {
      main: '#607D8B',
      contrastText: '#FFF',
    },
    bluegrey300: {
      main: '#90A4AE',
      contrastText: '#FFF',
    },
    white: {
      main: '#FFF',
      contrastText: '#212121',
    },
    grey: {
      white: '#ffffff',
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      black: '#000000',
    },
    bluegrey: {
      50: '#ECEFF1',
      100: '#CFD8DC',
      200: '#B0BEC5',
      300: '#90A4AE',
      400: '#78909C',
      500: '#607D8B',
      600: '#546E7A',
      700: '#455A64',
      750: '#3E5059',
      800: '#37474F',
      900: '#263238',
    },
    blue: {
      50: '#E2F3FF',
      200: '#8FCDFD',
      400: '#40A9FB',
      600: '#178DEC',
    },
    purple: {
      50: '#E7E5FC',
      200: '#A9A9E5',
      400: '#868ADA',
      600: '#5654C6',
    },
    grass: {
      50: '#F0FCD9',
      200: '#E4F5A3',
      400: '#CDE071',
      600: '#9EAC25',
    },
    green: {
      50: '#E5FAED',
      200: '#A1E7BC',
      400: '#68D997',
      600: '#00C569',
    },
    orange: {
      50: '#FFF4E2',
      200: '#FCD8A6',
      400: '#F5B889',
      600: '#F0853F',
    },
    pink: {
      50: '#FDEDFA',
      200: '#F8C4EF',
      400: '#ED9CE3',
      600: '#DA50CC',
    },
    brown: {
      50: '#E9E5E2',
      200: '#D9CCC4',
      400: '#BAAAA1',
      600: '#896F5E',
    },
    background: {
      primary: {
        5: 'rgba(0, 98, 255, 0.05)',
        10: 'rgba(0, 98, 255, 0.1)',
      },
      secondary: {
        5: 'rgba(200, 234, 0, 0.05)',
        10: 'rgba(200, 234, 0, 0.1)',
      },
      success: {
        5: 'rgba(51, 204, 75, 0.05)',
        10: 'rgba(51, 204, 75, 0.1)',
      },
      info: {
        5: 'rgba(58, 26, 255, 0.05)',
        10: 'rgba(58, 26, 255, 0.1)',
      },
      warning: {
        5: 'rgba(255, 204, 51, 0.05)',
        10: 'rgba(255, 204, 51, 0.1)',
      },
      error: {
        5: 'rgba(225, 52, 62, 0.05)',
        10: 'rgba(225, 52, 62, 0.1)',
      },
      black: {
        5: 'rgba(0, 0, 0, 0.05)',
        10: 'rgba(0, 0, 0, 0.1)',
        15: 'rgba(0, 0, 0, 0.15)',
      },
      bluegrey500: {
        5: 'rgba(96, 125, 139, 0.05)',
        10: 'rgba(96, 125, 139, 0.1)',
        15: 'rgba(96, 125, 139, 0.15)',
      },
    },
    chart: ['#178DEC', '#8FCDFD', '#5654C6', '#A9A9E5', '#00C569'],
  },
  typography: {
    fontFamily: 'Noto Sans',
    h1: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '120%',
      fontSize: 60,
      letterSpacing: -0.05,
    },
    h2: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '120%',
      fontSize: 48,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: 'Noto Sans',
      fontWeight: '500',
      lineHeight: '120%',
      fontSize: 40,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: 'Noto Sans',
      fontWeight: '500',
      lineHeight: '120%',
      fontSize: 32,
      letterSpacing: 0,
    },
    h5: {
      fontFamily: 'Noto Sans',
      fontWeight: '500',
      lineHeight: '120%',
      fontSize: 24,
      letterSpacing: 0,
    },
    h6: {
      fontFamily: 'Noto Sans',
      fontWeight: '500',
      lineHeight: '120%',
      fontSize: 20,
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: 'Noto Sans',
      fontWeight: '500',
      lineHeight: '130%',
      fontSize: 18,
      letterSpacing: 0.15,
    },
    subtitle2: {
      fontFamily: 'Noto Sans',
      fontWeight: '600',
      lineHeight: '130%',
      fontSize: 16,
      letterSpacing: 0.1,
    },
    body1: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '140%',
      fontSize: 16,
      letterSpacing: 0.5,
    },
    body2: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: 14,
      letterSpacing: 0.25,
    },
    body3: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: 13,
      letterSpacing: 0.25,
    },
    caption: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: 12,
      letterSpacing: 0.4,
    },
    captionItalic: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      fontStyle: 'italic',
      lineHeight: '150%',
      fontSize: 12,
      letterSpacing: 0.4,
    },
    button: {
      fontFamily: 'Noto Sans',
      fontWeight: '600',
      lineHeight: '175%',
      fontSize: 14,
      letterSpacing: 0.5,
    },
    buttonSmall: {
      fontFamily: 'Noto Sans',
      fontWeight: '600',
      lineHeight: '175%',
      fontSize: 13,
      letterSpacing: 0.5,
    },
    buttonLarge: {
      fontFamily: 'Noto Sans',
      fontWeight: '600',
      lineHeight: '175%',
      fontSize: 15,
      letterSpacing: 0.5,
    },
    note: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '125%',
      fontSize: 12,
      letterSpacing: 0.4,
    },
    overline: {
      fontFamily: 'Noto Sans',
      fontWeight: '400',
      lineHeight: '140%',
      fontSize: 14,
      letterSpacing: 0.5,
      textDecoration: 'underline',
    },
    overlineBold: {
      fontFamily: 'Noto Sans',
      fontWeight: '700',
      lineHeight: '140%',
      fontSize: 14,
      letterSpacing: 0.5,
      textDecoration: 'underline',
    },
    notoSans: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '12px',
      lineHeight: '140%',
    },
    menuItem: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },
    bannerTitle: {
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '150%',
      letterSpacing: '0.25px',
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          boxShadow: 'none',
          ...(ownerState.variant === 'text' &&
            ownerState.size === 'medium' && {
              padding: '6px 16px',
            }),
        }),
        outlinedError: ({ ownerState, theme }) => ({
          background: 'rgba(225, 52, 62, 0.1)',
        }),
        sizeSmall: {
          padding: '4px 16px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          cursor: 'pointer',
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'tiny' && {
            width: '16px', // 整體寬度
            height: '8px', // 整體高度
            padding: 0,
            marginRight: '12px',
            overflow: 'visible',
            '& .MuiSwitch-switchBase': {
              padding: 0,
              top: '50%', // 將開關底座垂直置中
              left: '15%', // 將開關底座水平置中
              transform: 'translate(-50%, -50%)', // 確保從中心點開始變換
              '&.Mui-checked': {
                transform: 'translate(9px, -50%)', // 在開啟時從中心點向右偏移
              },
            },
            '& .MuiSwitch-thumb': {
              width: '12px', // 更新滑塊大小以符合高度
              height: '12px', // 確保滑塊高度符合整體高度
              margin: 'auto', // 自動邊距以進一步確保置中
            },
            '& .MuiSwitch-track`': {
              borderRadius: 4, // 軌道圓角等於高度的一半
              opacity: 0.5, // 可以調整透明度
              boxSizing: 'border-box', // 確保軌道尺寸的計算方式
            },
          }),
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.size === 'large' && {
            fontSize: '28px',
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        // replace autofill background color
        root: {
          ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ffffff inset',
          },
        },
        input: {
          ':-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #ffffff inset',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fieldset: {
            borderColor: theme.palette['grey'][500],
          },
          '.Mui-disabled': {
            '-webkit-text-fill-color': theme.palette['grey'][400],
            fieldset: {
              '&.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette['grey'][400],
              },
            },
          },
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '500px',
        },
      },
    },
  },
};

export const theme = createTheme(globalTheme);

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider theme={theme}>
      <CssBaseline />
      {children}
    </Provider>
  );
};

import {
  PostDataOperationCommentReplyDetailProps,
  PostDataOperationCommentReplyDetailResponse,
} from '@frontend/sorghum/interface';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { DATA_DASHBOARD_OPERATION_COMMENT_REPLY_DETAIL } from '../apis';

async function postDataOperationCommentReplyDetail(
  props: PostDataOperationCommentReplyDetailProps,
): Promise<PostDataOperationCommentReplyDetailResponse> {
  return axios
    .post(DATA_DASHBOARD_OPERATION_COMMENT_REPLY_DETAIL, props)
    .then((res) => res.data.data)
    .catch((err) => ({
      conditions: [],
    }));
}

export function usePostDataOperationCommentReplyDetail() {
  return useMutation({
    mutationKey: ['post-data-Operation-commentReply-detail'],
    mutationFn: (props: PostDataOperationCommentReplyDetailProps) =>
      postDataOperationCommentReplyDetail(props),
  });
}

export default usePostDataOperationCommentReplyDetail;

import { Typography } from '@frontend/components/ui';
import AddIcon from '@mui/icons-material/Add';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface FlowEntryEmptyProps {
  isSearch: boolean;
  emptyText: string;
  onAddClick: () => void;
}

const IconWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette['background']['black']['10'],
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
  color: theme.palette['grey']['500'],
  marginBottom: '16px',
}));

const AddIconWrapper = styled(IconWrapper)(({ theme }) => ({
  background: 'rgba(0, 98, 255, 0.1)',
  color: theme.palette['primary']['main'],
}));

const FlowEmptyWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const FlowEntryEmpty: FC<FlowEntryEmptyProps> = ({
  isSearch,
  emptyText,
  onAddClick,
}: FlowEntryEmptyProps) => {
  const [t] = useTranslation();
  if (isSearch) {
    return (
      <FlowEmptyWrapper>
        <IconWrapper>
          <SearchOffIcon />
        </IconWrapper>
        <Typography variant="body1" color="grey.400">
          {t('flows.table.empty')}
        </Typography>
      </FlowEmptyWrapper>
    );
  } else {
    return (
      <FlowEmptyWrapper>
        <AddIconWrapper onClick={onAddClick}>
          <AddIcon color="primary" />
        </AddIconWrapper>
        <Typography variant="body1" color="grey.400">
          {emptyText}
        </Typography>
      </FlowEmptyWrapper>
    );
  }
};

export default FlowEntryEmpty;

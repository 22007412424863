export const REGEX_CHECK_TAIWAN_PHONE_NUMBER = /(\+?886-?|0)?9\d{8}/;
export const REGEX_CHECK_TAIWAN_LANDLINE_NUMBER = /(\+?886-?|0)\d{8,10}/g;
export const REGEX_CHECK_URL = /https?:\/\//g;
export const REGEX_CHECK_ENGLISH = /^[\x20-\x7E]*$/g;
export const REGEX_CHECK_HYPER_LINK = /\[.*?\]\(\s*https:\/\/.*?\)/;
export const REGEX_CHECK_EMAIL = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
export const REGEX_CHECK_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const REGEX_CHECK_SPECIAL_CHARACTERS = /^[^:/?#@\\[\]!$&'()*+,;=%]+$/;
export const REGEX_CHECK_CHARITY_CODE = /^\d{3,7}$/gm;
export const REGEX_CHECK_CITIZEN_CODE = /^[A-Z]{2}\d{14}$/m;
export const REGEX_MOBILE_BARCODE = '/[a-zA-Z0-9\\+\\-]{7}';
export const REGEX_CHECK_ONLY_DIGIT = /^\d+$/gm;

import { Dialog, Typography } from '@frontend/components/ui';
import { usePostProject } from '@frontend/sorghum/data-access';
import { UICtx } from '@frontend/sorghum/external-providers';
import {
  ModalTypesEnum,
  ProjectListItem,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import { usePath } from '@frontend/sorghum/utils';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, ThreadsIcon } from '../icons';
import {
  BetaChipStyled,
  ChannelOptionStyled,
  ChannelOptionWrapper,
} from './styles';

const ChannelOption = ({
  channel,
  title,
  hasBeta = false,
  isDisabled = false,
  isSelected,
  onClick,
}: {
  channel: SocialTypeEnum;
  title: string;
  hasBeta?: boolean;
  isDisabled?: boolean;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const [t] = useTranslation();

  return (
    <ChannelOptionStyled
      onClick={onClick}
      $isDisabled={isDisabled}
      $isSelected={isSelected}
    >
      {channel === SocialTypeEnum.FACEBOOK ? (
        <FacebookIcon size="lg" />
      ) : (
        <ThreadsIcon size="lg" />
      )}
      <Typography variant="h6">{title}</Typography>
      {hasBeta && (
        <BetaChipStyled>
          <Typography variant="body3" color="primary">
            {t('flows.leftSidebar.selectChannelModal.beta')}
          </Typography>
        </BetaChipStyled>
      )}
    </ChannelOptionStyled>
  );
};

interface SelectChannelProps {
  projectList: ProjectListItem[] | never[];
}

export const SelectChannelModal: FC<SelectChannelProps> = ({ projectList }) => {
  const [t] = useTranslation();
  const { modalType, closeModal, openUpgradeProjectModal } = useContext(UICtx);
  const { navigateToOtherProject, navigateToThreadsProject } = usePath();
  const { mutate: createProject } = usePostProject();

  const [selectedChannel, setSelectedChannel] = useState(0);

  const { hasFaceBookProject, hasThreadsProject } = useMemo(() => {
    let hasFaceBookProject = false;
    let hasThreadsProject = false;

    const projectSocialTypeList = projectList.map((item) => item.socialType);

    if (projectSocialTypeList.includes(SocialTypeEnum.FACEBOOK)) {
      hasFaceBookProject = true;
    }
    if (projectSocialTypeList.includes(SocialTypeEnum.THREADS)) {
      hasThreadsProject = true;
    }

    return { hasFaceBookProject, hasThreadsProject };
  }, [projectList]);

  const handleClose = useCallback(() => {
    closeModal();
    setSelectedChannel(0);
  }, [closeModal]);

  const handleConfirm = useCallback(() => {
    switch (selectedChannel) {
      case SocialTypeEnum.FACEBOOK: {
        if (hasFaceBookProject) {
          closeModal();
          openUpgradeProjectModal(SocialTypeEnum.FACEBOOK);
        } else {
          closeModal();
          createProject(
            {
              name: t('flows.defaultProjectName'),
              socialType: SocialTypeEnum.FACEBOOK,
            },
            {
              onSuccess: (data, variables) => {
                navigateToOtherProject(data.data.shortCode);
              },
            },
          );
        }

        setSelectedChannel(0);
        break;
      }
      case SocialTypeEnum.THREADS: {
        if (hasThreadsProject) {
          closeModal();
          openUpgradeProjectModal(SocialTypeEnum.THREADS);
        } else {
          closeModal();
          createProject(
            {
              name: t('flows.defaultProjectName'),
              socialType: SocialTypeEnum.THREADS,
              commentReplyName: t(
                'commentAutoReply.list.defaultCommentAutoReplyName',
              ),
              conditionName: t('commentAutoReply.list.defaultConditionName'),
            },
            {
              onSuccess: (data, variables) => {
                navigateToThreadsProject(data.data.shortCode);
              },
            },
          );
        }

        setSelectedChannel(0);
        break;
      }
      default:
        closeModal();
        setSelectedChannel(0);
    }
  }, [
    selectedChannel,
    hasFaceBookProject,
    hasThreadsProject,
    closeModal,
    openUpgradeProjectModal,
    createProject,
    t,
    navigateToOtherProject,
    navigateToThreadsProject,
  ]);

  return (
    <Dialog
      size="xs"
      open={modalType === ModalTypesEnum.SELECT_CHANNEL}
      title={t('flows.leftSidebar.selectChannelModal.title')}
      handleClose={handleClose}
      cancelBtnText={t('common.cancel')}
      confirmBtnText={t('common.add')}
      handleConfirm={handleConfirm}
      closeBtn={false}
      disableConfirmButton={selectedChannel === 0}
    >
      <ChannelOptionWrapper>
        <ChannelOption
          channel={SocialTypeEnum.FACEBOOK}
          title={t('flows.leftSidebar.selectChannelModal.messenger')}
          isSelected={selectedChannel === SocialTypeEnum.FACEBOOK}
          onClick={() => {
            setSelectedChannel(SocialTypeEnum.FACEBOOK);
          }}
        />
        <ChannelOption
          channel={SocialTypeEnum.THREADS}
          title={t('flows.leftSidebar.selectChannelModal.threads')}
          isSelected={selectedChannel === SocialTypeEnum.THREADS}
          onClick={() => setSelectedChannel(SocialTypeEnum.THREADS)}
        />
      </ChannelOptionWrapper>
    </Dialog>
  );
};

export default SelectChannelModal;

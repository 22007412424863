import { Button } from '@frontend/components/ui';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const ContainerStyled = styled(Box)(({ theme }) => ({
  whiteSpace: 'break-spaces',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: '28px 24px',
}));

export const ContentWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

export const TitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyContentStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const ReplyWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ReplyTitleWrapper = styled(Box)(({ theme }) => ({}));

export const ReplyDateWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const ApplyButtonStyled = styled(Button)(({ theme }) => ({
  marginTop: '8px',
}));

import { Dialog } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { useGetProject } from '@frontend/sorghum/data-access';
import { NotificationCtx, UICtx } from '@frontend/sorghum/external-providers';
import {
  LeftSideItem,
  ModalTypesEnum,
  NotificationTypeEnum,
  ProjectStep,
  SocialTypeEnum,
} from '@frontend/sorghum/interface';
import {
  ConnectThreadsModal,
  DeleteCommentAutoReplyModal,
  PaymentSurveyModal,
  RenameCommentAutoReplyModal,
} from '@frontend/sorghum/ui';
import {
  COMMENT_AUTO_REPLY,
  DASHBOARD,
  PAGE_COMMENT_AUTO_REPLY,
  PAGE_DASHBOARD,
  PAGE_PEOPLE,
  PAGE_SETTING,
  PEOPLE,
  SETTING,
  THREADS_TUTORIAL_EN,
  THREADS_TUTORIAL_ZH,
  usePath,
} from '@frontend/sorghum/utils';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BotIcon } from '../images/icon-bot.svg';

export const useThreads = () => {
  const [t, i18next] = useTranslation();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);

  const {
    modalData,
    title,
    content,
    confirm,
    cancel,
    modalType,
    openModal,
    closeModal,
  } = useContext(UICtx);
  const notificationState = useContext(NotificationCtx);
  const { navigateToThreads } = usePath();

  const handleModalConfirm = useCallback(() => {
    if (modalData.onConfirm) {
      modalData.onConfirm();
    }
    closeModal();
  }, [closeModal, modalData]);

  const gotoTutorial = useCallback(
    () =>
      window.open(
        i18next.language.includes('en')
          ? THREADS_TUTORIAL_EN
          : THREADS_TUTORIAL_ZH,
        '_blank',
      ),
    [i18next],
  );

  const leftSideItems = useMemo<LeftSideItem[]>(
    () => [
      {
        id: 'side_comment_auto_reply',
        label: t('leftSidebar.commentAutoReply'),
        value: COMMENT_AUTO_REPLY,
        Icon: () => <BotIcon />,
        onClick: () => {
          navigateToThreads(PAGE_COMMENT_AUTO_REPLY);
        },
      },
      {
        id: 'side_dashboard',
        label: t('leftSidebar.dataDashboard'),
        value: DASHBOARD,
        Icon: () => <InsertChartOutlinedIcon />,
        onClick: () => {
          navigateToThreads(PAGE_DASHBOARD);
        },
      },
      {
        id: 'side_customer',
        label: t('leftSidebar.people'),
        value: PEOPLE,
        Icon: () => <PeopleOutlinedIcon />,
        onClick: () => navigateToThreads(PAGE_PEOPLE),
      },
      {
        id: 'side_setting',
        label: t('leftSidebar.setting'),
        value: SETTING,
        alert:
          notificationState.noticeBarType ===
          NotificationTypeEnum.PERMISSION_CHANGED,
        alertType: 'error',
        Icon: () => <SettingsOutlinedIcon />,
        onClick: () => {
          navigateToThreads(PAGE_SETTING);
        },
      },
    ],
    [t, notificationState.noticeBarType, navigateToThreads],
  );

  const modal = useMemo(() => {
    switch (modalType) {
      case ModalTypesEnum.RENAME_COMMENT_AUTO_REPLY: {
        return <RenameCommentAutoReplyModal />;
      }
      case ModalTypesEnum.DELETE_COMMENT_AUTO_REPLY: {
        return <DeleteCommentAutoReplyModal />;
      }
      case ModalTypesEnum.CONNECT_THREADS_MODAL: {
        return <ConnectThreadsModal />;
      }
      case ModalTypesEnum.UNSUBSCRIBE_PAYMENT:
      case ModalTypesEnum.TRIAL_END: {
        return <PaymentSurveyModal socialType={SocialTypeEnum.THREADS} />;
      }
      /* 共用的 modal */
      case ModalTypesEnum.COMMON: {
        return (
          <Dialog
            size="xs"
            title={title}
            content={content}
            open={true}
            handleClose={closeModal}
            cancelBtnText={cancel}
            confirmBtnText={confirm}
            color={modalData.color}
            handleConfirm={handleModalConfirm}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [
    cancel,
    closeModal,
    confirm,
    content,
    handleModalConfirm,
    modalData.color,
    modalType,
    title,
  ]);

  useEffect(() => {
    if (project?.step === ProjectStep.TRIAL_END)
      openModal(ModalTypesEnum.TRIAL_END);
  }, [openModal, project?.step]);

  return { modal, leftSideItems, redirectTo: navigateToThreads, gotoTutorial };
};

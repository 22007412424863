import {
  useGetAccountInfo,
  useGetProject,
} from '@frontend/sorghum/data-access';
import { AccountPermission, ProjectStatus } from '@frontend/sorghum/interface';
import { useGetProjectID } from '../use-get-project-id/use-get-project-id';

export function useProjectPermission() {
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);
  const { data: account } = useGetAccountInfo();

  return {
    // FIXME: 之後兩者會拆開
    // 專案過期 or 權限為 Viewer 時，會進入檢視者模式
    isViewer:
      project?.status === ProjectStatus.VIEW_ONLY ||
      account?.permission === AccountPermission.VIEWER,

    // 專案權限是否為 Viewer
    isPermissionViewer: account?.permission === AccountPermission.VIEWER,

    // 專案是否過期 (Messenger)
    // 專案是否超過人數上限（Threads）
    isStatusViewOnly: project?.status === ProjectStatus.VIEW_ONLY,
  };
}

export default useProjectPermission;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Breakpoint, styled } from '@mui/system';
import { ReactNode, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import ReactPlayer from 'react-player/lazy';
import Button from '../button/button';
import Typography from '../typography/typography';

export interface VideoModalProps {
  open: boolean;
  title?: string;
  size?: Breakpoint;
  content?: ReactNode;
  videoLink?: string;
  lottieJsonUrl?: string;
  cancelButtonText?: string;
  confirmButtonText: string;
  confirmButtonID?: string;
  confirmButtonDisabled?: boolean;
  loop?: boolean;
  handleClose?: () => void;
  onConfirm?: () => void;
  onEnded?: () => void;
}
const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  padding: '20px 24px',
}));

const DialogActionsStyled = styled(DialogActions)(({ theme }) => ({
  padding: '0 24px 20px 24px',
}));

export const VideoModal = ({
  open,
  title,
  content,
  videoLink,
  lottieJsonUrl,
  size = 's',
  cancelButtonText,
  confirmButtonText,
  confirmButtonID,
  confirmButtonDisabled,
  loop = false,
  handleClose,
  onConfirm,
  onEnded,
}: VideoModalProps) => {
  const [videoModalJson, setVideoModalJson] = useState<JSON | null>(null);

  useEffect(() => {
    if (lottieJsonUrl) {
      fetch(lottieJsonUrl).then((res) => {
        res.json().then((json) => {
          setVideoModalJson(json);
        });
      });
    }
  }, [lottieJsonUrl]);

  if (!open) return null;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: videoModalJson,
  };

  return (
    <Dialog open maxWidth={size} fullWidth>
      {videoLink && (
        <ReactPlayer
          width={'100%'}
          height={size === 'm' ? '506px' : 'auto'}
          url={videoLink}
          controls
          onEnded={onEnded}
          playing
          loop={loop}
        />
      )}
      {lottieJsonUrl && videoModalJson && <Lottie options={defaultOptions} />}
      <DialogTitleStyled>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
      </DialogTitleStyled>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <DialogActionsStyled>
        {handleClose && (
          <Button onClick={handleClose}>{cancelButtonText}</Button>
        )}
        <Button
          id={confirmButtonID}
          onClick={onConfirm}
          variant="contained"
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText}
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
};

export default VideoModal;

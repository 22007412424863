import { globalTheme } from '@frontend/components/external-providers';
import { Button } from '@frontend/components/ui';
import { useGetProjectID } from '@frontend/editor/data-access';
import { EditorCtx, UICtx } from '@frontend/editor/external-providers';
import { DrawerTypesEnum, ModalTypesEnum } from '@frontend/editor/interface';
import {
  useGetCommentReply,
  useGetProject,
  usePatchCommentReplyPeriod,
} from '@frontend/sorghum/data-access';
import { dayjs } from '@frontend/sorghum/utils';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EditorDrawer } from '../editor-drawer/editor-drawer';
import ThreadsCommentReplyDateModal from '../threads-comment-reply-date-modal/threads-comment-reply-date-modal';
import {
  ApplyButtonStyled,
  ContainerStyled,
  ContentWrapperStyled,
  ReplyContentStyled,
  ReplyDateWrapper,
  ReplyTitleWrapper,
  ReplyWrapperStyled,
  TitleWrapper,
} from './style';

export const DrawerThreadsCommentReplyPeriod = () => {
  const { commentAutoReplyID } = useParams();
  const [t] = useTranslation('threads');

  const { data: commentReplyDataFromAPI } = useGetCommentReply(
    commentAutoReplyID as string,
  );
  const { mutate: updateCommentAutoReplyPeriod } = usePatchCommentReplyPeriod();
  const { data: projectID } = useGetProjectID();
  const { data: project } = useGetProject(projectID);

  const { drawerType, openModal, setDrawerType } = useContext(UICtx);
  const { setIsDrawerUpdate } = useContext(EditorCtx);

  const [replyPeriodRadio, setReplyPeriodRadio] = useState<1 | 2>(2);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  const timezoneOffset = project?.timezoneOffset ? project?.timezoneOffset : 0;

  const handleRadioChange = useCallback(
    (val: 1 | 2) => {
      setIsDrawerUpdate(true);
      setReplyPeriodRadio(val);
    },
    [setIsDrawerUpdate],
  );

  const handleStartSelect = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      setIsDrawerUpdate(true);
      setStartDate(newValue);
    },
    [setIsDrawerUpdate],
  );

  const handleEndSelect = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      setIsDrawerUpdate(true);
      setEndDate(newValue);
    },
    [setIsDrawerUpdate],
  );

  const handleApply = useCallback(() => {
    if (commentAutoReplyID) {
      updateCommentAutoReplyPeriod({
        id: commentAutoReplyID,
        isAutoSave: false,
        periodType: replyPeriodRadio,
        startedAt: startDate?.format('YYYY-MM-DD HH:mm'),
        endedAt: endDate?.format('YYYY-MM-DD HH:mm'),
      });
    }
    setIsDrawerUpdate(false);
    setDrawerType(DrawerTypesEnum.CLOSE);
  }, [
    commentAutoReplyID,
    setIsDrawerUpdate,
    setDrawerType,
    updateCommentAutoReplyPeriod,
    replyPeriodRadio,
    startDate,
    endDate,
  ]);

  const shouldApplyButtonDisabled = useMemo(() => {
    let shouldDisabled = false;
    if (replyPeriodRadio === 1 && (!startDate || !endDate)) {
      shouldDisabled = true;
    }
    return shouldDisabled;
  }, [replyPeriodRadio, startDate, endDate]);

  useEffect(() => {
    if (drawerType === DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD) {
      setReplyPeriodRadio(
        commentReplyDataFromAPI?.schedulePeriod
          ? commentReplyDataFromAPI.schedulePeriod.periodType
          : 2,
      );
      if (commentReplyDataFromAPI?.schedulePeriod?.startedAt) {
        setStartDate(dayjs(commentReplyDataFromAPI.schedulePeriod.startedAt));
      } else setStartDate(null);

      if (commentReplyDataFromAPI?.schedulePeriod?.endedAt) {
        setEndDate(dayjs(commentReplyDataFromAPI?.schedulePeriod?.endedAt));
      } else setEndDate(null);
      setIsDrawerUpdate(false);
    }
  }, [commentReplyDataFromAPI, drawerType, setIsDrawerUpdate]);

  return (
    <EditorDrawer drawerType={DrawerTypesEnum.THREADS_COMMENT_REPLY_PERIOD}>
      <ContainerStyled>
        <ContentWrapperStyled>
          <TitleWrapper>
            <Typography variant="h6" color="grey.900">
              {t('commentAutoReply.drawer.title')}
            </Typography>
          </TitleWrapper>

          <ReplyContentStyled>
            <ReplyWrapperStyled>
              <ReplyTitleWrapper>
                <Typography variant="subtitle2" color="black">
                  {t('commentAutoReply.drawer.replyPeriod.title')}
                </Typography>
              </ReplyTitleWrapper>
              <RadioGroup row value={replyPeriodRadio}>
                <FormControlLabel
                  value="2"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyPeriod.radio1')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(2)}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio size="small" />}
                  label={
                    <Typography variant="body2">
                      {t('commentAutoReply.drawer.replyPeriod.radio2')}
                    </Typography>
                  }
                  onClick={() => handleRadioChange(1)}
                />
              </RadioGroup>
            </ReplyWrapperStyled>

            {replyPeriodRadio === 1 && (
              <ReplyWrapperStyled>
                <ReplyTitleWrapper>
                  <Typography variant="subtitle2" color="black">
                    {t('commentAutoReply.drawer.replyPeriod.subtitle')}
                  </Typography>
                </ReplyTitleWrapper>

                {startDate && endDate && (
                  <ReplyDateWrapper>
                    <Typography variant="body3" color="grey.900">
                      {dayjs(startDate).tz().format('YYYY-MM-DD HH:mm')} -{' '}
                      {dayjs(endDate).tz().format('YYYY-MM-DD HH:mm')}
                    </Typography>
                    <Typography variant="body3" color="grey.900">
                      {t('commentAutoReply.drawer.replyPeriod.subContext', {
                        timezoneOffset: `${
                          timezoneOffset >= 0 && '+'
                        } ${timezoneOffset}`,
                      })}
                    </Typography>
                  </ReplyDateWrapper>
                )}

                <Button
                  onClick={() =>
                    openModal(ModalTypesEnum.THREADS_COMMENT_REPLY_DATE)
                  }
                  size="medium"
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: globalTheme.palette?.['bluegrey']?.[500],
                    border: `1px solid ${globalTheme.palette?.['bluegrey']?.[400]}`,
                  }}
                >
                  {t('commentAutoReply.drawer.replyPeriod.button')}
                </Button>
              </ReplyWrapperStyled>
            )}
          </ReplyContentStyled>

          <ApplyButtonStyled
            onClick={handleApply}
            size="large"
            variant="contained"
            fullWidth
            disabled={shouldApplyButtonDisabled}
            sx={{ height: '48px' }}
          >
            {t('commentAutoReply.drawer.applyButton')}
          </ApplyButtonStyled>
        </ContentWrapperStyled>
      </ContainerStyled>

      <ThreadsCommentReplyDateModal
        timezoneOffset={timezoneOffset}
        startDate={startDate}
        endDate={endDate}
        handleStartSelect={handleStartSelect}
        handleEndSelect={handleEndSelect}
      />
    </EditorDrawer>
  );
};

export default memo(DrawerThreadsCommentReplyPeriod);

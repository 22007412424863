import {
  BREAK_POINT,
  PAGE_DESKTOP_ONLY,
  usePath,
  useRwd,
} from '@frontend/sorghum/utils';
import { useEffect } from 'react';

export const useMobile = () => {
  const { breakPoint } = useRwd();
  const { navigate } = usePath();

  useEffect(() => {
    if (breakPoint <= BREAK_POINT.SM) {
      navigate(PAGE_DESKTOP_ONLY);
    }
  }, [breakPoint, navigate]);
};
